// eslint-disable-next-line no-extend-native
Array.prototype.findLastIndexOf = function (match) {
  let response = -1;
  for (let index = 0; index < this.length; index++) {
    const element = this[index];
    if (match(element))
      response = index;
  }

  return response;
}

// eslint-disable-next-line no-extend-native
Array.prototype.insert = function (index, item) {
  return [...this.slice(0, index), item, ...this.slice(index)];
}

// eslint-disable-next-line no-extend-native
Array.prototype.insertAfterOrEnd = function (match, item) {
  let index = this.findLastIndexOf(match)
  if (index >= 0) {
    return this.insert(index + 1, item);
  } else {
    this.push(item);
    return this;
  }
}

// eslint-disable-next-line no-extend-native
Array.prototype.remove = function (instance) {
  const index = this.findIndex(o => o === instance);
  if (index >= 0)
    return this.splice(index, index);

  return this;
}

// eslint-disable-next-line no-extend-native
Array.prototype.forEachAsync = async function(asyncFunction) {
  for (let index = 0; index < this.length; index++) {
    const element = this[index];
    await asyncFunction(element);
  }
}

// eslint-disable-next-line no-extend-native
Array.prototype.exists = function(match) {
  if (typeof match === 'string' || match === null || match === undefined){
    return this.indexOf(match) !== -1;
  }

  const found = this.find(match);
  return !(found === null || found === undefined);
}

// eslint-disable-next-line no-extend-native
Array.prototype.distinct = function(selector) {
  const map = Object.fromEntries(this, x => selector(x));
  return [...new Set(Object.keys(map).map(key => map[key]))]
}

// eslint-disable-next-line no-extend-native
Array.prototype.sortBy = function(valueExpression) {
  return this.sort((a, b) => {
    const aV = valueExpression(a);
    const bV = valueExpression(b);
    if (aV < bV) return -1;
    if (aV > bV) return 1;
    return 0;
  });
}

// eslint-disable-next-line no-extend-native
Array.prototype.move = function (fromIndex, toIndex) {
  var element = this[fromIndex];
  this.splice(fromIndex, 1);
  this.splice(toIndex, 0, element);
  return this;
}