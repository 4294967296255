import React, { useRef, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Loading from '../../shared/Loading';
import Table from '../../shared/Table';
import RelativeDate, { displayFormat } from '../../shared/RelativeDate';
import Pagination from '../../shared/Pagination';
import Timezone from '../../shared/Timezone';
import { toJS } from '../../../utility/immutable-utility';
import {
  analysisTimeSeriesVersionsSetTab,
  analysisTimeSeriesVersionsSaveSettings,

  analysisVersionSelectToggleAsAt,
  analysisVersionSelectAsAtPageChange,

  analysisVersionSelectAddAdHocAsAt,
  analysisVersionSelectDeleteAdHocAsAt,
  analysisVersionSelectDeleteAllAdHocAsAts,
  analysisVersionSelectEditAdHocAsAtValue,

  analysisVersionSelectAddRelativeAsAt,
  analysisVersionSelectDeleteRelativeAsAt,
  analysisVersionSelectEditRelativeAsAtValue,
  analysisVersionSelectEditRelativeAsAtCutoff,

  analysisVersionSelectAddRelativeForecast,
  analysisVersionSelectDeleteRelativeForecast,
  analysisVersionSelectEditRelativeForecastValue,
  analysisVersionSelectEditRelativeForecastCutoff

} from '../../../actions/analysis-versions';

function isChildOf(e, parent) {
  while (e) {
    if (e === parent) {
      return true;
    }
    e = e.parentNode;
  }

  return false;
}

function isValidCutoffTime(cutoffTime) {
  if (cutoffTime === undefined || cutoffTime.length === 0) return true;
  const regexp = /^(2[0-3]|[01][0-9])(:([0-5][0-9]))?$/;
  return regexp.test(cutoffTime);
}

export const ForecastsBuilderComponent = connect(
  (state) => ({
    timeseriesVersionSettings: state.getIn(['analysis', 'ui', 'timeseriesVersionSettings'])
  }),
  (dispatch) => ({
    setTab(name) {
      dispatch(analysisTimeSeriesVersionsSetTab(name));
    },
    saveSettings() {
      dispatch(analysisTimeSeriesVersionsSaveSettings());
    },

    toggleAsAt(asAt) {
      dispatch(analysisVersionSelectToggleAsAt(asAt));
    },
    asAtPageChange(page) {
      dispatch(analysisVersionSelectAsAtPageChange(page));
    },

    addAdHocAsAt(adHocAsAt) {
      dispatch(analysisVersionSelectAddAdHocAsAt(adHocAsAt));
    },
    deleteAdHocAsAt(adHocAsAt) {
      dispatch(analysisVersionSelectDeleteAdHocAsAt(adHocAsAt));
    },
    deleteAllAdHocAsAts() {
      dispatch(analysisVersionSelectDeleteAllAdHocAsAts());
    },
    setEditAdHocAsAtValue(value) {
      dispatch(analysisVersionSelectEditAdHocAsAtValue(value));
    },

    addRelativeAsAt(relativeAsAt) {
      dispatch(analysisVersionSelectAddRelativeAsAt(relativeAsAt));
    },
    deleteRelativeAsAt(relativeAsAt) {
      dispatch(analysisVersionSelectDeleteRelativeAsAt(relativeAsAt));
    },
    setEditRelativeAsAtValue(value) {
      dispatch(analysisVersionSelectEditRelativeAsAtValue(value));
    },
    setEditRelativeAsAtCutoff(value) {
      dispatch(analysisVersionSelectEditRelativeAsAtCutoff(value));
    },
    addRelativeForecast(relativeForecast) {
      dispatch(analysisVersionSelectAddRelativeForecast(relativeForecast));
    },
    deleteRelativeForecast(relativeForecast) {
      dispatch(analysisVersionSelectDeleteRelativeForecast(relativeForecast));
    },
    setEditRelativeForecastValue(value) {
      dispatch(analysisVersionSelectEditRelativeForecastValue(value));
    },
    setEditRelativeForecastCutoff(value) {
      dispatch(analysisVersionSelectEditRelativeForecastCutoff(value));
    },
  })
)(({
  onComplete,
  saveSettings,
  setTab,
  timeseriesVersionSettings,
  toggleAsAt, asAtPageChange,
  addAdHocAsAt, deleteAdHocAsAt, deleteAllAdHocAsAts, setEditAdHocAsAtValue,
  addRelativeAsAt, deleteRelativeAsAt, setEditRelativeAsAtValue, setEditRelativeAsAtCutoff,
  addRelativeForecast, deleteRelativeForecast, setEditRelativeForecastValue, setEditRelativeForecastCutoff }) => {

  if (!timeseriesVersionSettings) {
    return <div></div>
  }

  const _timeseriesVersionSettings = toJS(timeseriesVersionSettings);

  const masterName = _timeseriesVersionSettings ? _timeseriesVersionSettings.timeseries.name : '...';

  const isAsAtTabVisible = _timeseriesVersionSettings.timeseries.style !== 'Simple';
  const isAdHocAsAtTabVisible = _timeseriesVersionSettings.timeseries.style !== 'Simple';
  const isRelativeAsAtTabVisible = _timeseriesVersionSettings.timeseries.style !== 'Simple';
  const isRelativeForecastTabVisible = _timeseriesVersionSettings.timeseries.style !== 'Simple';

  const isAsAtTabActive = _timeseriesVersionSettings.tab === 'AsAt';
  const isAdHocAsAtTabActive = _timeseriesVersionSettings.tab === 'AdHocAsAt';
  const isRelativeAsAtTabActive = _timeseriesVersionSettings.tab === 'RelativeAsAt';
  const isRelativeForecastTabActive = _timeseriesVersionSettings.tab === 'RelativeForecast';

  function onApply(e) {
    if (e) e.preventDefault();

    saveSettings();
    onComplete();
  }

  function onCancel(e) {
    if (e) e.preventDefault();

    onComplete();
  }

  return <div className='modal-content'>
    <div className='modal-header'>
      <h5 className='modal-title'>AsAts for: {masterName}</h5>
      <button type='button' className='close' onClick={onCancel}>&times;</button>
    </div>
    <div className='modal-body'>
      <ul className='nav nav-tabs mb-2'>
        {isAsAtTabVisible && (
          <li className='nav-item tab-header-item'>
            <button className={`nav-link ${isAsAtTabActive ? 'active' : ''}`} type='button' onClick={() => setTab('AsAt')}>As-At</button>
          </li>
        )}
        {isAdHocAsAtTabVisible && (
          <li className='nav-item tab-header-item'>
            <button className={`nav-link ${isAdHocAsAtTabActive ? 'active' : ''}`} type='button' onClick={() => setTab('AdHocAsAt')}>AdHoc As-At</button>
          </li>
        )}
        {isRelativeAsAtTabVisible && (
          <li className='nav-item ml-1 tab-header-item'>
            <button className={`nav-link ${isRelativeAsAtTabActive ? 'active' : ''}`} type='button' onClick={() => setTab('RelativeAsAt')}>Relative As-At</button>
          </li>
        )}
        {isRelativeForecastTabVisible && (
          <li className='nav-item ml-1 tab-header-item'>
            <button className={`nav-link ${isRelativeForecastTabActive ? 'active' : ''}`} type='button' onClick={() => setTab('RelativeForecast')}>Relative forecast offset</button>
          </li>
        )}
      </ul>
      <div style={{ height: '35vh' }}>
        {isAsAtTabVisible && (
          <div className={isAsAtTabActive ? '' : 'd-none'} style={{ position: 'relative' }}>
            <AsAtPicker asAts={_timeseriesVersionSettings.asAts} toggleAsAt={toggleAsAt} pageChange={asAtPageChange} />
          </div>
        )}
        {isAdHocAsAtTabVisible && (
          <div className={isAdHocAsAtTabActive ? '' : 'd-none'} style={{ position: 'relative' }}>
            <AdHocAsAtPicker adHocAsAts={_timeseriesVersionSettings.adHocAsAts} addAdHocAsAt={addAdHocAsAt} deleteAdHocAsAt={deleteAdHocAsAt} deleteAllAdHocAsAts={deleteAllAdHocAsAts} setEditAdHocAsAtValue={setEditAdHocAsAtValue} />
          </div>
        )}
        {isRelativeAsAtTabVisible && (
          <div className={`${isRelativeAsAtTabActive ? 'd-flex' : 'd-none'} flex-column align-content-center justify-content-center`}>
            <RelativeAsAtPicker relativeAsAts={_timeseriesVersionSettings.relativeAsAts} addRelativeAsAt={addRelativeAsAt} deleteRelativeAsAt={deleteRelativeAsAt} setEditRelativeAsAtValue={setEditRelativeAsAtValue} setEditRelativeAsAtCutoff={setEditRelativeAsAtCutoff} />
          </div>
        )}
        {isRelativeForecastTabVisible && (
          <div className={`${isRelativeForecastTabActive ? 'd-flex' : 'd-none'} flex-column align-content-center justify-content-center`}>
            <RelativeForecastPicker relativeForecasts={_timeseriesVersionSettings.relativeForecasts} addRelativeForecast={addRelativeForecast} deleteRelativeForecast={deleteRelativeForecast} setEditRelativeForecastValue={setEditRelativeForecastValue} setEditRelativeForecastCutoff={setEditRelativeForecastCutoff} />
          </div>
        )}
      </div>
    </div>
    <div className='modal-footer'>
      <button type='button' className='btn btn-secondary' onClick={onCancel}>Cancel</button>
      <button type='button' className='btn btn-primary' onClick={onApply}>Apply</button>
    </div>
  </div>
});

function RelativeForecastPicker({ relativeForecasts, addRelativeForecast, deleteRelativeForecast, setEditRelativeForecastValue, setEditRelativeForecastCutoff }) {
  const dropdownContentsRef = useRef();
  const dropdownRef = useRef();
  const addButtonRef = useRef();

  function onAddRelativeDate() {
    addRelativeForecast(relativeForecasts.editValue);
  }

  useEffect(() => {
    window.$(dropdownRef.current).on('hide.bs.dropdown', function (e) {
      var target = (e.clickEvent && e.clickEvent.target) || e.target;
      if (isChildOf(target, dropdownContentsRef.current)) {
        if (!isChildOf(target, addButtonRef.current)) {
          e.preventDefault();
        }
      }
    });

  }, [dropdownRef]);

  return <div>
    <div className='input-group' >
      <div className='form-control' style={{ minHeight: '3.3em', height: '100%' }}>
        {relativeForecasts.selection.map(forecast => <button key={forecast} className='btn btn-outline-secondary btn-sm mr-1 mb-1 ml-1 mt-1' onClick={e => deleteRelativeForecast(forecast)} ><i className='fas fa-times fa-fw' />{displayFormat(forecast)}</button>)}
      </div>

      <div ref={dropdownRef} className='dropright input-group-append'>
        <div data-toggle="dropdown" className='input-group-text' >
          <i className='fas fa-plus fa-fw' />
        </div>
        <div ref={dropdownContentsRef} className='dropdown-menu fota-dropdown-modal' >
          <div className='modal-body'>
            <RelativeDate value={relativeForecasts.editValue.period} onChange={setEditRelativeForecastValue} isPastOnly={false} options={['D','M']} />
            <label className='font-weight-bold mt-4 mr-4'>Cutoff time</label>
            <input className='mb-4' onChange={e => setEditRelativeForecastCutoff(e.target.value)} value={relativeForecasts.editValue.cutoffTime} />

          </div>
          <div className='modal-footer'>
            <button ref={addButtonRef} type='button' className='btn btn-primary' disabled={!isValidCutoffTime(relativeForecasts.editValue.cutoffTime)} onClick={e => onAddRelativeDate(e)}><i className='fas fa-plus fa-fw' />&nbsp;Add</button>
          </div>
        </div>
      </div>
    </div>
  </div>
}

function RelativeAsAtPicker({ relativeAsAts, addRelativeAsAt, deleteRelativeAsAt, setEditRelativeAsAtValue, setEditRelativeAsAtCutoff }) {
  const dropdownContentsRef = useRef();
  const dropdownRef = useRef();
  const addButtonRef = useRef();

  function onAddRelativeDate() {
    addRelativeAsAt(relativeAsAts.editValue);
  }

  useEffect(() => {
    window.$(dropdownRef.current).on('hide.bs.dropdown', function (e) {
      var target = (e.clickEvent && e.clickEvent.target) || e.target;
      if (isChildOf(target, dropdownContentsRef.current)) {
        if (!isChildOf(target, addButtonRef.current)) {
          e.preventDefault();
        }
      }
    });

  }, [dropdownRef]);

  return <div>
    <div className='input-group' >
      <div className='form-control' style={{ minHeight: '3.3em', height: '100%' }}>
        {relativeAsAts.selection.map(asAt => <button key={asAt} className='btn btn-outline-secondary btn-sm mr-1 mb-1 ml-1 mt-1' onClick={e => deleteRelativeAsAt(asAt)} ><i className='fas fa-times fa-fw' />{displayFormat(asAt)}</button>)}
      </div>

      <div ref={dropdownRef} className='dropright input-group-append'>
        <div data-toggle="dropdown" className='input-group-text' >
          <i className='fas fa-plus fa-fw' />
        </div>
        <div ref={dropdownContentsRef} className='dropdown-menu fota-dropdown-modal' >
          <div className='modal-body'>
            <RelativeDate value={relativeAsAts.editValue.period} onChange={setEditRelativeAsAtValue} isPastOnly={false} />
            <label className='font-weight-bold mt-4 mr-4'>Cutoff time</label>
            <input className='mb-4' onChange={e => setEditRelativeAsAtCutoff(e.target.value)} value={relativeAsAts.editValue.cutoffTime} />
          </div>
          <div className='modal-footer'>
            <button ref={addButtonRef} type='button' className='btn btn-primary' disabled={!isValidCutoffTime(relativeAsAts.editValue.cutoffTime)} onClick={e => onAddRelativeDate(e)}><i className='fas fa-plus fa-fw' />&nbsp;Add</button>
          </div>
        </div>
      </div>
    </div>
  </div>
}

function AsAtRow({ cellData, rawData, toggleAsAt, isSelected }) {
  const { identityId, timestampUtc, updatedUtc, firstDataPointUtc, lastDataPointUtc } = cellData;
  return (
    <tr className={isSelected(rawData) ? 'table-primary' : ''}
      style={{ userSelect: 'none', cursor: 'pointer' }} onClick={() => toggleAsAt(rawData)}>
      <td className='text-nowrap'>{identityId}</td>
      <td className='text-nowrap'>{timestampUtc}</td>
      <td className='text-nowrap'>{updatedUtc}</td>
      <td className='text-nowrap'>{firstDataPointUtc}</td>
      <td className='text-nowrap'>{lastDataPointUtc}</td>
    </tr>
  );
}

function AsAtPicker({ asAts, toggleAsAt, pageChange }) {
  const columns = [{
    key: 'identityId',
    title: 'Id'
  }, {
    key: 'timestampUtc',
    title: <span>Timestamp <Timezone /></span>,
    format: value => moment.utc(value).format('DD-MMM-YYYY HH:mm:ss')
  }, {
    key: 'updatedUtc',
    title: <span>Last Updated <Timezone /></span>,
    format: value => moment.utc(value).format('DD-MMM-YYYY HH:mm:ss')
  }, {
    key: 'firstDataPointUtc',
    title: <span>First data point <Timezone /></span>,
    format: value => moment.utc(value).format('DD-MMM-YYYY HH:mm:ss')
  }, {
    key: 'lastDataPointUtc',
    title: <span>Last data point <Timezone /></span>,
    format: value => moment.utc(value).format('DD-MMM-YYYY HH:mm:ss')
  }];

  function isSelected(asAt) {
    return asAts.selection.some(a => a === asAt.timestampUtc);
  }

  return <Loading isLoading={asAts.isLoading} message='Loading other versions...'>
    <Fragment>
      <div className='table-responsive mb-3' style={{ maxHeight: '30vh', fontSize: '.9em' }}>
        <Table className='table-hover' columns={columns} data={asAts.data && asAts.data.results} useTBody={true}
          emptyMessage={asAts.error ? asAts.error : 'No other versions available'}>
          <AsAtRow isSelected={isSelected} toggleAsAt={toggleAsAt} />
        </Table>
      </div>
      <div>
        <Pagination currentPage={asAts.page} steps={5} pageSize={asAts.pageSize} itemCount={asAts.data && asAts.data.count}
          hidePageSize={true} hideJumpTo={true}
          onPageChange={pageChange} />
      </div>
    </Fragment>
  </Loading>
}

function AdHocAsAtPicker({ adHocAsAts, addAdHocAsAt, deleteAdHocAsAt, deleteAllAdHocAsAts, setEditAdHocAsAtValue }) {
  function onAddAdHocAsAt(e) {
    e.preventDefault();
    e.stopPropagation();
    addAdHocAsAt(adHocAsAts.editValue);
  }

  return <Fragment>
    <form onSubmit={onAddAdHocAsAt}>
      <div className='form-group'>
        <div className='input-group'>
          <div>
            <input className='form-control' type='datetime-local' min="0001-01-01T00:00:00" max="9999-12-31T23:59:59" step='01' value={adHocAsAts.editValue} onChange={e => setEditAdHocAsAtValue(e.target.value)} />
          </div>
          <button type="submit" className='btn btn-primary ml-1' onClick={onAddAdHocAsAt}><i className='fas fa-plus fa-fw' />&nbsp;Add</button>
        </div>
      </div>
      <div className='form-group'>
        <label className='fota-input-group-label font-weight-bold'>Dates</label>
        <div className='input-group' >
          <div className='form-control' style={{ minHeight: '28vh', maxHeight: '28vh', overflowY: "auto" }}>
            {adHocAsAts.selection.map(asAt => <button key={asAt} className='btn btn-outline-secondary btn-sm mr-1 mb-1 ml-1 mt-1' onClick={e => deleteAdHocAsAt(asAt)} ><i className='fas fa-times fa-fw' />{moment.utc(asAt).format('DD-MMM-YYYY HH:mm:ss')}</button>)}
          </div>
          <div className='input-group-append' style={{ height: '28vh' }}>
            {adHocAsAts.selection.length > 0 && <button type='button' className='btn btn-outline-secondary' onClick={deleteAllAdHocAsAts} >
              <i className='fas fa-times fa-fw' />
            </button>}
          </div>
        </div>
      </div>
    </form>
  </Fragment>
}