import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Loading from '../../shared/Loading';
import {
  etlJobFetch,
  etlJobHistoryFetch,
  etlJobRunFetch
} from '../../../actions/etlJob';
import {
  etlJobRequest
} from '../../../actions/etl';
import moment from 'moment';
import { useParams } from 'react-router';
import { AutoRefresh } from '../../shared/AutoRefresh';
import { Pagination } from '@mui/material';

function mapStatusToClass(value) {
  if (value === 'Failed' || value === 'FinishedWithErrors')
    return 'p-1 bg-danger text-white';
  if (value === 'Succeeded')
    return 'p-1 bg-success text-white';
  if (value === 'Running' || value === 'FinishedWithWarnings')
    return 'p-1 bg-warning';
  return 'p-1 bg-default';
}

function mapStatusToDesc(value) {
  if (value === 'FinishedWithWarnings')
    return 'Warnings';
  if (value === 'FinishedWithErrors')
    return 'Error';
  return value;
}

const EtlJob = connect(
  (state) => ({
    isLoading: state.getIn(['etlJob', 'isLoading']),
    isLoadingJobRun: state.getIn(['etlJob', 'isLoadingJobRun']),
    job: state.getIn(['etlJob', 'job']).toJS(),
    lastRun: state.getIn(['etlJob', 'lastRun']).toJS(),
    lastRunInformation: state.getIn(['etlJob', 'lastRunInformation']).toJS(),
    lastRunWarning: state.getIn(['etlJob', 'lastRunWarning']).toJS(),
    lastRunError: state.getIn(['etlJob', 'lastRunError']).toJS(),
    lastSuccessAtUtc: state.getIn(['etlJob', 'lastSuccessAtUtc']),
    nextRun: state.getIn(['etlJob', 'nextRun']).toJS(),
    lastRefreshed: state.getIn(['etlJob', 'lastRefreshed']),
    totalCount: state.getIn(['etlJobHistory', 'totalCount']),
    history: state.getIn(['etlJobHistory', 'items']).toJS(),
    historyPage: state.getIn(['etlJobHistory', 'page']),
    historyIsLoading: state.getIn(['etlJobHistory', 'isLoading']),
  }),
  (dispatch) => ({
    load(jobId, page) {
      dispatch(etlJobFetch(jobId));
      dispatch(etlJobHistoryFetch(jobId, page));
    },
    loadPage(jobId, page) {
      dispatch(etlJobHistoryFetch(jobId, page));
    },
    runJob(jobId){
      dispatch(etlJobRequest(jobId));
    },
    getJobRunDetails(runId) {
      dispatch(etlJobRunFetch(runId));
    }
  })
)(({ isLoading, job, lastRun, nextRun, lastRunInformation, lastRunWarning, lastRunError,lastSuccessAtUtc, lastRefreshed,
  totalCount, history, historyPage, historyIsLoading,
  load, loadPage, runJob, getJobRunDetails, isLoadingJobRun }) => {

  const params = useParams();
  const jobId = !isNaN(params.id) ? params.id : null;
  useEffect(() => load(jobId, 0), [load, jobId]);

  function isJobRunSelected(jobRun) {
    return lastRun && lastRun.id === jobRun.id;
  }
  
  function onRefresh() {
    load(job.id, historyPage);
  }

  function onPageChange(e, page) {
    loadPage(job.id, page - 1);
  }

  function onRunJob() {
    runJob(job.id);    
  }

  function onGetJobRunDetails(runId) {
    getJobRunDetails(runId);
  }
  
  function duration(historyItem) {
    if (!historyItem.elapsed)
      return '';

    if (historyItem.elapsed < moment.duration(1, 'minutes')) {
      return historyItem.elapsed.seconds() + ' seconds';
    }
    return historyItem.elapsed.humanize({ d: 7, w: 4, s: 60 });
  }

  return <main className='d-flex flex-column' style={{ overflowY:'hidden' }}>
  <Loading isLoading={isLoading || isLoadingJobRun || historyIsLoading} message='Loading...'>
    <div className='d-flex flex-column h-100'>
      <div className='d-flex p-2'>
        <button type='button' className='btn btn-primary text-nowrap' onClick={onRefresh}>
          <i className='fas fa-sync-alt fa-fw' />
          <span className='px-2 d-none d-md-inline-block'>Refresh</span>
        </button>        

        <div className='pt-0 pl-2'>
          <AutoRefresh onRefresh={onRefresh}
            options={[
              { minutes: 1, text: 'Every 1 minute' },
              { minutes: 2, text: 'Every 2 minutes' },
              { minutes: 5, text: 'Every 5 minutes' },
              { minutes: 10, text: 'Every 10 minutes' }
            ]} />
        </div>

        <div className='d-flex'>
          <div className='text-info d-sm-none mx-1' style={{fontSize:'10px'}}>{lastRefreshed ? <>{lastRefreshed.format('DD-MM-YYYY')}<br/>{lastRefreshed.format('HH:mm:ss')}</> : ''}</div>
          <div className='m-2 text-info d-none d-sm-block'>Last refreshed: {lastRefreshed ? lastRefreshed.format('DD-MMM-YYYY HH:mm:ss') : ''}</div>
        </div>

        <button type='button' className='btn btn-outline-secondary text-nowrap' onClick={onRunJob}>
          <span>Run job</span>
        </button>
      </div>

      <div className='d-flex flex-fill' style={{ maxHeight: 'calc(100% - 50px)' }}>
        <div className='w-50 p-2' style={{ overflowX:'hidden', overflowY: 'auto' }}>
            <div className='d-flex flex-column' >
              <div className='row mt-3'>
                <div className='col'>
                  <label className='font-weight-bold'>Job ID</label>
                  <div>{job.id}</div>
                </div>
                <div className='col'>
                  <label className='font-weight-bold'>Job Name</label>
                  <div>{job.name}</div>
                </div>
                <div className='col' />
                <div className='col' />
              </div>

              <div className='row mt-3'>
                <div className='col'>
                  <label className='font-weight-bold'>Run ID</label>
                  <div>{lastRun ? lastRun.id : 'N/A'}</div>
                </div>
                <div className='col'>
                  <label className='font-weight-bold'>Run Status</label>
                  <div className={mapStatusToClass(lastRun.status)}>{lastRun ? mapStatusToDesc(lastRun.status) : 'N/A'}</div>
                </div>
                <div className='col'>
                  <label className='font-weight-bold'>Run Originator</label>
                  <div>{lastRun ? lastRun.originator : 'N/A'}</div>
                </div>
                <div className='col' />
              </div>

              <div className='row mt-3'>
                <div className='col'>
                  <label className='font-weight-bold'>Run Started (UTC)</label>
                  <div>{lastRun && lastRun.startedAtUtc ? lastRun.startedAtUtc.format('DD-MMM-YYYY HH:mm:ss') : ''}</div>
                </div>
                <div className='col'>
                  <label className='font-weight-bold'>Run Stopped (UTC)</label>
                  <div>{lastRun && lastRun.stoppedAtUtc ? lastRun.stoppedAtUtc.format('DD-MMM-YYYY HH:mm:ss') : ''}</div>
                </div>
                <div className='col'>
                  <label className='font-weight-bold'>Next Run (UTC)</label>
                  <div className={nextRun && nextRun.isNextRunLate ? 'p-1 bg-danger text-white' : ''}>{nextRun && nextRun.nextRunUtc ? nextRun.nextRunUtc.format('DD-MMM-YYYY HH:mm:ss') : ''}</div>
                </div>
                <div className='col'>
                  <label className='font-weight-bold'>Last Success (UTC)</label>
                  <div>{lastSuccessAtUtc ? lastSuccessAtUtc.format('DD-MMM-YYYY HH:mm:ss') : ''}</div>
                </div>
              </div>

              <div className='row mt-3'>
                <div className='col-12'>
                  <label className='font-weight-bold'>Run Error</label>
                  <div>{lastRun && lastRun.error ? lastRun.error : 'N/A'}</div>
                </div>
              </div>

              <div className='row mt-3'>
                <div className='col-12'>
                  <label className='font-weight-bold'>Run Data</label>
                  <div className='row ml-2'>
                    <div>
                      <span className="badge badge-pill badge-info">Information {lastRun ? lastRun.informationCount : '-'}</span>
                    </div>
                    <div className='ml-1'>
                      <span className="badge badge-pill badge-warning">Warning {lastRun ? lastRun.warningCount : '-'}</span>
                    </div>
                    <div className='ml-1'>
                      <span className="badge badge-pill badge-danger">Error {lastRun ? lastRun.errorCount : '-'}</span>
                    </div>
                  </div>
                  <div className='mt-2'>
                    <table className='table table-sm table-striped'>
                      <thead>
                        <tr>
                          <th>Status</th>
                          <th>SourceId</th>
                          <th>Message</th>
                        </tr>
                      </thead>
                      <tbody>
                        {lastRunError.map((x, i) =>
                          <tr key={`e_${i}`} title={moment.utc(x.timestamp).format('DD-MMM-YYYY HH:mm:SS')}>
                            <td>{x.status}</td>
                            <td>{x.sourceId}</td>
                            <td>{x.message}</td>
                          </tr>
                        )}
                        {lastRunWarning.map((x, i) =>
                          <tr key={`w_${i}`} title={moment.utc(x.timestamp).format('DD-MMM-YYYY HH:mm:SS')}>
                            <td>{x.status}</td>
                            <td>{x.sourceId}</td>
                            <td>{x.message}</td>
                          </tr>
                        )}
                        {lastRunInformation.map((x, i) =>
                          <tr key={`i_${i}`} title={moment.utc(x.timestamp).format('DD-MMM-YYYY HH:mm:SS')}>
                            <td>{x.status}</td>
                            <td>{x.sourceId}</td>
                            <td>{x.message}</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div> 
            </div> 
        </div>

        <div className='w-50 h-100'>
          <div className='h-100 d-flex flex-column'>
            <div className='flex-fill sticky-table'>
              <table className='table table-sm table-striped'>
                <thead>
                  <tr className='text-nowrap'>
                    <th>Run ID</th>
                    <th><div className='d-none d-sm-block'>Status</div></th>
                    <th>Originator</th>
                    <th>Started UTC</th>
                    <th>Stopped UTC</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {!history && <tr>
                    <td colSpan={7}>
                      No history
                    </td>
                  </tr>}
                  {history.length > 0 && <>
                    {history.map((x, i) =>
                      <tr onClick={() => onGetJobRunDetails(x.id)} key={`h_${i}`} className='fota-analysis-table-row-hover text-nowrap' style={{ cursor: 'pointer' }}>
                        <td>{x.id}</td>
                        <td className={mapStatusToClass(x.status)}>
                          <div className='d-sm-none'>&nbsp;</div>
                          <div className='d-none d-sm-block' style={{ width: '8em' }}>{x.status}</div>
                        </td>
                        <td>{x.originator}</td>
                        <td>{x.startedAtUtc ? x.startedAtUtc.format('DD-MMM-YYYY HH:mm:ss') : ''}</td>
                        <td title={duration(x)}>{x.stoppedAtUtc ? x.stoppedAtUtc.format('DD-MMM-YYYY HH:mm:ss') : ''}</td>
                        <td>
                          <i className={`text-primary fas fa-check-circle fa-lg fa-fw ${isJobRunSelected(x) ? 'visible' : 'invisible'}`} />
                        </td>
                      </tr>
                    )}
                  </>}
                </tbody>
              </table>
            </div>

            <div className='d-flex justify-content-center p-2'>
              <Pagination count={totalCount > 0 ? Math.round(totalCount / 20) ?? 1 : 1} variant='outlined' shape='rounded' value={(historyPage ?? 0) + 1} onChange={onPageChange}></Pagination>
            </div>
          </div>
        </div>
      </div>

    </div>
  </Loading>
</main>
});

export default EtlJob;