import React from 'react';
import DateTimeInput from '../../../shared/DateTimeInput';

const groupStyle = { marginBottom: '.5em' };
const labelStyle = { fontSize: '.9em', marginBottom: 0 };

export default function InstanceToolbar({ fromUtc, toUtc, dataPointUtc, refreshFunction, setToolbarPropertyFunction }) {
  return (
      <div className='ml-0 form-row'>
        <div className='form-group ml-1' style={groupStyle}>
          <label className='font-weight-bold text-nowrap' style={labelStyle}>AsAt From (UTC)</label>
          <DateTimeInput style={{ fontSize: '12.6px' }}
                         value={fromUtc}
                         onChange={value => setToolbarPropertyFunction('fromUtc', value)} />
        </div>
        <div className='form-group ml-1' style={groupStyle}>
          <label className='font-weight-bold text-nowrap' style={labelStyle}>AsAt To (UTC)</label>
          <DateTimeInput style={{ fontSize: '12.6px' }}
                         value={toUtc}
                         onChange={value => setToolbarPropertyFunction('toUtc', value)} />
        </div>
        <div className='form-group ml-1' style={groupStyle}>
          <label className='font-weight-bold text-nowrap' style={labelStyle}>DP Date (UTC)</label>
          <DateTimeInput style={{ fontSize: '12.6px' }}
                         value={dataPointUtc}
                         onChange={value => setToolbarPropertyFunction('dataPointUtc', value)} />
        </div>        
        <div className='form-group ml-1 d-flex align-items-end' style={groupStyle}>
          <div className='btn-group btn-group-sm'>
            <button className='btn text-nowrap btn-primary rounded' onClick={e => refreshFunction()}>
              <i className='fas fa-sync-alt fa-fw' />
              <span className='px-2 d-none d-md-inline-block'>Refresh</span>
            </button>
          </div>
        </div>
      </div>

  );
}