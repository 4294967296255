import React from 'react';
import { connect } from 'react-redux';
import { toJS } from '../../../utility/immutable-utility';
import { tileComponent, tileOptions } from '../../../mapper/dashboardTileMapper';
import ErrorBoundary from '../../shared/ErrorBoundary';
import {
  dashboardTileRefresh,
  dashboardTileEditClone,
  dashboardTileEditDelete,
  dashboardTileSetProperties,
  dashboardTileSetPropertyBag,
  dashboardTileSavePropertyBag,
  dashboardTileExpand,
  dashboardTileHideError,
  dashboardTileHideAllErrors,
  dashboardTileLoadWorkspace,
  dashboardTileBeginPropertyBag
} from '../../../actions/dashboard-tiles';
import { ModalDialogButton } from '../../shared/ModalDialogButton';
import ConfirmButton from '../../shared/ConfirmButton';
import { getHLS } from '../../../utility/color-utility';
import { GetRegisteredDashboardItem } from '../../../mapper/dashboardTileMapper';
import TileCommonSettings from './TileCommonSettings';
import { FetchError } from '../../../auth';

function TileError({ index, error, hideError, data_id }) {
  let icon = <></>;
  switch (error.type) {
    case 'error':
      if (error.error instanceof FetchError)
        ({title: error.title, detail:error.message} = JSON.parse(error.error.body));
      icon = <span className='text-danger mr-1' style={{ fontSize: '1.5em' }}><i className='fas fa-exclamation-triangle fa-fw'></i></span>;
      break;
    case 'warn':
    case 'warning':
      icon = <span className='text-warning mr-1' style={{ fontSize: '1.5em' }}><i className='fas fa-exclamation-circle fa-fw'></i></span>;
      break;
    case 'info':
      icon = <span className='text-info mr-1' style={{ fontSize: '1.5em' }}><i className='fas fa-info-circle fa-fw'></i></span>;
      break;
    default:
  }

  let content = (typeof (error.message) === 'object') ? JSON.stringify(error.message, undefined, 2) : error.message;
  if (content === '{}')
    content = `${error.message}`;

  return <div data_id={data_id} className={`toast show mb-1`}>
    <div className='toast-header px-2'>
      {icon}<strong data_id='title' className='mr-auto fota-ellipsis-text'>{error.title ?? content}</strong>
      <button type='button' className='btn btn-sm ml-1 mb-1 mr-0' onClick={() => hideError(index)}>
        <i className='fas fa-times fa-fw'></i>
      </button>
    </div>
    <div className='toast-body'>
      <pre data_id='content' style={{ whiteSpace: 'pre-wrap' }} className='m-0'>{content}</pre>
    </div>
  </div>;
}

const SettingsEditorConnected = connect(
  (state, { dashboardTileType, stateKey }) => ({
    dashboardTileType,
    stateKey,
    dashboardTileProperties: state.getIn(['dashboardTiles', 'tilesConfig', stateKey]),
  }),
  (dispatch, { stateKey }) => ({
    beginEdit(){
      dispatch(dashboardTileBeginPropertyBag(stateKey));
    }
  })
)(({ dashboardTileType, stateKey, beginEdit }) => {
  return <ModalDialogButton onOpenDialog={beginEdit} buttonClassName='btn btn-sm' buttonContent={<i className='fas fa-cog fa-fw' />} >
    <SettingsEditorModalConnected dashboardTileType={dashboardTileType} stateKey={stateKey} />
  </ModalDialogButton>
})

const SettingsEditorModalConnected = connect(
  (state, { dashboardTileType, stateKey }) => ({
    dashboardTileType,
    stateKey,
    state,
    statePropertyBag: state.getIn(['dashboardTiles', 'tilesStatePropertyBag', stateKey]),
    workspaceName: state.getIn(['dashboardTiles', 'tilesState', stateKey, 'workspace', 'name']),
    workspace: state.getIn(['dashboardTiles', 'tilesState', stateKey, 'workspace']),
    indicateRefreshRequired: state.getIn(['dashboardTiles', 'tilesState', stateKey, 'indicateRefreshRequired'])
  }),
  (dispatch, { dashboardTileType, stateKey }) => ({
    refreshTile() {
      dispatch(dashboardTileRefresh(stateKey, dashboardTileType));
    },
    expandTile(expand) {
      dispatch(dashboardTileExpand(expand ? stateKey : undefined));
    },
    cloneTile() {
      dispatch(dashboardTileEditClone(stateKey));
    },
    deleteTile() {
      dispatch(dashboardTileEditDelete(stateKey));
    },
    saveProperties(stateKey) {
      dispatch(dashboardTileSavePropertyBag(stateKey));
    },
    setProperty(value) {
      dispatch(dashboardTileSetPropertyBag(stateKey, value));
    }
  })
)(({ dashboardTileType, stateKey, state,
  statePropertyBag, setProperty,
  refreshTile, saveProperties, workspace }) => {
  const propertyBag = statePropertyBag ? toJS(statePropertyBag) : {};
  const _dashboardTileOptions = tileOptions({ dashboardTileType, stateKey, state, workspace });
  const settingsCallbacks = {};

  function onSaveSettings(e) {
    e.preventDefault();

    if (settingsCallbacks.onSaving)
      settingsCallbacks.onSaving();

    saveProperties(stateKey);
    refreshTile();
  }

  if (_dashboardTileOptions)
    return <div className='modal-content' style={{ minWidth: '50em' }}>
      <div className='modal-header'>
        <h5 className='modal-title'>Edit tile</h5>
      </div>
      <div className='modal-body'>
        {_dashboardTileOptions.Settings({ state, propertyBag, setProperty, dashboardTileType, stateKey, workspace, settingsCallbacks })}
        <div className='modal-footer'>
          <button className='btn btn-secondary' data-dismiss>Cancel</button>
          <button className='btn btn-primary' onClick={e => onSaveSettings(e)} >Save</button>
          <button className='btn btn-primary' onClick={e => onSaveSettings(e)} data-dismiss>Save &amp; Close</button>
        </div>
      </div>
    </div>

  const dashboardTile = GetRegisteredDashboardItem(dashboardTileType);
  return <div className='modal-content' style={{ minWidth: '50em' }}>
    <div className='modal-header'>
      <h5 className='modal-title'>Edit tile</h5>
    </div>
    <div className='modal-body'>
      <div className='form-group'>
        <TileCommonSettings state={state} propertyBag={propertyBag} setProperty={setProperty} dashboardTileType={dashboardTileType} stateKey={stateKey} settingsCallback={settingsCallbacks} />
      </div>
      {dashboardTile && dashboardTile.settingsView && <>
        <div className='form-group'>
          {React.createElement(dashboardTile.settingsView, { state, dashboardTileType, stateKey })}
        </div>
      </>}
      <div className='modal-footer'>
        <button className='btn btn-secondary' data-dismiss>Cancel</button>
        <button className='btn btn-primary' onClick={e => onSaveSettings(e)} >Save</button>
        <button className='btn btn-primary' onClick={e => onSaveSettings(e)} data-dismiss>Save &amp; Close</button>
      </div>
    </div>
  </div>
});

const ViewToolbarConnected = connect(
  (state, { dashboardTileType, stateKey }) => ({
    dashboardTileType,
    stateKey,
    state,
    tilesConfig: state.getIn(['dashboardTiles', 'tilesConfig', stateKey]),
    workspace: state.getIn(['dashboardTiles', 'tilesState', stateKey, 'workspace'])
  }),
  (dispatch, { stateKey }) => ({
    setTileProperties(properties) {
      const { requiresSave, requiresRefresh, forceRefresh } = properties ?? {};
      dispatch(dashboardTileSetProperties(stateKey, properties, requiresSave, requiresRefresh, forceRefresh));
    }
  })
)(({ dashboardTileType, stateKey, state, setTileProperties, tilesConfig, workspace, expandTile, isExpanded }) => {
  const _tilesConfig = toJS(tilesConfig) ?? {};
  const _dashboardTileOptions = tileOptions({ dashboardTileType, stateKey, state, workspace });
  if (_dashboardTileOptions)
    return <>
      {_dashboardTileOptions.View({ state, propertyBag: _tilesConfig, setProperty: setTileProperties, dashboardTileType, stateKey, workspace })}
      {!isExpanded && <button type='button' title='Expand' className='btn btn-sm' onClick={() => expandTile(true)}><i className="fas fa-window-maximize" /></button>}
      {isExpanded && <button type='button' title='Normal size' className='btn btn-sm' onClick={() => expandTile(false)}><i className="fas fa-window-minimize" /></button>}
    </>

  const dashboardTile = GetRegisteredDashboardItem(dashboardTileType);
  return <>
    {dashboardTile && dashboardTile.viewToolbar ? React.createElement(dashboardTile.viewToolbar, { state, dashboardTileType, stateKey }) : <></>}
    {dashboardTile && dashboardTile.onOpenWorkspace && <button type='button' title='Open workspace' className='btn btn-sm' onClick={() => dashboardTile.onOpenWorkspace({ state, dashboardTileType, stateKey: stateKey })}><i className='fas fa-external-link-alt fa-fw' /></button>}
    {!isExpanded && <button type='button' title='Expand' className='btn btn-sm' onClick={() => expandTile(true)}><i className="fas fa-window-maximize" /></button>}
    {isExpanded && <button type='button' title='Normal size' className='btn btn-sm' onClick={() => expandTile(false)}><i className="fas fa-window-minimize" /></button>}
  </>
});

const EditToolbarConnected = connect(
  (state, { dashboardTileType, stateKey }) => ({
    dashboardTileType,
    stateKey,
    state,
    tilesConfig: state.getIn(['dashboardTiles', 'tilesConfig', stateKey]),
    workspace: state.getIn(['dashboardTiles', 'tilesState', stateKey, 'workspace']),
    indicateRefreshRequired: state.getIn(['dashboardTiles', 'tilesState', stateKey, 'indicateRefreshRequired'])
  }),
  (dispatch, { stateKey }) => ({
    setTileProperties(properties) {
      const { requiresSave, requiresRefresh, forceRefresh } = properties ?? {};
      dispatch(dashboardTileSetProperties(stateKey, properties, requiresSave, requiresRefresh, forceRefresh));
    }
  })
)(({ dashboardTileType, stateKey, state, isEditing, setTileProperties, tilesConfig, workspace }) => {
  const _tilesConfig = toJS(tilesConfig) ?? {};
  const _dashboardTileOptions = tileOptions({ dashboardTileType, stateKey, state, workspace });
  if (_dashboardTileOptions)
    return _dashboardTileOptions.Edit({ state, propertyBag: _tilesConfig, setProperty: setTileProperties, dashboardTileType, stateKey, isEditing, workspace })

  const dashboardTile = GetRegisteredDashboardItem(dashboardTileType);
  if (!dashboardTile) return <></>

  return <>
    {dashboardTile && dashboardTile.editToolbar ? React.createElement(dashboardTile.editToolbar, { state, dashboardTileType, stateKey: stateKey }) : <></>}
    {dashboardTile && dashboardTile.onOpenWorkspace && <button type='button' title='Open workspace' className='btn btn-sm' onClick={() => dashboardTile.onOpenWorkspace({ state, dashboardTileType, stateKey: stateKey })}><i className='fas fa-external-link-alt fa-fw' /></button>}
  </>
});

const TileToolbarConnected = connect(
  (state, { dashboardTileType, stateKey }) => ({
    dashboardTileType,
    stateKey,
    isEditing: state.getIn(['dashboardTiles', 'isEditing']),
    tilesConfig: state.getIn(['dashboardTiles', 'tilesConfig', stateKey]),
    workspaceName: state.getIn(['dashboardTiles', 'tilesState', stateKey, 'workspace', 'name']),
    indicateRefreshRequired: state.getIn(['dashboardTiles', 'tilesState', stateKey, 'indicateRefreshRequired'])
  }),
  (dispatch, { dashboardTileType, stateKey }) => ({
    refreshTile() {
      dispatch(dashboardTileRefresh(stateKey, dashboardTileType));
    },
    expandTile(expand) {
      dispatch(dashboardTileExpand(expand ? stateKey : undefined, expand ? dashboardTileType : undefined));
    },
    cloneTile() {
      dispatch(dashboardTileEditClone(stateKey));
    },
    deleteTile() {
      dispatch(dashboardTileEditDelete(stateKey));
    }
  })
)(({ dashboardTileType, stateKey, tilesConfig, workspaceName, indicateRefreshRequired, isEditing,
  refreshTile, cloneTile, deleteTile, expandTile, isExpanded }) => {
  const title = tilesConfig.get('title');
  const isTitleVisible = tilesConfig.get('isTitleVisible') ?? true;
  const _title = title ? title : workspaceName;
  const color = tilesConfig.get('color');
  const isLight = getHLS(color).isLight ?? true;

  return <div className={`d-flex ${isLight ? 'fota-dashboardItem-header-dark-colour' : 'fota-dashboardItem-header-light-colour'}`} style={{ margin: '0', backgroundColor: color }}>
    <div className={isEditing ? 'fota-dashboardItem-drag-target' : ''} style={{ flex: '1', whiteSpace: 'nowrap' }}>
      <div className='mt-1 ml-1'>{isTitleVisible ? _title : ''}</div>
    </div>

    <div className='d-flex justify-content-end' >
      <button type='button' title='Refresh' className={`btn btn-sm ${(indicateRefreshRequired ? 'btn-primary' : '')}`} onClick={() => refreshTile()}><i className='fas fa-sync-alt fa-fw' /></button>

      {!isEditing && <>
        <ViewToolbarConnected dashboardTileType={dashboardTileType} stateKey={stateKey} isExpanded={isExpanded} expandTile={expandTile} />
      </>}

      {isEditing && <>
        <EditToolbarConnected dashboardTileType={dashboardTileType} stateKey={stateKey} isEditing={isEditing} />
        <SettingsEditorConnected dashboardTileType={dashboardTileType} stateKey={stateKey} />
        <ConfirmButton type='button' title='Clone Tile' onClick={() => cloneTile()}
          className='btn btn-sm'
          content={<i className='fas fa-clone fa-fw' />}
          confirmClassName='btn btn-warning btn-sm ml-2'
          confirm={<div style={{ display: 'inline', whiteSpace: 'nowrap' }}><i className='fas fa-clone fa-fw' />?</div>} />

        <ConfirmButton type='button' title='Delete Tile' onClick={() => deleteTile()}
          className='btn btn-sm'
          content={<i className='fas fa-trash fa-fw' />}
          confirmClassName='btn btn-warning btn-sm ml-2'
          confirm={<div style={{ display: 'inline', whiteSpace: 'nowrap' }}><i className='fas fa-trash fa-fw' />?</div>} />
      </>}
    </div>
  </div>
});

export const TileConnected = connect(
  (state, { stateKey }) => ({
    error: state.getIn(['dashboardTiles', 'tilesState', stateKey, 'error']),
    hideToolbar: state.getIn(['dashboardTiles', 'tilesConfig', stateKey, 'hideToolbar']),
    borderStyle: state.getIn(['dashboardTiles', 'tilesConfig', stateKey, 'borderStyle']) ?? '',
    isEditing: state.getIn(['dashboardTiles', 'isEditing'])
  }),
  (dispatch, { stateKey }) => ({
    hideError(index) {
      dispatch(dashboardTileHideError(stateKey, index));
    },
    hideAllErrors() {
      dispatch(dashboardTileHideAllErrors(stateKey));
    }
  })
)(({ dashboardTileType, stateKey, error, isEditing, hideToolbar, borderStyle, isExpanded, hideError, hideAllErrors }) => {
  const _showToolbar = isEditing || (!isEditing && !hideToolbar);

  let _borderStyle = '';
  switch (borderStyle) {
    case 'none':
      _borderStyle = 'fota-dashboardItem';
      break;
    case 'flat':
      _borderStyle = 'fota-dashboardItem fota-dashboardItem-border';
      break;
    case 'default':
    default:
      _borderStyle = 'fota-dashboardItem fota-dashboardItem-shadow';
      break;
  }

  function onCloseAll() {
    hideAllErrors();
  }

  return <div className={_borderStyle}>
    <ErrorBoundary>
      {_showToolbar && <div className='fota-dashboardItem-header' >
        <TileToolbarConnected isExpanded={isExpanded} dashboardTileType={dashboardTileType} stateKey={stateKey} />
      </div>}

      <div className={_showToolbar ? 'fota-dashboardItem-body' : 'fota-dashboardItem-body-no-header'}>
        {tileComponent({ dashboardTileType, stateKey }) ?? <DefaultTileConnected dashboardTileType={dashboardTileType} stateKey={stateKey} />}
          <div data_id='tile-errors' className='fota-dashboardItem-errors'>
          {error && error.size > 0 && <>
            {error.size > 0 && <div className='d-flex align-content-end justify-content-end'>
              <button type='button' className='btn btn-sm btn-light text-nowrap text-center' data-dismiss='toast' onClick={onCloseAll}>
                Dismiss all <i className='fas fa-times fa-fw'></i>
              </button>
            </div>}
            {toJS(error).map((e, i) => <TileError data_id={`tile-error-${i}`} key={`e-${i}`} index={i} error={e} hideError={hideError} />)}
            </>}
          </div>
      </div>
    </ErrorBoundary>
  </div>
});

const DefaultTileConnected = connect(
  (state, { stateKey, dashboardTileType }) => ({
    stateKey,
    refreshRequired: state.getIn(['dashboardTiles', 'tilesState', stateKey, 'refreshRequired']),
    workspaceIsInitialised: state.getIn(['dashboardTiles', 'tilesState', stateKey, 'workspaceIsInitialised']),
    dashboardTileType
  }),
  (dispatch, { stateKey, dashboardTileType }) => ({
    loadWorkspace() {
      dispatch(dashboardTileLoadWorkspace(stateKey));
    },
    refreshComponent() {
      dispatch(dashboardTileRefresh(stateKey, dashboardTileType));
    }
  })
)(({ stateKey, dashboardTileType,
  loadWorkspace, workspaceIsInitialised,
  refreshRequired, refreshComponent }) => {
  return <DefaultTile
    stateKey={stateKey}
    dashboardTileType={dashboardTileType}
    loadWorkspace={loadWorkspace}
    workspaceIsInitialised={workspaceIsInitialised}
    refreshComponent={refreshComponent}
    refreshRequired={refreshRequired}
  />
});

class DefaultTile extends React.Component {
  componentDidMount() {
    this.props.loadWorkspace()
  }

  componentDidUpdate(prevProps = {}) {
    if (this.props.refreshRequired && prevProps.refreshRequired !== this.props.refreshRequired)
      this.props.refreshComponent();
  }

  render() {
    if (!this.props.workspaceIsInitialised)
      return <></>

    const dashboardTile = GetRegisteredDashboardItem(this.props.dashboardTileType);
    if (!dashboardTile)
      return <div className=''><i className='text-danger fas fa-exclamation-triangle fa-fw'> </i>The dashboard type '{this.props.dashboardTileType}' has not been registered</div>

    return React.createElement(dashboardTile.component, this.props);
  }
}