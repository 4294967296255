import moment from 'moment';
import { createDisplayMapItem } from '../mapper/reportMapper';
import { assertType } from '../utility/type-checking';
import PropTypes from 'prop-types';
import { cloneInstance } from '../utility/property-utlility';
import { toGasSeasonFormat, toQuarterFormat } from '../utility/date-utility';

export function enrichReportRows(row, adjustments, inlineAdjustments, rowKey) {
  assertType({
    row: PropTypes.object.isRequired,
    adjustments: PropTypes.object.isRequired,
    inlineAdjustments: PropTypes.object.isRequired,
    rowKey: PropTypes.string
  }, { row, adjustments, inlineAdjustments, rowKey });

  if (!row || row.length === 0)
    return row;

  row.isAdjusted = (row.id < -500);
  row.isSynthetic = (row.id > -500) && (row.id < 0);
  row.id = Math.abs(row.id);

  if (!row.rowKey) {
    if (rowKey) {
      row.rowKey = `${rowKey}-${row.overridenId}`;
    } else {
      row.rowKey = `${row.overridenId}`;
    }
  }

  if (row.values) {
    row.values = row.values.map((v, index) => {
      if (v === undefined || v === null || typeof (v) === 'number') {
        const cell = {
          value: v
        };

        if (adjustments[row.id] !== undefined && adjustments[row.id][index] === true) {
          cell.adjustment = v;
          cell.hasExistingAdjustment = true;
        }

        if (inlineAdjustments[row.id] !== undefined && inlineAdjustments[row.id][index] === true) {
          cell.adjustment = v;
          cell.hasExistingInlineAdjustment = true;
        }

        return cell;
      }

      return v;
    });
  }

  if (row.children) {
    row.children.forEach(childRow => enrichReportRows(childRow, adjustments, inlineAdjustments, row.rowKey));
  }

  return row;
}

export function mergeNewRows(rows, adjustments, inlineAdjustments, displayKey, newRow) {
  assertType({
    rows: PropTypes.array.isRequired,
    adjustments: PropTypes.object.isRequired,
    inlineAdjustments: PropTypes.object.isRequired,
    displayKey: PropTypes.string,
    newRow: PropTypes.object
  }, { rows, adjustments, inlineAdjustments, displayKey, newRow });

  if (rows) {
    const existingAdjustmentsRowsMap = {};
    forEachInRowHierarchyJs(
      rows,
      row => {
        existingAdjustmentsRowsMap[`${row.key}`] = row;
      });

    forEachInRowHierarchyJs(
      rows,
      row => {
        if (row.rowKey === displayKey) {
          const newRowClone = {
            ...cloneInstance(newRow),
            rowKey: row.rowKey
          };

          enrichReportRows(newRowClone, adjustments, inlineAdjustments, `${row.overridenId}`);

          // apply any existing adjustment data
          forEachInRowHierarchyJs(
            [newRowClone],
            row => {
              const existingRow = existingAdjustmentsRowsMap[`${row.key}`];
              if (existingRow !== undefined) {
                for (let index = 0; index < existingRow.values.length; index++) {
                  const existingCell = existingRow.values[index];
                  const newCell = row.values[index];
                  newCell.adjustmentIsDirty = existingCell.adjustmentIsDirty;
                  newCell.adjustment = existingCell.adjustment;
                  newCell.hasExistingAdjustment = existingCell.hasExistingAdjustment;
                  newCell.hasExistingInlineAdjustment = existingCell.hasExistingInlineAdjustment;
                }
              }
            });

          // copy ALL the new properties to the existing object row
          Object.assign(row, newRowClone);
        }
      }
    );
  }
}

export function buildInlineAdjustmentsRequests(rows, columns) {
  assertType({
    rows: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired
  }, { rows, columns });

  const response = [];

  const columnIndexDateTimeLookUp = Object.fromEntries(columns.map((c, index) => [index, c.dateTime.format()]));
  forEachInRowHierarchyJs(
    rows,
    row => {
      const adjustments = [];
      row.values.forEach((cell, colIndex) => {
        if ((cell.adjustmentIsDirty || cell.hasExistingInlineAdjustment) && cell.adjustment !== undefined) {
          adjustments.push({
            value: cell.adjustment,
            startDateTime: columnIndexDateTimeLookUp[colIndex]
          })
        }
      });

      if (adjustments.length > 0) {
        response.push({ rowKey: row.rowKey, adjustments });
      }
    });

  return response;
}

export function resetAllAdjustments(rows) {
  assertType({
    rows: PropTypes.array.isRequired
  }, { rows });

  forEachInRowHierarchyJs(
    rows,
    (row) => {
      if (row.values) {
        row.values.forEach((cell) => {
          delete cell.adjustmentIsDirty;
        });
      }
    });

  return rows;
}

function forEachInRowHierarchyJs(rows, func) {
  assertType({
    rows: PropTypes.array.isRequired,
    func: PropTypes.func.isRequired
  }, { rows, func });

  for (let index = 0; index < rows.length; index++) {
    const row = rows[index];
    const shouldContinue = forEachInRowHierarchyRecursive(row, index);
    if (shouldContinue !== undefined)
      return shouldContinue;
  }

  function forEachInRowHierarchyRecursive(row, index) {
    let path = undefined;
    if (Array.isArray(index)) {
      path = [...index];
    } else {
      path = [index];
    }

    const shouldContinue = func(row, path);
    if (shouldContinue !== undefined) {
      return shouldContinue;
    }

    if (row.children) {
      for (let index = 0; index < row.children.length; index++) {
        const childRow = row.children[index];
        const shouldContinueChild = forEachInRowHierarchyRecursive(childRow, [...path, 'children', index]);
        if (shouldContinueChild !== undefined)
          return shouldContinueChild;
      }
    }
  }
}

export function clearSelection(selection, rows, removeIsEditingFlag = false, rowsLookupMap = undefined) {
  assertType({
    selection: PropTypes.object.isRequired,
    rows: PropTypes.immutable.isRequired,
    removeIsEditingFlag: PropTypes.bool,
    rowsLookupMap: PropTypes.object,
    newRow: PropTypes.object
  }, { selection, rows, removeIsEditingFlag, rowsLookupMap });

  const stateChanges = [];
  if (selection && selection.range)
    selection.range = {};

  if (!rowsLookupMap)
    rowsLookupMap = buildRowsLookup(undefined, rows);

  Object.keys(rowsLookupMap).forEach(key => {
    const { row, path } = rowsLookupMap[key];
    const rowValues = row.get('values');
    if (rowValues && rowValues.size > 0) {
      for (let cellIndex = 0; cellIndex < rowValues.size; cellIndex++) {
        const cell = rowValues.get(cellIndex);
        if (cell.get('isSelected') === true) {
          stateChanges.push({ path: ['results', 'rows', ...path, 'values', cellIndex, 'isSelected'], value: false });
        }

        if (removeIsEditingFlag && cell.get('isEditing') === true) {
          stateChanges.push({ path: ['results', 'rows', ...path, 'values', cellIndex, 'isEditing'], isDeletion: true });
          delete cell.isEditing;
          delete selection.editCell;
          delete selection.cursorCell;
        }
      };
    }
  })

  return stateChanges;
}

// When we save adjustment we convert inline-adjustments to adjustments, after they are saved update state so they are no longer as inline-adjustments
export function resetInlineAdjustmentsAfterConversionToAdjustments(rows) {
  assertType({
    rows: PropTypes.immutable.isRequired
  }, { rows });

  const stateChanges = [];
  forEachInRowHierarchy(
    undefined,
    rows,
    ({ row, path }) => {
      row.get('values').forEach((cell, colIndex) => {
        if (cell.get('adjustmentIsDirty') || cell.get('hasExistingInlineAdjustment')) {
          stateChanges.push({ path: ['results', 'rows', ...path, 'values', colIndex, 'adjustmentIsDirty'], isDeletion: true });
          stateChanges.push({ path: ['results', 'rows', ...path, 'values', colIndex, 'hasExistingInlineAdjustment'], isDeletion: true });
        }
      });
    });

  return stateChanges;
}

export function setEditCell(selection, rows, rowKey, colKey, colIndex, rowsLookupMap) {
  assertType({
    selection: PropTypes.object.isRequired,
    rows: PropTypes.immutable.isRequired,
    rowKey: PropTypes.string,
    colKey: PropTypes.string,
    colIndex: PropTypes.number,
    rowsLookupMap: PropTypes.object
  }, { selection, rows, rowKey, colKey, colIndex, rowsLookupMap });

  let stateChanges = [];
  if (selection.editCell && rowKey === selection.editCell.rowKey && colKey === selection.editCell.colKey && colIndex === selection.editCell.colIndex)
    return stateChanges;

  if (!rowsLookupMap) rowsLookupMap = buildRowsLookup(undefined, rows);

  // remove the previous edit cell
  if (selection.editCell && selection.editCell.rowKey && selection.editCell.colIndex !== undefined) {
    let { path: prevEditCellUpdatePath } = rowsLookupMap[selection.editCell.rowKey];
    stateChanges.push({ path: ['results', 'rows', ...prevEditCellUpdatePath, 'values', selection.editCell.colIndex, 'isEditing'], value: false });
  }

  // set the next edit cell
  const edit = rowsLookupMap[rowKey];
  if (edit && edit.row.get('isSynthetic') !== true) {
    const cell = edit.row.get('values').get(colIndex);
    const cellAdjustment = cell.get('value');
    const cellValue = cell.get('adjustment');
    selection.range[`${rowKey}:${colKey}`] = true;
    selection.deferredValue = cellAdjustment !== undefined ? cellAdjustment : cellValue
    selection.editCell = {
      rowKey,
      colKey,
      colIndex
    };

    stateChanges.push({ path: ['results', 'rows', ...edit.path, 'values', colIndex, 'isEditing'], value: true });
  }

  return stateChanges;
}

export function setCellAdjustmentValue(displayMap, rows, rowKey, colIndex, value) {
  assertType({
    displayMap: PropTypes.immutable.isRequired,
    rows: PropTypes.immutable.isRequired,
    rowKey: PropTypes.string.isRequired,
    colIndex: PropTypes.number.isRequired,
    value: PropTypes.any // string or number
  }, { displayMap, rows, rowKey, colIndex, value });

  if (value !== '' && value !== undefined && isNaN(value))
    return [];

  let stateChanges = [];

  const parts = rowKey.split('-');
  const rowId = `${parts[parts.length - 1]}`;
  forEachInRowHierarchy(
    displayMap,
    rows,
    ({ row, key, path }) => {
      const rowValues = row.get('values');
      if (rowValues && rowValues.size > 0 && row.get('isSynthetic') !== true && `${row.get('overridenId')}` === rowId) {
        const cell = rowValues.get(colIndex);
        if (cell) {
          stateChanges.push({ path: ['results', 'rows', ...path, 'values', colIndex, 'adjustment'], value: value });
          if (`${cell.get('value')}` !== `${value}`) {
            stateChanges.push({ path: ['results', 'rows', ...path, 'values', colIndex, 'adjustmentIsDirty'], value: true });
            stateChanges.push({ path: ['adjustments', 'dirtyCellsMap', toAddress(key, colIndex)], value: true });
          } else {
            stateChanges.push({ path: ['results', 'rows', ...path, 'values', colIndex, 'adjustmentIsDirty'], isDeletion: true });
            stateChanges.push({ path: ['adjustments', 'dirtyCellsMap', toAddress(key, colIndex)], isDeletion: true });
          }
        }
      }
    },
    true);

  return stateChanges;
}

export function setSelectionAdjustmentValue(displayMap, rows, columns, value) {
  assertType({
    displayMap: PropTypes.immutable.isRequired,
    rows: PropTypes.immutable.isRequired,
    columns: PropTypes.array.isRequired,
    value: PropTypes.any // string or number
  }, { displayMap, rows, columns, value });

  if (value === null || (value !== undefined && isNaN(value)))
    value = '';

  let stateChanges = [];
  const columnIndexKeyLookUp = Object.fromEntries(columns.map((c, index) => [index, c.key]));

  const selectionMap = {};
  forEachInRowHierarchy(
    displayMap,
    rows,
    ({ row }) => {
      if (row.get('isSynthetic') !== true) {
        row.get('values').forEach((cell, colIndex) => {
          if (cell.get('isSelected')) {
            const overridenId = row.get('overridenId');
            if (!selectionMap[overridenId]) selectionMap[overridenId] = {};
            selectionMap[overridenId][columnIndexKeyLookUp[colIndex]] = true;
          }
        });
      }
    },
    true);

  forEachInRowHierarchy(
    displayMap,
    rows,
    ({ row, key, path }) => {
      const selectedRow = selectionMap[row.get('overridenId')];
      if (selectedRow) {
        row.get('values').forEach((cell, colIndex) => {
          const colKey = columnIndexKeyLookUp[colIndex];
          if (selectedRow[colKey]) {
            if (`${cell.get('value')}` !== `${value}`) {
              stateChanges.push({ path: ['results', 'rows', ...path, 'values', colIndex, 'adjustment'], value: value });
              stateChanges.push({ path: ['results', 'rows', ...path, 'values', colIndex, 'adjustmentIsDirty'], value: true });
              stateChanges.push({ path: ['adjustments', 'dirtyCellsMap', toAddress(key, colIndex)], value: true });
            } else {
              stateChanges.push({ path: ['results', 'rows', ...path, 'values', colIndex, 'adjustmentIsDirty'], isDeletion: true });
              stateChanges.push({ path: ['adjustments', 'dirtyCellsMap', toAddress(key, colIndex)], isDeletion: true });
            }
          }
        });
      }
    },
    true);

  return stateChanges;
}

export function pasteTableToSelection(displayMap, rows, table, targetGrid) {
  assertType({
    displayMap: PropTypes.immutable.isRequired,
    rows: PropTypes.immutable.isRequired,
    table: PropTypes.array.isRequired,
    targetGrid: PropTypes.array.isRequired
  }, { displayMap, rows, table, targetGrid });

  const stateChanges = [];

  const minX = targetGrid[0].cells[0].colIndex;

  let y = undefined;
  forEachInRowHierarchy(
    displayMap,
    rows,
    ({ row, rowKey, key, path }) => {
      if (rowKey === targetGrid[0].rowKey) {
        y = 0;
      }

      if (y !== undefined && y < table.length) {
        for (let colIndex = minX, x = 0; x < table[y].length && colIndex < row.get('values').size; colIndex++) {
          const value = table[y][x];
          stateChanges.push({ path: ['results', 'rows', ...path, 'values', colIndex, 'adjustment'], value: value });
          stateChanges.push({ path: ['results', 'rows', ...path, 'values', colIndex, 'adjustmentIsDirty'], value: true });
          stateChanges.push({ path: ['adjustments', 'dirtyCellsMap', toAddress(key, colIndex)], value: true });
          x++;
        }
        y++;
      }
    },
    false);

  return stateChanges;
}

export function undoSelectedAdjustmentChanges(rows) {
  assertType({
    rows: PropTypes.immutable.isRequired
  }, { rows });

  let stateChanges = [];

  const selectionLookup = {}

  forEachInRowHierarchy(
    undefined,
    rows,
    ({ row, key }) => {
      row.get('values').forEach((cell, colIndex) => {
        if (cell.get('isSelected')) {
          selectionLookup[`${key}~${colIndex}`] = true;
        }
      });
    },
    true);

  forEachInRowHierarchy(
    undefined,
    rows,
    ({ row, key, path }) => {
      row.get('values').forEach((cell, colIndex) => {
        if (selectionLookup[`${key}~${colIndex}`] !== undefined) {
          if (cell.get('hasExistingAdjustment') || cell.get('hasExistingInlineAdjustment')) {
            stateChanges.push({ path: ['results', 'rows', ...path, 'values', colIndex, 'adjustment'], value: cell.get('value') });
          } else {
            stateChanges.push({ path: ['results', 'rows', ...path, 'values', colIndex, 'adjustment'], isDeletion: true });
          }

          stateChanges.push({ path: ['results', 'rows', ...path, 'values', colIndex, 'adjustmentIsDirty'], isDeletion: true });
          stateChanges.push({ path: ['adjustments', 'dirtyCellsMap', toAddress(key, colIndex)], isDeletion: true });
        }
      });
    },
    true);

  return stateChanges;
}

export function removeSelectedAdjustments(displayMap, rows, includeAll = false) {
  assertType({
    displayMap: PropTypes.immutable.isRequired,
    rows: PropTypes.immutable.isRequired,
    includeAll: PropTypes.bool
  }, { displayMap, rows });

  const stateChanges = [];
  if (rows) {
    const selectionMap = {};
    forEachInRowHierarchy(
      displayMap,
      rows,
      ({ row }) => {
        row.get('values').forEach((cell, colIndex) => {
          if (includeAll || cell.get('isSelected')) {
            if (cell.get('adjustment') !== undefined || cell.get('adjustmentIsDirty')) {
              const overridenId = row.get('overridenId');
              if (!selectionMap[overridenId]) selectionMap[overridenId] = {};
              selectionMap[overridenId][colIndex] = true;
            }
          }
        });
      },
      !includeAll);

    forEachInRowHierarchy(
      displayMap,
      rows,
      ({ row, key, path }) => {
        const selectedRow = selectionMap[row.get('overridenId')];
        if (selectedRow) {
          row.get('values').forEach((cell, colIndex) => {
            if (selectedRow[colIndex] === true) {
              if (cell.get('hasExistingAdjustment') || cell.get('hasExistingInlineAdjustment')) {
                stateChanges.push({ path: ['results', 'rows', ...path, 'values', colIndex, 'adjustment'], isDeletion: true });
                stateChanges.push({ path: ['results', 'rows', ...path, 'values', colIndex, 'adjustmentIsDirty'], value: true });
                stateChanges.push({ path: ['adjustments', 'dirtyCellsMap', toAddress(key, colIndex)], value: true });
              } else {
                stateChanges.push({ path: ['results', 'rows', ...path, 'values', colIndex, 'adjustment'], isDeletion: true });
                stateChanges.push({ path: ['results', 'rows', ...path, 'values', colIndex, 'adjustmentIsDirty'], isDeletion: false });
                stateChanges.push({ path: ['adjustments', 'dirtyCellsMap', toAddress(key, colIndex)], value: false });
              }
            }
          });
        }
      },
      true);
  }

  return stateChanges;
}

export function selectSingleCell(displayMap, rows, colIndex, colKey, rowKey, selection) {
  assertType({
    displayMap: PropTypes.immutable.isRequired,
    rows: PropTypes.immutable.isRequired,
    colIndex: PropTypes.number.isRequired,
    colKey: PropTypes.string.isRequired,
    rowKey: PropTypes.string.isRequired,
    selection: PropTypes.object.isRequired
  }, { displayMap, rows, colIndex, colKey, rowKey, selection });

  let stateChanges = [];
  let rowsLookupMap = buildRowsLookup(displayMap, rows);

  if (selection.cursorCell) {
    let { path: cursorCellUpdatePath } = rowsLookupMap[selection.cursorCell.rowKey] ?? {};
    if (cursorCellUpdatePath) {
      stateChanges.push({ path: ['results', 'rows', ...cursorCellUpdatePath, 'values', selection.cursorCell.colIndex, 'hasCursor'], isDeletion: true });
      delete selection.cursorCell;
    }
  }

  const { path } = rowsLookupMap[rowKey] ?? {};
  if (path) {
    selection.range[`${rowKey}:${colKey}`] = true;
    stateChanges.push({ path: ['results', 'rows', ...path, 'values', colIndex, 'isSelected'], value: true });
    stateChanges.push({ path: ['results', 'rows', ...path, 'values', colIndex, 'hasCursor'], value: true });
    selection.cursorCell = {
      rowKey: rowKey,
      colKey: colKey,
      colIndex: colIndex
    };
  }

  selection.startRowKey = rowKey;
  selection.startColIndex = colIndex;
  selection.endRowKey = rowKey;
  selection.endColIndex = colIndex;

  updateTimeSeriesMeta(rows, selection, rowsLookupMap);
  return stateChanges;
}

export function selectGridRange(displayMap, rows, columns, selection) {
  assertType({
    displayMap: PropTypes.immutable.isRequired,
    rows: PropTypes.immutable.isRequired,
    columns: PropTypes.array.isRequired,
    selection: PropTypes.object.isRequired
  }, { displayMap, rows, columns, selection });

  let stateChanges = [];
  const startColIndex = selection.startColIndex < selection.endColIndex ? selection.startColIndex : selection.endColIndex;
  const endColIndex = selection.startColIndex > selection.endColIndex ? selection.startColIndex : selection.endColIndex
  const rowsLookupMap = buildRowsLookup(displayMap, rows);

  if (selection.cursorCell) {
    const { path: cursorCellUpdatePath } = rowsLookupMap[selection.cursorCell.rowKey];
    if (cursorCellUpdatePath) {
      stateChanges.push({ path: ['results', 'rows', ...cursorCellUpdatePath, 'values', selection.cursorCell.colIndex, 'hasCursor'], isDeletion: true });
      delete selection.cursorCell;
    }
  }

  if (selection.endColIndex !== undefined && selection.endRowKey !== undefined) {
    const { path: cursorCellUpdatePath } = rowsLookupMap[selection.endRowKey];
    if (cursorCellUpdatePath) {
      stateChanges.push({ path: ['results', 'rows', ...cursorCellUpdatePath, 'values', selection.endColIndex, 'hasCursor'], value: true });
      delete selection.cursorCell;
      selection.cursorCell = {
        rowKey: selection.endRowKey,
        colKey: selection.endColKey,
        colIndex: selection.endColIndex
      };
    }
  }

  let isInSelectionRange = false;
  forEachInRowHierarchy(
    displayMap,
    rows,
    ({ row, rowKey, path }) => {
      if (isInSelectionRange === false) {
        if (rowKey === selection.startRowKey || rowKey === selection.endRowKey) {
          isInSelectionRange = true;
          selectCellsInRow(row, rowKey, path);
        }
      } else {
        if (selection.startRowKey !== selection.endRowKey)
          selectCellsInRow(row, rowKey, path);

        if (rowKey === selection.startRowKey || rowKey === selection.endRowKey) {
          isInSelectionRange = false;
        }
      }
    });

  function selectCellsInRow(row, rowKey, path) {
    for (let colIndex = startColIndex; colIndex <= endColIndex; colIndex++) {
      selection.range[`${rowKey}:${columns[colIndex].key}`] = true;
      stateChanges.push({ path: ['results', 'rows', ...path, 'values', colIndex, 'isSelected'], value: true });
    }
  }

  updateTimeSeriesMeta(rows, selection, rowsLookupMap);
  return stateChanges;
}

export function navigateToNextCell(displayMap, rows, columns, selection, direction, continueSelection) {
  assertType({
    displayMap: PropTypes.immutable.isRequired,
    rows: PropTypes.immutable.isRequired,
    columns: PropTypes.array.isRequired,
    selection: PropTypes.object.isRequired
  }, { displayMap, rows, columns, selection });

  let stateChanges = [];
  if (!selection.cursorCell && selection.editCell)
    selection.cursorCell = { ...selection.editCell }

  const cursorCell = selection.cursorCell;

  if (!cursorCell)
    return stateChanges;

  let nextRow = undefined;
  if (direction === 'up') {
    let prevRow = undefined;
    forEachInRowHierarchy(
      displayMap,
      rows,
      ({ row, rowKey }) => {
        if (cursorCell.rowKey === rowKey) {
          nextRow = prevRow;
          return true;
        }

        prevRow = row;
      }
    );
  }
  else if (direction === 'down' || direction === 'enter') {
    let currentRow = undefined;
    forEachInRowHierarchy(
      displayMap,
      rows,
      ({ row, rowKey }) => {
        if (currentRow && !nextRow) {
          nextRow = row;
          return true;
        }

        if (cursorCell.rowKey === rowKey) {
          currentRow = row;
        }
      });

    if (direction === 'enter' && !nextRow) {
      forEachInRowHierarchy(
        displayMap,
        rows,
        ({ row, rowKey }) => {
          if (cursorCell.rowKey === rowKey) {
            nextRow = row;
            return true;
          }
        });
    }
  } else {
    forEachInRowHierarchy(
      displayMap,
      rows,
      ({ row, rowKey }) => {
        if (cursorCell.rowKey === rowKey) {
          nextRow = row;
        }
      });
  }

  if (!nextRow)
    return stateChanges;

  let nextColumnIndex = cursorCell.colIndex;
  if (direction === 'left') nextColumnIndex--;
  if (direction === 'right') nextColumnIndex++;

  if (nextColumnIndex < 0 || nextColumnIndex >= columns.length)// nextRow.get('values').size)
    return stateChanges;

  const nextColKey = columns[nextColumnIndex].key;
  const rowsLookupMap = buildRowsLookup(displayMap, rows);

  if (selection.cursorCell) {
    const { path: cursorCellUpdatePath } = rowsLookupMap[selection.cursorCell.rowKey];
    if (cursorCellUpdatePath) {
      stateChanges.push({ path: ['results', 'rows', ...cursorCellUpdatePath, 'values', selection.cursorCell.colIndex, 'hasCursor'], isDeletion: true });
      delete selection.cursorCell;
    }
  }

  const nextRowKey = nextRow.get('rowKey');
  if (continueSelection) {
    if (selection.startRowKey === undefined) selection.startRowKey = nextRowKey;
    if (selection.startColIndex === undefined) selection.startColIndex = nextColumnIndex;

    selection.endRowKey = nextRowKey;
    selection.endColIndex = nextColumnIndex;
    stateChanges = [...stateChanges, ...selectGridRange(displayMap, rows, columns, selection)];
  }
  else {
    selection.startRowKey = nextRowKey;
    selection.startColIndex = nextColumnIndex;
    selection.endRowKey = nextRowKey;
    selection.endColIndex = nextColumnIndex;
    stateChanges = [
      ...stateChanges,
      ...setEditCell(selection, rows, nextRowKey, nextColKey, nextColumnIndex, rowsLookupMap),
      ...clearSelection(selection, rows, false, rowsLookupMap)];
  }

  const { path } = rowsLookupMap[nextRowKey];
  if (path) {
    selection.range[`${nextRowKey}:${columns[nextColumnIndex].key}`] = true;
    stateChanges.push({ path: ['results', 'rows', ...path, 'values', nextColumnIndex, 'isSelected'], value: true });
    stateChanges.push({ path: ['results', 'rows', ...path, 'values', nextColumnIndex, 'hasCursor'], value: true });
    selection.cursorCell = {
      rowKey: nextRowKey,
      colKey: nextColKey,
      colIndex: nextColumnIndex
    };
  }

  updateTimeSeriesMeta(rows, selection, rowsLookupMap);
  return stateChanges;
}

export function mergeDisplayMap(sourceDisplayMap, targetDisplayMap) {
  assertType({
    sourceDisplayMap: PropTypes.object.isRequired,
    targetDisplayMap: PropTypes.object.isRequired
  }, { sourceDisplayMap, targetDisplayMap });

  Object.entries(sourceDisplayMap).forEach(([key, sourceValue]) => {
    const sourceDisplayMode = getDisplayMode(sourceValue);
    const targetDisplayMode = getDisplayMode(targetDisplayMap[key]);

    if (!targetDisplayMode) {
      targetDisplayMap[key] = sourceValue;
    }
    else if (sourceDisplayMode === 'OnDemand' || sourceDisplayMode === 'OnDemand-Open') {
      if (targetDisplayMode === 'Open') {
        targetDisplayMap[key] = createDisplayMapItem('OnDemand-Open', sourceValue.id, sourceValue.overridenId, sourceValue.dependentId);
      }
      else if (targetDisplayMode === 'Closed') {
        targetDisplayMap[key] = createDisplayMapItem('OnDemand-Closed', sourceValue.id, sourceValue.overridenId, sourceValue.dependentId);
      }
    }
    else if (targetDisplayMode === 'OnDemand' && sourceDisplayMode === 'Closed') {
      targetDisplayMap[key] = createDisplayMapItem('Closed', sourceValue.id, sourceValue.overridenId, sourceValue.dependentId);
    }
    else if (targetDisplayMode === 'OnDemand' && sourceDisplayMode === 'Open') {
      targetDisplayMap[key] = createDisplayMapItem('Open', sourceValue.id, sourceValue.overridenId, sourceValue.dependentId);
    }
  });
}

export function getDisplayMapKeys(displayMap) {
  assertType({
    displayMap: PropTypes.object
  }, { displayMap });

  return Object.entries(displayMap).map(([displayKey, displayMapItem]) => {
    const displayKeys = displayKey.split('-');
    const key = displayKeys[displayKeys.length - 1];
    const nodeLevel = displayKeys.length;
    const displayMode = getDisplayMode(displayMapItem);
    const isOnDemand = displayMode === 'OnDemand'
      || displayMode === 'OnDemand-Open'
      || displayMode === 'OnDemand-Closed';

    return { key, displayKey, isOnDemand, nodeLevel };
  });
}

export function getDisplayMode(displayMapItem) {
  return displayMapItem ? displayMapItem.displayMode : undefined;
}

export function buildRowsLookup(displayMap, rows, includeClosed = false) {
  assertType({
    displayMap: PropTypes.immutable,
    rows: PropTypes.object.isRequired,
    includeClosed: PropTypes.bool
  }, { displayMap, rows, includeClosed });

  const rowsLookupMap = {};
  forEachInRowHierarchy(
    displayMap,
    rows,
    ({ row, key, rowKey, path }) => {
      rowsLookupMap[rowKey] = { path, row, key };
    },
    includeClosed
  );

  return rowsLookupMap;
}

export function forEachInRowHierarchy(displayMap, rows, func, includeClosed = false) {
  assertType({
    displayMap: PropTypes.immutable,
    rows: PropTypes.object.isRequired,
    func: PropTypes.func.isRequired,
    includeClosed: PropTypes.bool
  }, { displayMap, rows, func, includeClosed });

  for (let index = 0; index < rows.size; index++) {
    const row = rows.get(index);
    const shouldContinue = forEachInRowHierarchyRecursive(row, index);
    if (shouldContinue !== undefined)
      return shouldContinue;
  }

  function getDisplayMode(displayMapItem) {
    return displayMapItem ? displayMapItem.get('displayMode') : undefined;
  }

  function forEachInRowHierarchyRecursive(row, index) {
    let path = undefined;
    if (Array.isArray(index)) {
      path = [...index];
    } else {
      path = [index];
    }

    const rowKey = row.get('rowKey');
    const key = row.get('key');

    const shouldContinue = func({ row, rowKey, key, path });
    if (shouldContinue !== undefined) {
      return shouldContinue;
    }

    const displayMode = displayMap ? getDisplayMode(displayMap.get(rowKey)) : undefined;
    const rowChildren = row.get('children');
    if (rowChildren && rowChildren.size > 0 && (!displayMap || (includeClosed || displayMode === 'Open' || displayMode === 'OnDemand-Open'))) {
      for (let ci = 0; ci < rowChildren.size; ci++) {
        const childRow = rowChildren.get(ci);
        const shouldContinueChild = forEachInRowHierarchyRecursive(childRow, [...path, 'children', ci]);
        if (shouldContinueChild !== undefined)
          return shouldContinueChild;
      }
    }
  }
}

export function mapToValidationMessages(rows, messages = []) {
  assertType({
    rows: PropTypes.immutable.isRequired,
    messages: PropTypes.array.isRequired
  }, { rows, messages });

  const response = [];
  const rowsLookupMap = buildRowsLookup(undefined, rows, true);
  const names = Object.keys(rowsLookupMap).map(key => ({ key: rowsLookupMap[key].row.key, value: rowsLookupMap[key].row.name }));
  messages.forEach(m => {
    let ts = response.find(t => t.key === m.key);
    if (ts === undefined) {
      const name = names.find(n => n.key === m.key);
      ts = {
        key: m.key,
        name: (name !== undefined) ? name.value : `${m.key}`,
        errors: [],
        warnings: [],
        informations: []
      };
      response.push(ts);
    }

    switch (m.messageType) {
      case 'Error':
        ts.errors.push(m.text);
        break;
      case 'Warning':
        ts.warnings.push(m.text);
        break;
      case 'Information':
        ts.informations.push(m.text);
        break;
      default:
        break;
    }
  });

  return response;
}

export function buildAdjustmentsRequest(defaultTimeSeriesMeta, timeSeriesMetas, criteria = {}, dateTimes, rows) {
  assertType({
    defaultTimeSeriesMeta: PropTypes.object.isRequired,
    timeSeriesMetas: PropTypes.array.isRequired,
    criteria: PropTypes.object.isRequired,
    dateTimes: PropTypes.array.isRequired,
    rows: PropTypes.immutable.isRequired,
  }, { defaultTimeSeriesMeta, timeSeriesMetas, criteria, dateTimes, rows });

  const timeSeriesMap = {};
  forEachInRowHierarchy(
    undefined,
    rows,
    ({ row, key }) => {
      const _row = row.toJS();
      timeSeriesMap[key] = {
        name: _row.name,
        id: _row.id,
        key: `${_row.id}`,
        adjustments: _row.values.map((cell, index) => ({ cell, index })).filter(x => x.cell.adjustmentIsDirty || x.cell.hasExistingInlineAdjustment).map(x => {
          return {
            startDateTime: dateTimes[x.index],
            value: x.cell.adjustment,
            isDeletion: x.cell.adjustment === undefined
          }
        })
      }
    },
    true);

  const timeSeries = Object.keys(timeSeriesMap).map(key => timeSeriesMap[key]);

  const timeSeriesAdjustments = timeSeries.filter(a => a.adjustments && a.adjustments.length > 0).map(ts => {
    const meta = timeSeriesMetas.find(m => m.key === ts.key);
    return {
      identityId: ts.id,
      key: ts.key,
      lens: criteria.lens,
      adjustments: ts.adjustments,
      annotation: (meta && meta.annotation) ? meta.annotation : defaultTimeSeriesMeta.annotation,
      adjustmentType: (meta && meta.adjustmentType) ? meta.adjustmentType : defaultTimeSeriesMeta.adjustmentType
    };
  }).filter(ts => ts);

  return {
    timeSeriesAdjustments,
    timeZoneId: criteria.timeZoneId,
    lens: criteria.lens,
    conversionUnit: criteria.conversionUnit,
    conversionFactor: criteria.conversionFactor,
    operation: criteria.operation,
    absFromDate: moment.utc(criteria.fromDate).format(),
    absToDate: moment.utc(criteria.toDate).format()
  };
}

export function mergeAdjustments(sources) {
  let response = {};
  [...sources].forEach(source => {
    Object.keys(source).forEach(k => {
      response[k] = response[k] ?? [];
      for (let index = 0; index < source[k].length; index++) {
        const targetValue = response[k][index];
        if (targetValue === undefined || targetValue === null) {
          const value = source[k][index];
          response[k][index] = value;
        }
      };
    });
  });

  return response;
}

function toAddress(rowKey, colKey) {
  return `${rowKey}~${colKey}`;
}

function updateTimeSeriesMeta(rows, selection, rowsLookupMap) {
  assertType({
    selection: PropTypes.object.isRequired,
    rowsLookupMap: PropTypes.object.isRequired,
    rows: PropTypes.immutable.isRequired
  }, { rows, selection, rowsLookupMap });

  // add new select to list as required
  Object.keys(rowsLookupMap).forEach(key => {
    const { row, key: row_key } = rowsLookupMap[key];
    const timeSeriesMeta = selection.timeSeriesMeta.find(ts => ts.key === row_key);
    if (!timeSeriesMeta) {
      selection.timeSeriesMeta.push({
        key: row_key,
        identityId: row.get('id'),
        name: row.get('name'),
        isSelected: false,
        operation: '',
        timeZoneTreatment: '',
        comment: ''
      });
    }
  });

  // de-select all
  selection.timeSeriesMeta.forEach(ts => ts.isSelected = false);
  if (selection.startRowKey === selection.endRowKey || selection.endRowKey === undefined) {
    selection.timeSeriesMeta.find(ts => ts.key === rowsLookupMap[selection.startRowKey].row.get('key')).isSelected = true;
  }

  return selection;
}

export function selectTopLevelScenarioOverride(rootScenario, flatRows, scenarioOverrideMap) {
  assertType({
    rootScenario: PropTypes.string.isRequired,
    flatRows: PropTypes.array.isRequired
  }, { rootScenario, flatRows });

  let fullScenarioOverrideMap = undefined;

  if (rootScenario === '') {
    fullScenarioOverrideMap = {};
  }
  else {
    // Remove keys from the map that are no longer in the report
    const allKeys = flatRows.map(({ parentId, overridenId }) => `${parentId}|${overridenId}`);
    scenarioOverrideMap = Object.fromEntries(Object.entries(scenarioOverrideMap).filter(([key]) => allKeys.includes(key)));

    let baseScenarioOverrideMap = Object.fromEntries(flatRows.map(({ parentId, overridenId }) => [`${parentId}|${overridenId}`, '']));
    fullScenarioOverrideMap = { ...baseScenarioOverrideMap, ...scenarioOverrideMap };

    // Set all time series that have the corresponding scenario
    Object.keys(fullScenarioOverrideMap).forEach(key => {
      const row = flatRows.find(i => `${i.parentId}|${i.overridenId}` === key);

      if (row && row.scenarioAlternatesSummary)
        fullScenarioOverrideMap[key] = row.scenarioAlternatesSummary.some(sas => sas.scenarioName === rootScenario) ? rootScenario : '';
    });
  }

  return { scenarioOverrideMap:fullScenarioOverrideMap, rootScenario };
}

export function selectScenarioOverride(key, value, flatRows, scenarioOverrideMap, rootScenario) {
  assertType({
    key: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    flatRows: PropTypes.array.isRequired,
    scenarioOverrideMap: PropTypes.object.isRequired,
    rootScenario: PropTypes.string
  }, { key, value, flatRows, scenarioOverrideMap, rootScenario });

  // Remove keys from the map that are no longer in the report
  const allKeys = flatRows.map(({ parentId, overridenId }) => `${parentId}|${overridenId}`);
  scenarioOverrideMap = Object.fromEntries(Object.entries(scenarioOverrideMap).filter(([key]) => allKeys.includes(key)));

  // Set scenario to time series and its dependencies
  const rowIndex = flatRows.findIndex(i => `${i.parentId}|${i.overridenId}` === key);
  if(rowIndex !== -1) {
    const row = flatRows[rowIndex];
    for (let i = rowIndex, currentRow; i < flatRows.length && (currentRow = flatRows[i]) && (row.level < currentRow.level || row === currentRow); i++){
      scenarioOverrideMap[`${currentRow.parentId}|${currentRow.overridenId}`] = currentRow.scenarioAlternatesSummary.some(sas => sas.scenarioName === value) ? value :'';
    }

    // Clear scenario from parent time series if different
    let nextParentId = row.parentId;
    for (let i = rowIndex - 1, currentRow; i >= 0 && (currentRow = flatRows[i]); i--) {
      if (currentRow.overridenId !== nextParentId)
        continue;

      const nodeKey = `${currentRow.parentId}|${currentRow.overridenId}`;
      if (scenarioOverrideMap[nodeKey] !== undefined && scenarioOverrideMap[nodeKey] !== value)
        rootScenario = '';
      
      nextParentId = currentRow.parentId;
    }
  }

  return { scenarioOverrideMap, rootScenario };
}

export function resetSelection(selection) {
  assertType({
    selection: PropTypes.object.isRequired
  }, { selection });

  selection.editCell = undefined;
  selection.cursorCell = undefined;
  selection.range = {};
  selection.startColIndex = undefined;
  selection.startRowKey = undefined;
  selection.endColIndex = undefined;
  selection.endRowKey = undefined;
  return selection;
}

export function mergeStyles(existingStyles, existingSpecialisedStyles, responseStyles)
{
  if (responseStyles) {
    if (existingSpecialisedStyles){
      Object.entries(existingSpecialisedStyles).forEach(([property, value]) => {
        if (responseStyles[property]){
          responseStyles[property] = {...value, ...responseStyles[property]};
        } else {
          responseStyles[property] = value;
        }
      });
    }

    if (existingStyles){
      Object.entries(existingStyles).forEach(([property, value]) => {
        if (responseStyles[property]){
          responseStyles[property] = {...value, ...responseStyles[property]};
        } else {
          responseStyles[property] = value;
        }
      });
    }

    const specialProperties = ['commaSeparated', 'decimalPlaces', 'valueFormat'];
    const { styles, specialisedStyles } = Object.entries(responseStyles).reduce((accumulator, [key, value]) => {
      let styles = {}, specialisedStyles = {};

      Object.entries(value).forEach(([property, value]) => {
        let o = specialProperties.includes(property) ? specialisedStyles : styles;

        o[key] = o[key] || {};
        o[key][property] = value;
      });

      return {
        ...accumulator,
        styles: {
          ...accumulator.styles,
          ...styles
        },
        specialisedStyles: {
          ...accumulator.specialisedStyles,
          ...specialisedStyles
        }
      };
    }, {});

    return {styles, specialisedStyles};
  }
  else {
    return {styles:{}, specialisedStyles:{}};
  }
}

function getColumnTitleFormatter(lens, orientation) {
  const spacer = orientation === 'Vertical' ? ' ' : '\n';

  switch (lens) {
    case 'Year':
      return (bucket) => bucket;
    case 'GasYear':
      return (bucket, dateTime) => `GY ${spacer}${dateTime.format('YYYY')}`;
    case 'GasSeason':
      return (bucket, dateTime) => toGasSeasonFormat(dateTime);
    case 'Quarter':
      return (bucket, dateTime) => toQuarterFormat(dateTime);
    case 'Week':
      return (bucket, dateTime) => `${bucket}${spacer}${dateTime.format('YYYY')}`;
    case 'Month':
      return (bucket, dateTime) => `${dateTime.format('MMM')}${spacer}${dateTime.format('YYYY')}`;
    case 'Day':
      return (bucket, dateTime) => `${dateTime.format('ddd')}${spacer}${dateTime.format('D MMM')}`;
    default:
      return (bucket, dateTime) =>{
        if(bucket.endsWith('01')) return `${dateTime.format('D MMM')}${spacer}${bucket}`;
        return `${spacer}${bucket}`;
      }
    }
}

export function buildColumns(headers, lens, orientation) {
  assertType({
    lens: PropTypes.string.isRequired,
    orientation: PropTypes.string,
    headers: PropTypes.array.isRequired
  }, { headers, lens, orientation });

  const getColumnTitle = getColumnTitleFormatter(lens, orientation);

  return headers.map(i => {
    const dateTime = moment.utc(i.dateTime);
    const title = getColumnTitle(i.bucket, dateTime);

    return {
      key: `${i.bucket}-${dateTime.format('DD-MM-YYYY')}`,
      title: title,
      className: i.style,
      dateTime
    };
  });
}
