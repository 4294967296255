import { push } from "redux-first-history";
import { createAction } from '../utility/redux-utility';
import { csvExport, jsonExport, saveAs } from '../utility/file-utility';
import { authFetch } from '../auth';
import { ANALYSIS_API_URL, EVENTS_API_URL } from '../config';
import qs from 'querystring';
import moment from 'moment';
import {
  logInfoNotification,
  logErrorNotification
} from './log';

export const EVENTS_SEARCH_STARTED = 'EVENTS_SEARCH_STARTED';
export const eventsSearchStarted = createAction(EVENTS_SEARCH_STARTED);

export const eventsSearch = () => (dispatch, getState) => {
  const state = getState();

  const criteria = state.getIn(['events', 'search', 'criteria']),
        query = criteria.get('query'),
        customFilter = criteria.get('customFilter'),
        orderBy = criteria.get('orderBy'),
        orderByDirection = criteria.get('orderByDirection'),
        page = criteria.get('page'),
        pageSize = criteria.get('pageSize'),
        timeRangeFilter = criteria.get('timeRangeFilter'),
        facets = criteria.get('facets').toArray(),
        filters = criteria.get('filters').map(f => {
          const name = f.get('name'),
                value = f.get('value'),
                isNumber = !isNaN(value),
                dateTime = !isNumber ? moment.utc(value, moment.ISO_8601, true) : null;

          if (dateTime && dateTime.isValid()) return `${name} eq ${dateTime.format()}`;

          return `${name} eq '${value}'`;
        }).toArray();

  const top = pageSize || 25;
  const skip = page && page > 1 ? (page * top) - top : 0;

  // Azure search skip limit
  if (skip > 100000) return dispatch(logInfoNotification('Paging limit reached, please refine your search.'));

  let params = {
    query: query || '*',
    customFilter: customFilter,
    filters: filters,
    facets: facets,
    orderby: `${orderBy || 'raisedUtc'} ${orderByDirection || 'desc'}`,
    top: top,
    skip: skip
  };

  if (timeRangeFilter) {
    const nowUtc = moment.utc();
    const customFilter = params.customFilter ? `${params.customFilter} and` : ''

    switch (timeRangeFilter.toUpperCase()) {
      case '1H': params.customFilter = `${customFilter} raisedUtc ge ${nowUtc.subtract(1, 'h').format()}`; break;
      case '24H': params.customFilter = `${customFilter} raisedUtc ge ${nowUtc.subtract(24, 'h').format()}`; break;
      case '7D': params.customFilter = `${customFilter} raisedUtc ge ${nowUtc.subtract(7, 'd').format()}`; break;
      case '30D': params.customFilter = `${customFilter} raisedUtc ge ${nowUtc.subtract(30, 'd').format()}`; break;
      default: break;
    }
  }

  for (let i in params) if (params[i] === undefined) delete params[i];

  dispatch(eventsSearchStarted());

  authFetch(`${EVENTS_API_URL}/timeseries-events/search?${qs.stringify(params)}`)
    .then(response => response.json())
    .then(data => {
      dispatch(eventsSearchComplete(data));

      if (data.count === 1) {
        const result = data.results[0];

        dispatch(eventsSearchToggleExpand(result.id));
      }
    })
    .catch(error => {
      dispatch(eventsSearchComplete());
      dispatch(logErrorNotification(error));
    });
};

export const EVENTS_SEARCH_COMPLETE = 'EVENTS_SEARCH_COMPLETE';
export const eventsSearchComplete = createAction(EVENTS_SEARCH_COMPLETE, 'data');

export const EVENTS_SEARCH_TOGGLE_EXPAND = 'EVENTS_SEARCH_TOGGLE_EXPAND';
export const eventsSearchToggleExpand = createAction(EVENTS_SEARCH_TOGGLE_EXPAND, 'key');

export const eventsSearchLoadMessage = (key) => (dispatch, getState) => {
  authFetch(`${EVENTS_API_URL}/timeseries-events/${key}`)
    .then(response => response.text())
    .then(message => {
      dispatch(eventsSearchLoadMessageComplete(key, message));
    })
    .catch(error => {
      dispatch(eventsSearchLoadMessageComplete(''));
      dispatch(logErrorNotification(error));
    });
};

export const EVENTS_SEARCH_LOAD_MESSAGE_COMPLETE = 'EVENTS_SEARCH_LOAD_MESSAGE_COMPLETE';
export const eventsSearchLoadMessageComplete = createAction(EVENTS_SEARCH_LOAD_MESSAGE_COMPLETE, 'key', 'message');

export const eventsSearchExport = (type) => (dispatch, getState) => {
  const state = getState();

  const results = state.getIn(['events', 'search', 'results', 'data']).toJS();

  if (!results || !results.length) return;

  const columns = ['id', 'eventType', 'source', 'sourceId', 'identityId', 'status', 'originator', 'raisedUtc', 'reason', 'message'],
        startUtc = moment.utc(results[0].raisedUtc).format('DD-MMM-YYYY-HH-mm-ss'),
        endUtc = moment.utc(results[results.length - 1].raisedUtc).format('DD-MMM-YYYY-HH-mm-ss'),
        fileName = `events-export_${startUtc}_${endUtc}`;

  if (type === 'json') jsonExport(fileName, columns, results)
  else csvExport(fileName, columns, results);
};

export const eventsClearFilters = () => (dispatch, getState) => {
  dispatch(eventsUpdateCriteria({ filters: [] }));
};

export const eventsUpdateQuery = (query, customFilter) => (dispatch, getState) => {
  dispatch(eventsUpdateCriteria({ query, customFilter }));
};

export const eventsUpdateTimeRangeFilter = (timeRangeFilter) => (dispatch, getState) => {
  dispatch(eventsUpdateCriteria({ timeRangeFilter }));
};

export const eventsUpdateFilter = (key, value) => (dispatch, getState) => {
  const state = getState();

  let filters = state.getIn(['events', 'search', 'criteria', 'filters']).toJS(),
      filterIndex = filters.findIndex(f => f.name === key);

  if (filterIndex < 0 && (value === undefined || value === null || value === '')) return;

  if (filterIndex >= 0) {
    if (value === undefined || value === null || value === '') filters.splice(filterIndex, 1);
    else filters[filterIndex].value = value;
  }
  else if (value || value === 0) filters.push({
    name: key,
    value: value
  });

  dispatch(eventsUpdateCriteria({ filters }));
};

export const eventsUpdateSort = (key) => (dispatch, getState) => {
  const state = getState();

  const orderByDirection = state.getIn(['events', 'search', 'criteria', 'orderBy']) === key
          ? state.getIn(['events', 'search', 'criteria', 'orderByDirection']) === 'desc' ? 'asc' : 'desc'
          : 'desc';

  dispatch(eventsUpdateCriteria({ orderBy: key, orderByDirection }));
};

export const eventsUpdatePage = (page, pageSize) => (dispatch, getState) => {
  const state = getState();

  const currentPageSize = state.getIn(['events', 'search', 'criteria', 'pageSize']);

  dispatch(eventsUpdateCriteria({ page, pageSize: pageSize || currentPageSize }));
};

export const eventsUpdateCriteria = (payload) => (dispatch, getState) => {
  const state = getState();

  let search = state.getIn(['router', 'location', 'search']) || '';

  if (search.startsWith('?')) search = search.substring(1);

  let criteria = qs.parse(search);

  if (payload.filters) {
    payload.filters = Array.isArray(payload.filters) ? payload.filters : [payload.filters];
    payload.filters = payload.filters.filter(f => f.name && (f.value || f.value === 0 || f.value === false))
                                     .map(f => `${f.name}|${f.value}`);
  }

  Object.keys(payload).forEach(key => {
    if (payload.hasOwnProperty(key)) {
      if (payload[key] || payload[key] === 0 || payload[key] === false) criteria[key] = payload[key];
      else delete criteria[key];
    }
  });

  if (criteria.filters && !criteria.filters.length) delete criteria.filters;

  dispatch(push({ search: qs.stringify(criteria) }));
};

export const eventsInitialiseCriteria = (data) => (dispatch, getState) => {
  if (!data) return;

  let criteria = { ...data };

  Object.keys(criteria).forEach(key => {
    if (criteria.hasOwnProperty(key)) {
      if (!criteria[key] && criteria[key] !== 0 && criteria[key] !== false) delete criteria[key];
    }
  });

  if (Object.keys(data).length) dispatch(eventsInitialiseUserSettings(criteria));
};

export const EVENTS_INITIALISE_USER_SETTINGS = 'EVENTS_INITIALISE_USER_SETTINGS';
export const eventsInitialiseUserSettings = createAction(EVENTS_INITIALISE_USER_SETTINGS, 'data');

export const eventsSourceFileDownload = (sourcePath) => (dispatch, getState) => {
  authFetch(`${ANALYSIS_API_URL}/source-data-stream?path=${sourcePath}`)
    .then(response => {
      let filename = `source-file-${new Date().getTime()}`;

      if (response.headers && response.headers.has('content-disposition')) {
        const contentDisposition = response.headers.get('content-disposition'),
              matches = /filename="?([^";]+)/.exec(contentDisposition);

        if (matches.length > 1) filename = matches[1];
      }

      return response.blob().then(blob => saveAs(filename, blob));
    })
    .catch(error => {
      dispatch(logErrorNotification(error));
    });
};