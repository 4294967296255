import React, { Fragment, useEffect, useState } from 'react';
import { PopoverButton } from '../../shared/PopoverButton';
import { MenuItem } from '@mui/material';
import { DebugPopup } from '../../shared/DebugPopup';
import InfoPopup from '../../shared/InfoPopup';
import FormattedDateTime from '../../shared/FormattedDateTime';
import ColourPicker from '../../shared/ColourPicker';
import IdentityId from '../../shared/IdentityId2';
import { OnDemandDerivedDetailsConnected } from '../onDemandDerivedDetails/OnDemandDerivedDetailsConnected';
import JsonEditor from '../../shared/JsonEditor';
import { DelayedExecution } from '../../shared/DelayedExecution';
import moment from 'moment';
import { absRelDateToString } from '../../../utility/date-utility';

const delayedUpdateChartSettings = new DelayedExecution();

export function ChartBasketRowNext({
  basketView,
  reportCriteria,
  periods,
  setFocusPath,
  lenses,
  operations,
  series,
  removeFromBasket,
  conversionUnits,
  yAxes,
  updateSeriesProperty,
  updateChartProperty,
  updateSeriesWindowPeriod,
  toggleVisibility,
  toggleNameStyle,
  updateSeriesCustomName,
  basketTimeseries,
  moveTimeSeries,
  cloneTimeSeries,
  openEditDialog,
  openForecastsProjections,
  openComparisonProjections,
  seriesTypes,
  seriesDashStyles,
  seriesStacking,
  editHighchartsJson,
  highchartsJson,
  updateHighchartsJson,
  cancelHighchartsJson,
  previewHighchartsJson,
  applyHighchartsJson
}) {

  const {
    identityId,
    name,
    customName,
    expressionName,
    style,
    granularity,
    sourceTimeZoneId,
    createdFrom,

    lens = '',
    operation = '',
    conversionUnit,
    conversionFactor = '',
    lastUpdatedUtc,
    key,
    infoType,
    infoMessage,
    isXAxis,
    derivationData,

    // api
    window,
    period = '',
    windowType = 'none',
    asAtWindow,
    asAtWindowEnabled = false,
    shape: {
      name: shape
    } = {},

    scenario,
    comparisonSettings: {
      name: comparisonModeName = '',
      plotOrigin: comparisonPlotOrigin = {},
    } = {},

    // highcharts
    color,
    type,
    dashStyle,
    stacking,
    yAxis,
    lineWidth,
    isDisabled,
    connectNulls: isConnectNullsEnabled = false,
    marker: {
      enabled: isMarkerEnabled = false
    } = {}
  } = series;

  const forecast = {
    asAtType: series.asAtType,
    masterBuildStyle: series.masterBuildStyle,
    seriesStyle: series.seriesStyle,

    asAt: series.asAtType === 'adHocAsAt' || series.asAtType === 'asAt' ? {
      period: series.asAt
    } : undefined,

    forecastOffset: series.asAtType === 'forecastOffset' ? {
      cutoffTime: series.cutoffTime,
      period: series.forecastOffset + 'D'
    } : undefined,

    relativeAsAt: series.asAtType === 'relativeAsAt' ? {
      cutoffTime: series.relativeAsAtDate?.cutoffTime ?? '',
      period: series.relativeAsAtDate?.period ?? ''
    } : undefined,
    
    forecastWindow: series.asAtType === 'window' ? {
      from: series.forecastWindow.fromDateMode === 'abs' ? moment(series.forecastWindow.absFromDate).format('YYYY-MM-DD HH:mm') : series.forecastWindow.relFromDate,
      to: series.forecastWindow.toDateMode === 'abs' ? moment(series.forecastWindow.absToDate).format('YYYY-MM-DD HH:mm') : series.forecastWindow.relToDate
    } : undefined
  };

  const canChangeNameStyle = (customName ?? '').length > 0 || (expressionName ?? '').length > 0;
  const actualIndex = basketTimeseries.map(ts => ts.key).indexOf(key);

  const infoTypeClassName = infoType === 'error' ? {
    iconClassName: 'text-danger ml-1',
    faIconClassName: 'fa-exclamation-triangle'
  } : infoType === 'warning' ? {
    iconClassName: 'text-warning ml-1',
    faIconClassName: 'fa-exclamation-triangle'
  } : infoType === 'information' ? {
    iconClassName: 'text-info ml-1',
    faIconClassName: 'fa-exclamation-circle'
  } : null;

  const canConfigureChartSettings = !isXAxis;
  const [customNameLocal, setCustomNameLocal] = useState(customName);
  const [isEditingCustomNameLocal, setIsEditingCustomNameLocal] = useState(false);
  const [seriesLineWidthLocal, setSeriesLineWidthLocal] = useState(lineWidth);
  const [conversionFactorLocal, setConversionFactorLocal] = useState(conversionFactor);

  useEffect(()=>{
    setSeriesLineWidthLocal(lineWidth)
  }, [lineWidth, setSeriesLineWidthLocal]);

  useEffect(()=>{
    setConversionFactorLocal(conversionFactor)
  }, [conversionFactor, setConversionFactorLocal]);

  function onMoveUp() {
    moveTimeSeries(key, 'UP');
  }

  function onMoveDown() {
    moveTimeSeries(key, 'DOWN');
  }

  function onToggleNameStyle() {
    toggleNameStyle(key);
  }

  function onSetSeriesCustomName(e) {
    setCustomNameLocal(e.target.value);
  }

  function onCustomNameBegin() {
    setCustomNameLocal(customName);
    setIsEditingCustomNameLocal(true);
  }

  function onSeriesCustomNameHasLostFocus(e) {
    if (isEditingCustomNameLocal) {
      updateSeriesCustomName(key, customNameLocal);
    }
    setIsEditingCustomNameLocal(false);
  }

  function onSeriesCustomNameKeyPress(e) {
    const keyCode = e.charCode || e.keyCode || 0;
    if (keyCode === 13) {
      e.target.blur();
    }

    // if escape is pressed - cancel the edit
    if (keyCode === 27) {
      setIsEditingCustomNameLocal(false);
    }
  }

  function onRemoveFromBasket() {
    removeFromBasket(key);
  }

  function onClone(closePopover) {
    closePopover();
    cloneTimeSeries(key);
  }

  function onRemove(closePopover) {
    closePopover();
    removeFromBasket(key);
  }

  function onOpenComparisonProjections(closePopover, parentKey) {
    if (closePopover)
      closePopover();

    openComparisonProjections(parentKey ?? key);
  }

  function onOpenEditDialog(closePopover, targetFocusPath) {
    if (closePopover)
      closePopover();

    setFocusPath(targetFocusPath);
    openEditDialog({ key, shape: series.shape })
  }

  function onOpenForecastsProjections(closePopover, parentKey) {
    if (closePopover)
      closePopover();

    openForecastsProjections(parentKey ?? key);
  }

  function onUpdateLens(e) {
    updateSeriesProperty(key, 'lens', e.target.value, true);
  }

  function onUpdateOperation(e) {
    updateSeriesProperty(key, 'operation', e.target.value, true);
  }

  function onUpdateConversionUnit(e) {
    updateSeriesProperty(key, 'conversionUnit', e.target.value, true);
  }

  function onUpdateConversionFactor(e) {
    const newValue = e.target.value;
    setConversionFactorLocal(newValue);
    delayedUpdateChartSettings.execute(updateChartProperty, key, 'conversionFactor', newValue);
  }

  function onUpdateSeriesColour(color) {
    updateChartProperty(key, 'color', color.hex);
  }

  function onUpdateSeriesYAxis(e) {
    var number = e.target.value ? Number(e.target.value) : null;
    updateChartProperty(key, 'yAxis', number);
  }

  function onUpdateSeriesType(e) {
    updateChartProperty(key, 'type', e.target.value);
  }

  function onUpdateSeriesDashStyle(e) {
    updateChartProperty(key, 'dashStyle', e.target.value);
  }

  function onUpdateSeriesStacking(e) {
    updateChartProperty(key, 'stacking', e.target.value);
  }

  function onUpdateSeriesEnableMarkers(value) {
    updateChartProperty(key, 'marker', { enabled: value });
  }

  function onUpdateSeriesConnectNulls(value) {
    updateChartProperty(key, 'connectNulls', value);
  }

  function onUpdateSeriesLineWidth(e) {
    var newValue = e.target.value ? Number(e.target.value) : null;
    setSeriesLineWidthLocal(newValue);
    delayedUpdateChartSettings.execute(updateChartProperty, key, 'lineWidth', newValue);
  }

  function onUpdatePeriod(value) {
    updateSeriesWindowPeriod(key, value);    
    
    if (value !== '')
      updateSeriesProperty(key, 'windowType', 'none', true);
  }

  function onToggleVisibility() {
    toggleVisibility(key);
  }

  function onApplyHighchartsJson(key) {
    applyHighchartsJson(key);
    // synchromnise properties with delay input properties that use state
    if (highchartsJson) {
      setSeriesLineWidthLocal(highchartsJson.hasOwnProperty('lineWidth') ? highchartsJson.lineWidth : '');
    }
  }

  const positionMenu = <td className='pb-1 pt-2 pl-0 pr-0' >
    <div className={`d-flex fota-hover-hide`} >
      <button type='button' className='btn btn-xs btn-link fota-btn-toolbar p-0 m-0' disabled={actualIndex < 1} onClick={onMoveUp}>
        <i className='fas fa-arrow-up fa-fw' style={{ fontSize: '9px' }} />
      </button>
      <button type='button' className='btn btn-xs btn-link fota-btn-toolbar p-0 m-0' disabled={actualIndex + 1 >= basketTimeseries.length} onClick={onMoveDown}>
        <i className='fas fa-arrow-down fa-fw' style={{ fontSize: '9px' }} />
      </button>
    </div>
  </td>

  const initialMenu = <td className='pb-1 pt-2 pl-0 pr-0' >
    <div className='d-flex justify-content-end flex-nowrap p-0'>
      <div className='flex-fill justify-content-end' style={{ textAlign: 'right', width: '100px' }}>
        {identityId > 0 &&
          <IdentityId groupClassname='basket-view' timeSeriesKey={key} id={identityId}>
            <div>{identityId}</div>
          </IdentityId>}
        {derivationData && <OnDemandDerivedDetailsConnected basketTimeseries={basketTimeseries} timeSeriesKey={key} />}
      </div>

      <PopoverButton buttonClassName='ml-1 btn btn-xs btn-link text-decoration-none text-nowrap' buttonContent={<><i className='fas fa-ellipsis-h' /></>}  >
        {({ closePopover }) => (<>

          <MenuItem className='mt-3' type='button' onClick={() => onOpenEditDialog(closePopover)}>
            <span><i className='fas fa-edit fa-fw mr-3'></i>Edit</span>
          </MenuItem>

          <MenuItem type='button' onClick={() => onClone(closePopover)}>
            <span><i className='fas fa-clone fa-fw mr-3'></i>Clone</span>
          </MenuItem>

          <hr style={{ margin: '0' }} />

          <MenuItem type='button' disabled={style === 'Simple'} onClick={() => onOpenForecastsProjections(closePopover)}>
            <span><i className="fas fa-code-branch fa-fw mr-3"></i>Select AsAts</span>
          </MenuItem>

          <MenuItem type='button' onClick={() => onOpenComparisonProjections(closePopover)}>
            <span><i className="fas fa-not-equal fa-fw mr-3"></i>Select comparisons</span>
          </MenuItem>

          <hr style={{ margin: '0' }} />

          <MenuItem type='button' onClick={() => onRemove(closePopover)}>
            <span><i className='fas fa-trash fa-fw mr-3'></i>Remove</span>
          </MenuItem>

          {canConfigureChartSettings && <MenuItem type='button' onClick={() => onToggleVisibility()}>
            <span><i className={`fas ${isDisabled ? 'fa-eye-slash' : 'fa-eye'} fa-fw mr-3`}></i>Visibility</span>
          </MenuItem>}

          <hr style={{ margin: '0' }} />

          <div className='dropdown-hline-items justify-content'>
            <button type='button' className='btn btn-sm' disabled={actualIndex < 1} onClick={onMoveUp}>
              <i className='fas fa-arrow-up fa-fw' />
            </button>
            <button type='button' className='btn btn-sm' disabled={actualIndex + 1 >= basketTimeseries.length} onClick={onMoveDown}>
              <i className='fas fa-arrow-down fa-fw' />
            </button>
          </div>
        </>)}
      </PopoverButton>
    </div>
  </td>;

  const nameColumn = <td style={{ width: '100%' }}>
    <div className='d-flex justify-content-between flex-nowrap'>
      <DebugPopup>{() => <JsonEditor data={series} />}</DebugPopup>
      <div className='mt-1 mb-2' onDoubleClick={onCustomNameBegin} style={{ flex: 1 }}>
        {!isEditingCustomNameLocal && <>
          {name}
          {!!infoTypeClassName && (
            <InfoPopup {...infoTypeClassName}>
              {infoMessage}
            </InfoPopup>
          )}
        </>}
        {isEditingCustomNameLocal && (
          <input type='text' className='form-control form-control-sm border-top-0 border-bottom-0 border-right-0 rounded-0' placeholder='Please enter a name'
            value={customNameLocal ?? ''}
            autoFocus={true}
            onChange={onSetSeriesCustomName}
            onKeyUp={onSeriesCustomNameKeyPress}
            onBlur={onSeriesCustomNameHasLostFocus} />
        )}
      </div>
      <div className='d-flex flex-row fota-hover-hide'>
        <div>
          <button type='button' className='btn btn-xs btn-link fota-btn-toolbar' style={{ margin: '0px', minWidth: '0px' }} onClick={onCustomNameBegin}><i className='fas fa-pen fa-fw' style={{ color: 'rgba(0, 0, 0, 0.3)' }} /></button>
          <button type='button' className='btn btn-xs btn-link fota-btn-toolbar' style={{ margin: '0px', minWidth: '0px' }} disabled={!canChangeNameStyle} onClick={onToggleNameStyle} ><i className='fas fa-sort xfa-fw' style={{ color: 'rgba(0, 0, 0, 0.3)' }} /></button>
        </div>
      </div>
    </div>
  </td>;

  const commonSeriesColumns = <>
    <td className='pb-1 pt-2 text-nowrap'>{style}</td>
    <td className='pb-1 pt-2 text-nowrap'>{granularity} ({sourceTimeZoneId})</td>
    <td className='pb-1 pt-2 text-nowrap'><FormattedDateTime>{lastUpdatedUtc}</FormattedDateTime></td>
  </>;

  const evoColumn = <td className='pt-2'>
    <div className='d-flex justify-content-center pt-1'>
      <input readOnly={true} type='checkbox' checked={asAtWindowEnabled || hasValue(reportCriteria.evo) ? true : false} className='basket-view-link' onClick={() => onOpenEditDialog(null, 'General|asAtWindow')} />
    </div>
  </td>;

  const lensRequestColumn = <td className='pyx-1 pr-0 text-nowrap' style={{ minWidth: '100px' }}>
    <div className={`input-group ${hasValue(lens) ? '' : 'basket-view-item-report-criteria'}`}>
      <select className='form-control form-control-sm border-right-0 border-top-0 border-bottom-0 rounded-0 px-0'
        value={getValue(lens, reportCriteria.lens)}
        onChange={onUpdateLens}>
        <option key='' value=''></option>
        {lenses.map(l => <option key={l} value={l}>{l}</option>)}
      </select>
    </div>
  </td>;

  const operationRequestColumn = <td className='pyx-1 pr-0 text-nowrap' style={{ minWidth: '100px' }}>
    <div className={`input-group ${hasValue(operation) ? '' : 'basket-view-item-report-criteria'}`}>
      <select className='form-control form-control-sm border-right-0 border-top-0 border-bottom-0 rounded-0 px-0'
        value={getValue(operation, reportCriteria.operation)}
        onChange={onUpdateOperation}>
        <option key='' value=''></option>
        {operations.map(l => <option key={l} value={l}>{l}</option>)}
      </select>
    </div>
  </td>;

  const unitRequestColumn = <td className='pyx-1 pr-0 text-nowrap' style={{ minWidth: '100px' }}>
    <div className={`input-group ${hasValue(conversionUnit) ? '' : 'basket-view-item-report-criteria'}`}>
      <select className='form-control form-control-sm border-right-0 border-top-0 border-bottom-0 rounded-0 px-0'
        value={getValue(conversionUnit, reportCriteria.conversionUnit)}
        onChange={onUpdateConversionUnit}>
        <option key='none' value=''></option>
        {conversionUnits.map(cu => (
          <optgroup key={cu.group} label={cu.group}>
            {cu.units.map(u => <option key={u.name} value={u.name}>{u.name}</option>)}
          </optgroup>
        ))}
      </select>
    </div>
  </td>;

  const factorRequestColumn = <td className='py-1' style={{ minWidth: '80px' }}>
    <div className={`input-group ${hasValue(conversionFactor) ? '' : 'basket-view-item-report-criteria'}`}>
      <input className='form-control form-control-sm border-top-0 border-bottom-0 rounded-0' type='number' style={{ width: '80px' }}
        value={getValue(conversionFactorLocal, reportCriteria.conversionFactor) ?? ''} onChange={onUpdateConversionFactor} />
    </div>
  </td>;

  const windowRequestColumn = function(){
    let defaultPeriod = undefined;

    switch(windowType) {
      case 'comparison' :
        return <td>
          <div className={`d-flex justify-content-center pt-1 basket-view-link ${hasValue(window) ? '' : 'basket-view-item-report-criteria'}`}>
            <div className='basket-view-link text-nowrap' onClick={() => onOpenComparisonProjections(null, createdFrom)}>
              {comparisonModeName}
            </div>
          </div>
        </td>
      case 'override':
        defaultPeriod = {
          from: {
            mode: window.fromDateMode,
            abs : window.absFromDate,
            rel : window.relFromDate
          },
          to :{
            mode: window.toDateMode,
            abs : window.absToDate,
            rel : window.relToDate
          }
        };
        break;      
      case 'none':
      default:
        defaultPeriod = periods.find(p => p.name === reportCriteria.window.period) ?? {
            from: {
              mode: reportCriteria.window.fromDateMode,
              abs : reportCriteria.window.absFromDate,
              rel : reportCriteria.window.relFromDate
            },
            to :{
              mode: reportCriteria.window.toDateMode,
              abs : reportCriteria.window.absToDate,
              rel : reportCriteria.window.relToDate
            }
          };
        break;
    }

    return <td className='pyx-1 pr-0 text-nowrap' style={{ minWidth: '200px' }}>
        <div className={`d-flex flex-row ${period || windowType === 'override' ? '' : 'basket-view-item-report-criteria'}`}>
          <select className='form-control form-control-sm border-right-0 border-top-0 border-bottom-0 rounded-0 px-0 mr-1'
                  value={period ?? ''}
                  onChange={e => onUpdatePeriod(e.target.value)}>
            {!period && defaultPeriod.name && <option key='-top-' value='' hidden >{defaultPeriod.name}</option>}
            {!period && !defaultPeriod.name && <option key='-top-' value='' hidden >{`${absRelDateToString(defaultPeriod.from.mode, defaultPeriod.from.abs, defaultPeriod.from.rel)} to ${absRelDateToString(defaultPeriod.to.mode, defaultPeriod.to.abs, defaultPeriod.to.rel)}`}</option>}
            <option key='-top-option-' value=''></option>
            {periods.map(period => <option key={period.name} value={period.name}>{period.name}</option>)}
          </select>
          <div className={`d-flex justify-content-center pt-1 fota-hover-hide ${windowType !== 'none' ? '' : 'basket-view-item-report-criteria'}`}>
            <div className='basket-view-link' onClick={() => onOpenEditDialog(null, 'General|window')}>
              <i className='fas fa-edit fa-fw opacity-50'/>
            </div>
          </div>
        </div>
      </td>
  }

  const plotOriginColumn = function(){
    const isEnabled = (reportCriteria.plotOriginEnabled || series.plotOriginEnabled || (windowType === 'comparison' && comparisonPlotOrigin.mode)) === true;
    let plotOriginMode = reportCriteria.plotOriginMode ?? series.plotOriginMode ?? comparisonPlotOrigin.mode;
    let absPlotOrigin = reportCriteria.absPlotOrigin ?? series.absPlotOrigin ?? comparisonPlotOrigin.absOriginDate;
    let relPlotOrigin = reportCriteria.relPlotOrigin ?? series.relPlotOrigin ?? comparisonPlotOrigin.relOriginDate;

    return <td>
      <div className={`d-flex justify-content-center pt-1 basket-view-link ${!isEnabled ? '' : 'basket-view-item-report-criteria'}`}
        onClick={() => onOpenEditDialog(null, 'General|plotOrigin')}>
        {isEnabled ? absRelDateToString(plotOriginMode, absPlotOrigin, relPlotOrigin) : '-'}
      </div>
    </td>};
      
  const asAtWindowRequestColumn = <td>
    <div className={`d-flex justify-content-center pt-1 basket-view-link ${!hasValue(reportCriteria.evo) || asAtWindowEnabled ? '' : 'basket-view-item-report-criteria'}`}
      onClick={() => onOpenEditDialog(null, 'General|asAtWindow')}>
      {(hasValue(reportCriteria.evo) || asAtWindowEnabled) ? <WindowView window={asAtWindow} altWindow={reportCriteria.evo} altText={'-'} /> : <>-</>}
    </div>
  </td>;

  const forecastRequestColumn = <td >
    <div className='d-flex justify-content-center basket-view-link pt-1' onClick={() => onOpenEditDialog(null, 'Forecast')}>
      {forecast.asAtType === 'asAt' &&
        <>
          <span style={{ fontSize: '10px', verticalAlign: 'text-bottom' }}>AsAt&nbsp;</span>
          {moment(forecast.asAt.period).format('YYYY-MM-DD HH:mm')}
        </>}
      {forecast.asAtType === 'adHocAsAt' &&
        <>
          <span style={{ fontSize: '10px', verticalAlign: 'text-bottom' }}>AdHoc&nbsp;</span>
          {moment(forecast.asAt.period).format('YYYY-MM-DD HH:mm')}
        </>}
      {forecast.asAtType === 'forecastOffset' &&
        <>
          <span style={{ fontSize: '10px', verticalAlign: 'text-bottom' }}>Offset&nbsp;</span>
          {`${forecast.forecastOffset.period} ${forecast.forecastOffset.cutoffTime ?? ''}`}
        </>}
      {forecast.asAtType === 'relativeAsAt' && <>
        <div className='text-nowrap'>
          <span style={{ fontSize: '10px', verticalAlign: 'text-bottom' }}>Relative&nbsp;</span>
          {`${forecast.relativeAsAt.period} ${forecast.relativeAsAt.cutoffTime ?? ''}`}
        </div>
      </>}
      {forecast.asAtType === 'window' && <>
        <div className='text-nowrap'>
          <span style={{ fontSize: '10px', verticalAlign: 'text-bottom' }}>Window&nbsp;</span>
            {`${forecast.forecastWindow.from} ${forecast.forecastWindow.to}`}          
        </div>
      </>}
      {!forecast.asAtType &&
        <>
          -
        </>}
    </div>
  </td>;

  const scenarioRequestColumn = <td>
    <div title={scenario}
      className='d-flex justify-content-center pt-1 basket-view-link'
      onClick={() => onOpenEditDialog(null, 'General|scenario')}>
      {scenario ? <div style={{ width: '60px', whiteSpace: 'nowrap', overflowX: 'hidden', textOverflow: 'ellipsis' }}>{scenario}</div> : '-'}
    </div>
  </td>;

  const shapeRequestColumn = <td>
    <div title={shape}
      className='d-flex justify-content-center pt-1 basket-view-link'
      onClick={() => onOpenEditDialog(null, 'Shape')}>
      {shape ? <div style={{ width: '60px', whiteSpace: 'nowrap', overflowX: 'hidden', textOverflow: 'ellipsis' }}>{shape}</div> : '-'}
    </div>
  </td>;

  const colourChartColumn = <td className='pb-1 pt-2'>
    {canConfigureChartSettings && <div className='d-flex justify-content-between align-items-center w-100'>
      <ColourPicker colour={color} onColourChange={onUpdateSeriesColour} />
    </div>}
  </td>;

  const yaxisChartColumn = <td className='px-0 pl-1 py-1' style={{ minWidth: '125px' }}>
    {canConfigureChartSettings && ((yAxes && !!yAxes.length) ? (
      <select className='form-control form-control-sm border-right-0 border-top-0 border-bottom-0 rounded-0 px-0'
        value={yAxis}
        onChange={onUpdateSeriesYAxis}>
        {!!yAxes.length
          ? yAxes.map((i, ix) => <option key={ix} value={ix}>{(i.title || {}).text || `y-Axis (${ix})`}</option>)
          : <option value=''>y-Axis (0)</option>}
      </select>
    ) : (
      <div className='form-control form-control-sm border-top-0 border-bottom-0 rounded-0'>
        y-Axis (0)
      </div>
    ))}
  </td>

  const seriesTypeChartColumn = <td className='pr-0 py-1' style={{ minWidth: '85px' }}>
    {canConfigureChartSettings && <select className='form-control form-control-sm border-right-0 border-top-0 border-bottom-0 rounded-0 px-0'
      value={type} onChange={onUpdateSeriesType}>
      {seriesTypes.find(s => s.value === type) === undefined && <option style={{ color: '#aaa' }} value={type} disabled>{type}</option>}
      {seriesTypes.map((s, si) => <option key={`seriesTypes-${si}`} value={s.value}>{s.text}</option>)}
    </select>}
  </td>;

  const seriesDashStyleChartColumn = <td className='pr-0 py-1' style={{ minWidth: '105px' }}>
    {canConfigureChartSettings && <select className='form-control form-control-sm border-right-0 border-top-0 border-bottom-0 rounded-0 px-0'
      value={dashStyle} onChange={onUpdateSeriesDashStyle}>
      {seriesDashStyles.find(s => s.value === dashStyle) === undefined && <option style={{ color: '#aaa' }} value={dashStyle} disabled>{dashStyle}</option>}
      {seriesDashStyles.map((s, si) => <option key={`seriesDashStyles-${si}`} value={s.value}>{s.text}</option>)}
    </select>}
  </td>;

  const stackingChartColumn = <td className='pr-0 py-1' style={{ minWidth: '90px' }}>
    {canConfigureChartSettings && <select className='form-control form-control-sm border-right-0 border-top-0 border-bottom-0 rounded-0 px-0'
      value={stacking} onChange={onUpdateSeriesStacking}>
      {seriesStacking.find(s => s.value === stacking) === undefined && <option style={{ color: '#aaa' }} value={stacking} disabled>{stacking}</option>}
      {seriesStacking.map((s, si) => <option key={`seriesStacking-${si}`} value={s.value}>{s.text}</option>)}
    </select>}
  </td>;

  const lineWidthChartColumn = <td className='py-1'>
    {canConfigureChartSettings && <input className='form-control form-control-sm border-top-0 border-bottom-0 rounded-0' type='number' step='1' min='1' max='10'
      value={seriesLineWidthLocal ?? ''} onChange={onUpdateSeriesLineWidth} />}
  </td>;

  const markerChartColumn = <td>
    {canConfigureChartSettings && <div className='d-flex justify-content-center'>
      <input className='mt-2' type='checkbox' checked={isMarkerEnabled} onChange={e => onUpdateSeriesEnableMarkers(e.target.checked)} />
    </div>}
  </td>;

  const connectNullColumn = <td>
    {canConfigureChartSettings && <div className='d-flex justify-content-center'>
      <input className='mt-2' type='checkbox' checked={isConnectNullsEnabled} onChange={e => onUpdateSeriesConnectNulls(e.target.checked)} />
    </div>}
  </td>;

  const highchartsColumn = <td className='py-1'>
    {canConfigureChartSettings && <PopoverButton onOpenPopover={() => editHighchartsJson(key)} buttonClassName='mt-1 btn btn-sm btn-link fota-btn-toolbar' buttonContent={<><i className='fas fa-file-code' /></>}  >
      {() => (<>
        <div className='modal-content' style={{ width: '50vw', height: '50vh' }}>
          <div className='modal-body'>
            <a href={`https://api.highcharts.com/highcharts/series`} target='_blank' rel='noopener noreferrer'>Highcharts api</a>
            <JsonEditor mode='code' data={highchartsJson} onEdited={e => updateHighchartsJson(key, e)} />
          </div>
          <div className='modal-footer'>
            <button type='button' className='btn btn-secondary' onClick={() => cancelHighchartsJson(key)} data-dismiss>Cancel</button>
            <button type='button' className='btn btn-secondary' onClick={() => previewHighchartsJson(key)} >Preview</button>
            <button autoFocus className='btn btn-primary' onClick={() => onApplyHighchartsJson(key)} data-dismiss>Apply</button>
          </div>
        </div>
      </>)}
    </PopoverButton>}
  </td>;

  const hideRemoveMenu = <td className='py-1' style={{ minWidth: '50px' }}>
    <div className='d-flex flex-row justify-content-end'>
      {canConfigureChartSettings && <button type='button' className='btn btn-sm btn-link' onClick={onToggleVisibility}>
        <i className={`fas ${isDisabled ? 'fa-eye-slash' : 'fa-eye'} fa-fw`}></i>
      </button>
      }
      <button type='button' className='btn btn-sm btn-link fota-btn-toolbar' onClick={onRemoveFromBasket}>
        <i className='fas fa-trash fa-fw'></i>
      </button>
    </div>
  </td>;

  let columns = [];
  if (basketView === 'request') {
    columns = [
      positionMenu,
      initialMenu,
      nameColumn,
      evoColumn,
      windowRequestColumn(),
      plotOriginColumn(),
      asAtWindowRequestColumn,
      lensRequestColumn,
      operationRequestColumn,
      unitRequestColumn,
      factorRequestColumn,
      forecastRequestColumn,
      scenarioRequestColumn,
      shapeRequestColumn,
      hideRemoveMenu
    ];
  }
  else if (basketView === 'chart') {
    columns = [
      positionMenu,
      initialMenu,
      nameColumn,
      colourChartColumn,
      yaxisChartColumn,
      seriesTypeChartColumn,
      seriesDashStyleChartColumn,
      stackingChartColumn,
      lineWidthChartColumn,
      markerChartColumn,
      connectNullColumn,
      highchartsColumn,
      hideRemoveMenu
    ];
  } else {
    columns = [
      positionMenu,
      initialMenu,
      nameColumn,
      commonSeriesColumns,
      lensRequestColumn,
      unitRequestColumn,
      factorRequestColumn,
      colourChartColumn,
      yaxisChartColumn,
      seriesTypeChartColumn,
      seriesDashStyleChartColumn,
      stackingChartColumn,
      lineWidthChartColumn,
      hideRemoveMenu
    ];
  }

  return (
    <tr className={'fota-analysis-table-row-hover'} style={{ textDecoration: isDisabled ? 'line-through' : '' }} >
      {columns.map((c, i) => <Fragment key={i} >{c}</Fragment>)}
    </tr>
  );
}

function WindowView({ window, altWindow, altText }) {
  const w = window ?? altWindow;
  if (!w) return altText ?? 'N/A';

  const { fromDateMode, absFromDate, relFromDate, toDateMode, absToDate, relToDate } = w;

  return <span className='text-nowrap'>
    {w.period ?
      <>{w.period}</> :
      <>
        {absRelDateToString(fromDateMode, absFromDate, relFromDate)}
        &nbsp;to&nbsp;
        {absRelDateToString(toDateMode, absToDate, relToDate)}
      </>}
  </span>;
}

function hasValue(value) {
  if (value === undefined ||
    value === '' ||
    value === null)
    return false;

  return true;
}

function getValue(value, fallback) {
  if (!hasValue(value))
    return fallback;

  return value;
}