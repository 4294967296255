import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { push } from "redux-first-history";
import qs from 'querystring';
import Loading from '../../shared/Loading';
import Table from '../../shared/Table';
import Modal from '../../shared/Modal';
import FormattedDateTime from '../../shared/FormattedDateTime';
import {
  dashboardWorkspacesLoad,
  dashboardWorkspaceSelect,
  dashboardWorkspaceDelete,
  dashboardWorkspacesSort
} from '../../../actions/dashboard';
import {
  analysisWorkspaceLoad,
  analysisWorkspaceCloseIfOpen
} from '../../../actions/analysis';
import {
  reportsClear,
  reportsResetCriteria
} from '../../../actions/reports';

function DashboardItemRow({ onLoad, onShare, onSaveAs, onDelete, rawData, level = 0 }) {
  const { scope, subFolderPath, name, updatedBy, updatedUtc } = rawData;

  const paddingLeft = useMemo(() => {
    let padding = (level + 1) * 8;

    if (level > 0) padding += level * 16;

    return `${padding}px`;
  }, [level]);

  return (
    <tr>
      <td className='w-50 text-nowrap' style={{ paddingLeft, userSelect: 'none' }}>
        <div className='dropdown'>
          <button className='btn btn-link text-dark float-right bg-white py-0 px-1' type='button' data-toggle='dropdown'>
            <i className='fas fa-ellipsis-h fa-fw'></i>
          </button>
          <div className='dropdown-menu'>
            {scope === 'Shared' && (
              <button className='dropdown-item' type='button' onClick={() => onShare(rawData)}>
                <i className='fas fa-copy fa-fw mr-1' /> Copy url to clipboard
              </button>
            )}
            <button className='dropdown-item' type='button' onClick={() => onSaveAs(rawData)}>
              <i className='fas fa-save fa-fw mr-1' /> Save as
            </button>
            <button className='dropdown-item' type='button' onClick={() => onDelete(rawData)}>
              <i className='fas fa-trash fa-fw mr-1' /> Delete
            </button>
          </div>
        </div>
        <div style={{ cursor: 'pointer' }} onClick={() => onLoad(rawData)}>
          <span>{name}</span>
        </div>
      </td>
      <td className='w-25 text-nowrap'>{subFolderPath}</td>
      <td className='text-nowrap'>{updatedBy}</td>
      <td className='text-nowrap'><FormattedDateTime>{updatedUtc}</FormattedDateTime></td>
    </tr>
  );
}

function DashboardFolderRow(props) {
  const { rawData, parentPath = [], level = 0 } = props,
    { name, children } = rawData,
    hasChildren = children && children.length;

  const [displayChildren, setDisplayChildren] = useState(!!rawData.displayChildren);

  const paddingLeft = useMemo(() => {
    let padding = (level + 1) * 8;

    if (level > 0) padding += level * 16;

    return `${padding}px`;
  }, [level]);

  const baseKey = useMemo(() => [...parentPath, name].join('/'), [parentPath, name]);

  return (
    <Fragment>
      <tr>
        <td className='w-50 text-nowrap' colSpan={columns.length} style={{ paddingLeft, userSelect: 'none', cursor: 'pointer' }} onClick={() => setDisplayChildren(!displayChildren)}>
          <button type='button' className='btn btn-sm btn-link p-0 mr-2' style={{ lineHeight: 0 }}>
            <i className={'fas fa-fw ' + (displayChildren ? 'fa-folder-open' : 'fa-folder')} />
          </button>
          <span>{name}</span>
        </td>
      </tr>
      {(!!hasChildren && displayChildren) && children.map((i,index) => (
        <DashboardRow key={`${baseKey}/${i.name}-${index}`} {...{ ...props, rawData: i, parentPath: [...parentPath, name], level: level + 1 }} />
      ))}
      {(!hasChildren && displayChildren) && (
        <tr>
          <td colSpan={columns.length}>
            <div className='row no-gutters'>
              <div className='col text-center text-muted'>- Empty -</div>
            </div>
          </td>
        </tr>
      )}
    </Fragment>
  );
}

function DashboardRow(props) {
  const { itemType, children } = props.rawData;

  if (itemType === 'Root')
    return children && children.length
      ? children.map((i, ix) => <DashboardRow key={ix} {...{ ...props, rawData: i }} />)
      : (
        <tr>
          <td colSpan={columns.length}>
            <div className='row no-gutters'>
              <div className='col text-center'>No data</div>
            </div>
          </td>
        </tr>
      );

  return itemType === 'Folder'
    ? <DashboardFolderRow {...props} />
    : <DashboardItemRow {...props} />
}

const columns = [
  {
    key: 'name',
    title: 'Name',
    style: { paddingLeft: '8px' },
    sortable: true
  },
  {
    key: 'subFolderPath',
    title: 'Sub-folder path',
    sortable: true
  },
  {
    key: 'updatedBy',
    title: 'Last Updated By',
    sortable: true
  },
  {
    key: 'updatedUtc',
    title: 'Last Updated',
    sortable: true
  }];

const DashboardWorkspaces = connect(
  (state, ownProps) => {
    const { area, type, scope = 'private' } = ownProps;

    return {
      data: state.getIn(['dashboard', 'workspaces', area, type, scope, 'data']),
      metricsTotal: state.getIn(['dashboard', 'workspaces', area, type, scope, 'metrics-total']),
      metricsRefreshed: state.getIn(['dashboard', 'workspaces', area, type, scope, 'metrics-refreshed']),
      metricsFiltered: state.getIn(['dashboard', 'workspaces', area, type, scope, 'metrics-filtered']),
      isLoading: state.getIn(['dashboard', 'workspaces', area, type, scope, 'isLoading']),
      orderBy: state.getIn(['dashboard', 'workspaces', area, type, scope, 'orderBy']),
      orderByDirection: state.getIn(['dashboard', 'workspaces', area, type, scope, 'orderByDirection'])
    };
  },
  (dispatch, ownProps) => {
    const { area, type, scope = 'private' } = ownProps;

    return {
      refreshWorkspaces() {
        dispatch(dashboardWorkspacesLoad({ area, type, scope }));
      },
      loadWorkspace(data) {
        const { id } = data;

        switch (type) {
          case 'analysis':
            dispatch(analysisWorkspaceLoad(id, '/analysis/chart'));
            break;
          case 'report': {
            const { scope, folderPath, subFolderPath, name } = data;
            const url = `/reports/${scope}/${encodeURIComponent(folderPath || '')}/${encodeURIComponent(subFolderPath || '')}/${encodeURIComponent(name || '')}`.replace(/\/+/g, '/');

            dispatch(reportsClear());
            dispatch(reportsResetCriteria());
            dispatch(push(url));
            break;
          }
          default: break;
        }
      },
      openSaveAsWorkspace(data) {
        dispatch(dashboardWorkspaceSelect(data));
      },
      deleteWorkspace({ id, name, callback }) {
        dispatch(dashboardWorkspaceDelete({ id, name, callback, area, type, scope }));

        switch (type) {
          case 'analysis': dispatch(analysisWorkspaceCloseIfOpen(id)); break;
          default: break;
        }
      },
      updateSort({ area, type, scope, orderBy }) {
        dispatch(dashboardWorkspacesSort({ area, type, scope, orderBy }));
      }
    }
  }
)(({ isLoading, title, data, metricsTotal, metricsRefreshed, metricsFiltered, area, type, scope, refreshWorkspaces, loadWorkspace, openSaveAsWorkspace, deleteWorkspace, updateSort, orderBy, orderByDirection }) => {
  const modalRef = useRef();

  const getShareUrl = useCallback(data => {
    const { type = '', scope, folderPath, subFolderPath, name } = data;

    const path = `${scope}/${folderPath || ''}/${subFolderPath || ''}/${name || ''}`.replace(/\/+/g, '/');

    let url = '';

    switch (type.toLowerCase()) {
      case 'analysis': url = `${window.location.origin}/analysis?${qs.stringify({ workspacePath: `/analysis/${path}` })}`; break;
      case 'report': url = `${window.location.origin}/reports/${path.replace(/\s/g, '%20')}`; break;
      default: break;
    }

    return url;
  }, []);

  const onShare = useCallback(data => {
    const url = getShareUrl(data);

    if (!url) return;

    let element = document.createElement('textarea');

    element.value = url;
    element.setAttribute('readonly', '');
    element.style.position = 'absolute';
    element.style.left = '-9999px';
    document.body.appendChild(element);
    element.select();
    document.execCommand('copy');
    document.body.removeChild(element);
  }, [getShareUrl]);

  const onDelete = useCallback(({ id, name, callback }) => modalRef.current.onShow({
    title: 'Delete workspace?',
    message: `Are you sure you want to delete the workspace "${name}"?`,
    onApply() {
      deleteWorkspace({ id, name, callback });
    }
  }), [deleteWorkspace]);

  const onUpdateSort = useCallback(orderBy => {
    updateSort({ area, type, scope, orderBy });
  }, [area, type, scope, updateSort]);

  useEffect(() => {
    if (!data || !data.size) refreshWorkspaces();
  }, [data, refreshWorkspaces]);

  return (
    <Fragment>
      <Modal ref={modalRef} />
      <div className='card h-100'>
        <h6 className={'card-header ' + (scope === 'shared' ? 'bg-light text-primary' : 'bg-secondary text-white')}>

          <div className='row no-gutters'>
            <div className="col d-flex align-items-center">
              {title} - {area}
            </div>

            <div className="pull-right">
              <div className="input-group">
                  <button className={'btn btn-sm btn-link ' + (scope === 'shared' ? 'text-primary' : 'text-white')} type='button' onClick={refreshWorkspaces}>
                    <i className='fas fa-sync-alt fa-fw'></i>
                  </button>
              </div>
            </div>

          </div>
        </h6>
        <div className='card-body p-0' style={{ height: 'calc(100% - 70px)'}} >
          <Loading isLoading={isLoading} message='Loading...'>
            <div className='fota-dashboard table-responsive sticky-table h-100'>
              <Table className='table-hover w-100' columns={columns} data={data} useTBody={true} useStickyHeader={true}
                orderBy={orderBy} orderByDirection={orderByDirection} onSortChange={onUpdateSort}>
                <DashboardRow onShare={onShare} onLoad={loadWorkspace} onSaveAs={openSaveAsWorkspace} onDelete={onDelete} />
              </Table>
            </div>
          </Loading>
        </div>
        <div style={{fontSize:'10px', marginLeft:'1em', marginRight:'1em'}}>
          {metricsFiltered} of {metricsTotal}
          <div style={{ float: 'right' }}>
              {metricsRefreshed}
          </div>
        </div>
      </div>
    </Fragment>
  );
});

export default DashboardWorkspaces;