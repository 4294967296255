import { createReducer } from '../utility/redux-utility';
import {
  INSTANCES_LOAD_COMPLETE,
  INSTANCES_DATA_POINT_LOAD_COMPLETE,
  INSTANCES_LOADING,
  INSTANCES_SET_PAGE,
  INSTANCES_SET_PROMOTE_VISIBLE,
  INSTANCES_SET_PROMOTED_ASATUTC,
  INSTANCES_SET_TOOLBAR_PROPERTY,
  INSTANCES_TOGGLE_SELECTION
} from '../actions/instanceViewer';
import { fromJS } from 'immutable';

export const instanceViewer = createReducer(null, {
  [INSTANCES_LOAD_COMPLETE](state, action) {
    const selectedInstanceId = state.getIn(['selectedInstance', 'Id']);
    let selectedInstance = null;
    action.data.rows.forEach(x=> {
      x.selected = selectedInstanceId === x.id;
      if(x.selected)
        selectedInstance = x;
    });
    
    if(!selectedInstance) {
      return state.set('selectedInstance', null)
                  .set('selectedInstanceDataPoints', fromJS([]))
                  .setIn(['instances', 'rows'], fromJS(action.data.rows))
                  .setIn(['instances', 'total'], action.data.total);
    } 
    
    return state.setIn(['instances', 'rows'], fromJS(action.data.rows))
                .setIn(['instances', 'total'], action.data.total);
  },
  [INSTANCES_DATA_POINT_LOAD_COMPLETE](state, action) {
    return state.set('selectedInstanceDataPoints', fromJS(action.data));
  },
  [INSTANCES_LOADING](state, action) {
    return state.set('isLoading', action.isLoading);
  },
  [INSTANCES_SET_PAGE](state, action) {
    return state.setIn(['instances', 'page'], action.page);
  },
  [INSTANCES_SET_PROMOTE_VISIBLE](state, action) {
    return state.set('instancePromoteDialogVisible', action.value);
  },
  [INSTANCES_SET_PROMOTED_ASATUTC](state, action) {
    return state.set('instancePromoteAsAtUtc', action.value);
  },
  [INSTANCES_SET_TOOLBAR_PROPERTY](state, action) {
    return state.setIn(['toolbar', action.name], action.value);
  },
  [INSTANCES_TOGGLE_SELECTION](state, action) {
    return state.set('selectedInstance', action.instance)
                .updateIn(['instances', 'rows'], rows => rows.map(r => { 
                  const matching = r.get('id') === (action.instance && action.instance.get('id'));
                  const selected = matching && !r.get('selected') ? true : false;
                  return r.set('selected', selected);
              }));
  }
});