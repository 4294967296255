import moment from 'moment';
import { assertType } from './type-checking';
import PropTypes from 'prop-types';

export function getRequest({ comparisonMode, timeseries, timeZoneId, from, to, lens, asAtUtc, operation, conversionUnit, conversionFactor, conversionUnitStrictErrors, force24HourDST, alignTimeZones, 
                      materialisationMode, variantMode, masterBuildStyle, seriesStyle, asAtWindow, dataWindowRelativeToAsAt, periods, useNewAsAtQuery }) {
  assertType({
    comparisonMode : PropTypes.string, 
    timeseries : PropTypes.arrayOf(PropTypes.timeSeries),
    timeZoneId : PropTypes.string,
    from : PropTypes.object,
    to : PropTypes.object,
    lens : PropTypes.string,
    asAtUtc : PropTypes.string,
    operation : PropTypes.string,
    conversionUnit : PropTypes.string,
    conversionFactor : PropTypes.any,
    conversionUnitStrictErrors : PropTypes.bool,
    force24HourDST : PropTypes.bool,
    alignTimeZones : PropTypes.bool,
    materialisationMode : PropTypes.string,
    variantMode : PropTypes.string,
    masterBuildStyle : PropTypes.any,
    seriesStyle : PropTypes.any,
    asAtWindow : PropTypes.any,
    dataWindowRelativeToAsAt : PropTypes.bool,
    periods : PropTypes.array,
    useNewAsAtQuery : PropTypes.bool
  }, {comparisonMode, timeseries, timeZoneId, from, to, lens, asAtUtc, operation, conversionUnit, conversionFactor, conversionUnitStrictErrors, force24HourDST, alignTimeZones, 
    materialisationMode, variantMode, masterBuildStyle, seriesStyle, asAtWindow, dataWindowRelativeToAsAt});

  let request = {
    timeZoneId: timeZoneId,
    lens: lens,
    asAt: asAtUtc ? moment.utc(asAtUtc).format() : undefined,
    operation: operation ? operation : undefined,
    timeSeries: [],
    includeBuckets: false,
    includeTimeseries: true,
    comparison: comparisonMode,
    conversionUnit: conversionUnit ? conversionUnit : undefined,
    conversionFactor: conversionFactor ? conversionFactor : undefined,
    strictConversionErrors: conversionUnitStrictErrors ? conversionUnitStrictErrors : false,
    force24HourForDaylightSaving: force24HourDST ? (force24HourDST && (lens === 'Hour' || lens === 'HalfHour' || lens === 'QuarterHour')) : false,
    alignTimeZones: alignTimeZones,
    resultStyle: 'MultiFrame',
    materialisationMode: materialisationMode,
    variantMode: variantMode,
    masterBuildStyle: masterBuildStyle,
    seriesStyle: seriesStyle,
    useNewAsAtQuery : useNewAsAtQuery
  };

  if (from && to)
    request.window = getTimeSeriesRequestWindow({ from, to });

  if (timeseries) {
    if (Array.isArray(timeseries)) {
      // basketV2
      timeseries.forEach(ts => {
        let tsr = getTimeSeriesRequest({ ts, timeZoneId, asAtWindow, dataWindowRelativeToAsAt, periods });
        request.timeSeries.push(tsr);
      });
    }
    else {
      const timeseriesCollection = Object.keys(timeseries).map(k => timeseries[k]);
      timeseriesCollection.forEach(ts => {
        if (isIncludedInRequest({ timeseries: ts, timeseriesCollection, comparisonMode })) {
          let tsr = getTimeSeriesRequest({ ts, timeZoneId, asAtWindow, dataWindowRelativeToAsAt, periods });
          request.timeSeries.push(tsr);
        }
      });
    }
  }

  return request;
}

function isIncludedInRequest({ timeseries, timeseriesCollection, comparisonMode }) {
  const hasShapes = timeseriesCollection.some(ts => ts.shape);
  const hasComparisons = comparisonMode && comparisonMode !== 'none';

  if (hasComparisons && hasShapes)
    return timeseries.shape && (timeseries.comparisonMode || timeseries.comparisonSettings);

  if (hasComparisons)
    return timeseries.comparisonMode || timeseries.comparisonSettings;

  if (hasShapes)
    return timeseries.shape;

  return true;
}

export function getTimeSeriesRequest({ ts, timeZoneId, asAtWindow, dataWindowRelativeToAsAt, periods }) {
  assertType({
    ts : PropTypes.timeSeries,
    timeZoneId : PropTypes.string,
    asAtWindow : PropTypes.object,
    dataWindowRelativeToAsAt : PropTypes.bool,
    period: PropTypes.array
  }, {ts, timeZoneId, asAtWindow, dataWindowRelativeToAsAt});
    
  let timeSeriesRequest = {};
  timeSeriesRequest.id = ts.identityId;
  timeSeriesRequest.key = ts.key;
  timeSeriesRequest.lens = ts.lens;
  timeSeriesRequest.operation = ts.operation;
  timeSeriesRequest.conversionUnit = ts.conversionUnit;
  timeSeriesRequest.conversionFactor = ts.conversionFactor;
  timeSeriesRequest.valueType = ts.valueType;
  timeSeriesRequest.scenario = ts.scenario;
  timeSeriesRequest.scenarioOverrides = ts.scenarioOverrides;
  timeSeriesRequest.schemaName = ts.schemaName;
  timeSeriesRequest.complexScenario = ts.complexScenario;

  if(ts.materialisationModeDisabled === true)
    timeSeriesRequest.materialisationMode = 'Disabled';

  if(ts.variantModeDisabled === true)
    timeSeriesRequest.variantMode = 'Disabled';
  
  if (ts.dataWindowRelativeToAsAt !== undefined)
    timeSeriesRequest.dataWindowRelativeToAsAt = ts.dataWindowRelativeToAsAt;
  else
    timeSeriesRequest.dataWindowRelativeToAsAt = dataWindowRelativeToAsAt;

  if (ts.derivationData) {
    timeSeriesRequest.derivationData = ts.derivationData;
  }

  switch (ts.asAtType) {
    case 'asAt':
    case 'adHocAsAt':
      timeSeriesRequest.asAtType = 'asAt';
      timeSeriesRequest.asAt = { asAt: moment.utc(ts.asAt).format() };
      break;
    case 'forecastOffset':
      timeSeriesRequest.asAtType = 'forecastOffset';
      if(ts.forecastOffset.endsWith('D'))
        timeSeriesRequest.asAt = { forecastOffset: ts.forecastOffset.slice(0, -1), forecastOffsetLens: 'Day', cutoffTime: ts.cutoffTime };
      else if(ts.forecastOffset.endsWith('M'))
        timeSeriesRequest.asAt = { forecastOffset: ts.forecastOffset.slice(0, -1), forecastOffsetLens: 'Month', cutoffTime: ts.cutoffTime };
      else
        timeSeriesRequest.asAt = { forecastOffset: ts.forecastOffset, cutoffTime: ts.cutoffTime };      
      break;
    case 'relativeAsAt':
      timeSeriesRequest.asAtType = 'relativeAsAt';
      timeSeriesRequest.asAt = { relativeAsAtDate: ts.relativeAsAtDate.period, cutoffTime: ts.relativeAsAtDate.cutoffTime };
      break;
    case 'window':
      timeSeriesRequest.asAtType = 'window';
      timeSeriesRequest.asAt = {
        forecastWindowData: {
          asAtWindow: mapToRequestWindow(ts.forecastWindow),
          asAtSelectionOperation: ts.forecastWindowAsAtSelectionOperation,
          asAtWindowRelativeToDataPoint: ts.forecastWindowAsAtWindowRelativeToDataPoint === 'true',
          strictCountForOperation: ts.forecastWindowStrictCountForOperation === 'true',
          fillForwardAsAt: ts.forecastWindowFillForwardAsAt === 'true',
          dataPointOperation: ts.forecastWindowDataPointOperation,
        }
      };
      break;
    default:
      timeSeriesRequest.asAtType = undefined;
      break;
  }

  timeSeriesRequest.seriesStyle = ts.seriesStyle;
  timeSeriesRequest.masterBuildStyle = ts.masterBuildStyle;
  timeSeriesRequest.shape = ts.shape ? { ...ts.shape, locationTimeZoneId: timeZoneId } : undefined;
  timeSeriesRequest.window = undefined;
  timeSeriesRequest.aggregated = undefined;
  
  let _windowType = ts.windowType;
  if (!_windowType) {
    _windowType = 'none';
    if (ts.window) _windowType = 'override';
    if (ts.comparisonSettings) _windowType = 'comparison';
  }

  if (ts.asAtWindowEnabled) {
    timeSeriesRequest.asAtWindow = mapToRequestWindow(ts.asAtWindow);
  }
  
  if(!timeSeriesRequest.asAtWindow && asAtWindow) {
    timeSeriesRequest.asAtWindow = mapToRequestWindow(asAtWindow);
  }

  if (_windowType === 'comparison') {
    timeSeriesRequest.comparison = {
      mode: ts.comparisonSettings.mode
    };

    if (ts.comparisonSettings.plotOrigin) {
      if (ts.comparisonSettings.plotOrigin.mode === 'rel') {
        timeSeriesRequest.comparison.relOriginDate = ts.comparisonSettings.plotOrigin.relOriginDate;
      } else {
        timeSeriesRequest.comparison.absOriginDate = ts.comparisonSettings.plotOrigin.absOriginDate;
      }
    }

    timeSeriesRequest.windowType = ts.comparisonSettings.windowType;
    if (ts.comparisonSettings.window && ts.comparisonSettings.window.absFromDate) {
      timeSeriesRequest.window = {
        absFromDate: moment.utc(ts.comparisonSettings.window.absFromDate).format(),
        absToDate: moment.utc(ts.comparisonSettings.window.absFromDate).format()
      };
    } else {
      timeSeriesRequest.window = ts.comparisonSettings.window;
    }

    timeSeriesRequest.aggregated = getAggregatedWindow(ts.comparisonSettings.aggregated);
  }
  else {
    const tsPeriod = periods.find(p => p.name === ts.period);
    if (tsPeriod && tsPeriod.isValid) {
      timeSeriesRequest.windowType = 'simple';
      const window = {
        from: (tsPeriod.from) ? tsPeriod.from : {
          mode: tsPeriod.fromMode ?? 'abs',
          abs : tsPeriod.absFromDate
        },
        to: (tsPeriod.to) ? tsPeriod.to : {
          mode: tsPeriod.toMode ?? 'abs',
          abs : tsPeriod.absToDate
        } 
      };

      timeSeriesRequest.window = mapToRequestWindow(window);
    } else if (_windowType === 'override') {
      timeSeriesRequest.windowType = 'simple';
      timeSeriesRequest.window = mapToRequestWindow(ts.window);      
    }

    if (ts.plotOriginEnabled) {
      if (ts.plotOriginMode === 'abs') {
        timeSeriesRequest.absPlotOrigin = moment.utc(ts.absPlotOrigin).format();
      } else {
        timeSeriesRequest.relPlotOrigin = ts.relPlotOrigin;
      }
    }
  }
  
  return timeSeriesRequest;
}

function mapToRequestWindow(date) {
  let response = {};

  if (date.from) {
    if (date.from.mode === 'abs') response.absFromDate = moment.utc(date.from.abs).format();
    else response.relFromDate = date.from.rel;

    if (date.to.mode === 'abs') response.absToDate = moment.utc(date.to.abs).format();
    else response.relToDate = date.to.rel;

  } else {
    if (date.fromDateMode === 'abs') response.absFromDate = moment.utc(date.absFromDate).format();
    else response.relFromDate = date.relFromDate;

    if (date.toDateMode === 'abs') response.absToDate = moment.utc(date.absToDate).format();
    else response.relToDate = date.relToDate;
  }
  return response;      
}

export function getTimeSeriesRequestWindow({ from, to }) {
  if (!from)
    return;

  if (!to)
    return;

  let request = {};
  if (from.mode === 'rel')
    request.relFromDate = from.rel;
  else if (from.abs)
    request.absFromDate = moment.utc(from.abs).format();

  if (to.mode === 'rel')
    request.relToDate = to.rel;
  else if (to.abs)
    request.absToDate = moment.utc(to.abs).format();

  request.fromDateMode = from.mode;
  request.toDateMode = to.mode;

  return request;
}

function getDataPointEvolutionIdentityRequest(timeSeries, lens, operation, timeZoneId) {
  return {
    key: timeSeries.key,
    id: timeSeries.identityId,
    operation: timeSeries.operation ? timeSeries.operation : operation,
    lens: lens,
    shape: timeSeries.shape ? { ...timeSeries.shape, locationTimeZoneId: timeZoneId } : undefined
  };
}

function getAggregatedWindow(aggregated) {
  if (!aggregated)
    return;

  if (aggregated.windowType === 'range')
    return aggregated;

  if (aggregated.windowType === 'individual')
    return {
      windowType: aggregated.windowType,
      operation: aggregated.operation,
      dates: aggregated.dates.map(d => d.window)
    }
}

export function mapToTimeSeriesReportRequest(workspace, periods = [], useNewAsAtQuery = false) {
  const {timeseries, lens, operation, asAtUtc, timeZoneId, comparisonMode, conversionUnit, conversionFactor, conversionUnitStrictErrors, force24HourDST, alignTimeZones, materialisationMode, variantMode, masterBuildStyle, seriesStyle, isEvolution, dataWindowRelativeToAsAt } = workspace;
  let asAtWindow = undefined;
  if(isEvolution) {
    asAtWindow = getTimeSeriesRequestWindow(
      { from: { 
          mode: workspace.fromAsAtDateMode,
          abs: moment.utc(workspace.asAtFromUtc).format(), 
          rel: workspace.relAsAtFrom 
        },
        to: { 
          mode: workspace.toAsAtDateMode,
          abs: moment.utc(workspace.asAtToUtc).format(), 
          rel: workspace.relAsAtTo
        }
      });
  }

  return getRequest({
    comparisonMode,
    timeseries,
    from: { 
      mode: workspace.fromDateMode,
      abs: moment.utc(workspace.fromUtc).format(), 
      rel: workspace.relFrom
    },
    to: { 
      mode: workspace.toDateMode,
      abs: moment.utc(workspace.toUtc).format(), 
      rel: workspace.relTo
    },
    timeZoneId,
    lens,
    asAtUtc,
    operation,
    conversionUnit,
    conversionFactor,
    conversionUnitStrictErrors,
    force24HourDST,
    alignTimeZones,
    materialisationMode,
    variantMode,
    masterBuildStyle,
    seriesStyle,
    asAtWindow,    
    dataWindowRelativeToAsAt,
    periods,
    useNewAsAtQuery
  });
}

export function mapToTimeSeriesReportEvoRequest(workspace) {
  const request = {
    identityRequests: Object.values(workspace.timeseries).map(x => getDataPointEvolutionIdentityRequest(x, workspace.lens, workspace.operation, workspace.timeZoneId)),
    dataWindow: getTimeSeriesRequestWindow(
      { from: { 
          mode: workspace.fromDateMode, 
          abs: moment.utc(workspace.fromUtc).format(), 
          rel: workspace.relFrom },
        to: { 
          mode: workspace.toDateMode, 
          abs: moment.utc(workspace.toUtc).format(), 
          rel: workspace.relTo }
      }),
    asAtWindow: getTimeSeriesRequestWindow(
      { from: { 
          mode: workspace.fromAsAtDateMode, 
          abs: moment.utc(workspace.asAtFromUtc).format(), 
          rel: workspace.relAsAtFrom },
        to: { 
          mode: workspace.toAsAtDateMode, 
          abs: moment.utc(workspace.asAtToUtc).format(), 
          rel: workspace.relAsAtTo }
      }),
    lens: workspace.asAtLens,
    timeZoneId: workspace.timeZoneId    
  };

  return request;
}

export function mapToTimeSeriesTemplateNameRequest(timeseries, templateNameExpression, comparisonMode, operation, valueType, window, asAt, shape, timeZoneId, periods = []){
  const request = {
    timeseries: timeseries.map(ts => getTimeSeriesRequest({ts, periods})),
    comparison : comparisonMode,
    operation: operation,
    template: templateNameExpression,
    valueType: valueType,
    window: window,
    asAt: asAt,
    shape: shape,
    timeZoneId: timeZoneId,
    periods
  };

  return request;
}

export function mapToTemplatedNameExpression (workspace, periods) {
  return { 
    timeseries: workspace.timeseries ?? [], 
    templateNameExpression: workspace.templateNameExpression ?? '', 
    comparisonMode : workspace.comparisonMode, 
    asAt: workspace.asAtUtc, 
    operation: workspace.operation, 
    timeZoneId: workspace.timeZoneId, 
    window: { 
      absFromDate: workspace.fromUtc,
      relFromDate: workspace.relFrom, 
      absToDate: workspace.toUtc, 
      relToDate: workspace.relTo
    },
    periods
  }
}