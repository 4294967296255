export const ENVIRONMENT_TYPE = {
  LOCAL: '[LOCAL]',
  DEV: '[DEV]',
  ALPHA: '[ALPHA]',
  BETA: '[BETA]',
  STAGING: '[STAGING]',
  PROD: '[PROD]'
};

const hostname = window && window.location && window.location.hostname;
const environment = {
  'fota-timeseries.azurewebsites.net': ENVIRONMENT_TYPE.PROD,
  'fota-timeseries-staging.azurewebsites.net': ENVIRONMENT_TYPE.STAGING,
  'fota-timeseries-beta.azurewebsites.net': ENVIRONMENT_TYPE.BETA,
  'fota-timeseries-alpha.azurewebsites.net': ENVIRONMENT_TYPE.ALPHA,
  'dev-fota-timeseries.azurewebsites.net': ENVIRONMENT_TYPE.DEV
};

export const ENVIRONMENT = environment[hostname] || ENVIRONMENT_TYPE.LOCAL;
export const TITLE = 'FOTA Timeseries';
export const VERSION = 'v3.1.46';