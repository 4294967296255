import { fromJS } from 'immutable';
import moment from 'moment';
import { guid } from './utility/uid-utility';
import { referenceData } from './state/refererence-data';
import { storageAuctions } from './state/storage-auctions';
import { dashboardTiles } from './state/dashboard-tiles';

export default function getInitialState() {
  return fromJS({
    storageAuctions,
    referenceData : referenceData,
    screenCapture: {
      isVisible: false,
      isLoading: false,
      isSaving: false,
      isEditing: false,
      actions: [],
      selectedId: 0,
      defaults: {
        cronSpec: '',
        recipients: '',
        subject: '',
        targetUrl: ''
      },
      edit: {
        id: 0,
        isEnabled: false,
        cronSpec: '',
        recipients: '',
        subject: '',
        targetUrl: ''
      }
    },
    dashboardTiles: {...dashboardTiles},
    workspaceHitCount: {
      isVisible: false,
      isLoading: false,
      hits: []
    },
    application: {
      routePath:[]
    },
    sharedComponents: {
      collapsePanel: {},
      identityMenu: {
        id: 0,
        position: {},
        isVisible: false
      }
    },
    dashboard: {
      workspaces: {
        homepage: {
          filter: {
            filterType: 'name',
            filterText: ''
          },
          analysis: {
            private: {
              data: [],
              isLoading: false,
              orderBy: 'name',
              orderByDirection: 'asc'
            },
            shared: {
              data: [],
              isLoading: false,
              orderBy: 'name',
              orderByDirection: 'asc'
            }
          },
          report: {
            private: {
              data: [],
              isLoading: false,
              orderBy: 'name',
              orderByDirection: 'asc'
            },
            shared: {
              data: [],
              isLoading: false,
              orderBy: 'name',
              orderByDirection: 'asc'
            }
          }
        },
        reports: {
          filter: {
            filterType: 'name',
            filterText: ''
          },
          analysis: {
            private: {
              data: [],
              isLoading: false,
              orderBy: 'name',
              orderByDirection: 'asc'
            },
            shared: {
              data: [],
              isLoading: false,
              orderBy: 'name',
              orderByDirection: 'asc'
            }
          },
          report: {
            private: {
              data: [],
              isLoading: false,
              orderBy: 'name',
              orderByDirection: 'asc'
            },
            shared: {
              data: [],
              isLoading: false,
              orderBy: 'name',
              orderByDirection: 'asc'
            }
          }
        },
        selected: null
      }
    },
    homepage: {
      isInitialised: false,
      isLoading: false,
      scope: '',
      path: '',
      folder: '',
      subFolder: '',
      items: [],
      favourites: [],
      displayMode: '',
      orderBy: 'name',
      orderByDirection: 'asc',
      isUploadingImage: false,
      isLoadingImages: false,
      selectedImage: {
        id: 0,
        name: '',
        dataUrl: ''
      },
      images: [],
      searchText: '',
      isSearchMode: false,
      preSearchDisplayMode: '',
      isNewItemDialogOpen:false,
      isUploadImageDialogOpen:false
    },
    homepageNewItemModal: {
      isInitialised: false,
      isLoading: false,
      isSaving: false,
      sharedFolders: [],
      mode: '',
      type: 'WebLink',
      scope: 'Private',
      folderPath: '',
      subFolderPath: '',
      name: '',
      fullPath: '',
      data: '',
      selectedWorkspaceId: 0,
      selectedWorkspacePath: '',
      selectedWorkspace: {}
    },
    workspaceModal: {
      isInitialised: false,
      isLoading: false,
      isSaving: false,
      private: [],
      shared: [],
      sharedFolders: [],
      filteredSharedFolders: [],
      mode: '',
      type: '',
      scope: '',
      folderPath: '',
      subFolderPath: '',
      name: '',
      fullPath: '',
      selectedWorkspaceId: 0,
      selectedWorkspacePath: '',
      selectedWorkspace: {}
    },
    timeseriesSearch2: {
      derivationKeySearch: {
        criteria: {
          query: '',
          customFilter: '',
          orderBy: 'name',
          orderByDirection: 'asc',
          page: 0,
          pageSize: 50
        },
        results: {
          count: 0,
          facets: [],
          data: []
        },
        isSearching: false,
        selectedTimeSeries: [],
      },
      copyCategoriesSearch: {
        criteria: {
          query: '',
          customFilter: '',
          orderBy: 'name',
          orderByDirection: 'asc',
          page: 0,
          pageSize: 50
        },
        results: {
          count: 0,
          facets: [],
          data: []
        },
        isSearching: false,
        selectedTimeSeries: [],
      },
      addScenariosSearch: {
        criteria: {
          query: '',
          customFilter: '',
          orderBy: 'name',
          orderByDirection: 'asc',
          page: 0,
          pageSize: 50
        },
        results: {
          count: 0,
          facets: [],
          data: []
        },
        isSearching: false,
        selectedTimeSeries: [],
      }
    },
    analysisCrossSchemaSearch : {
      location: {
        pathname: '/analysis',
        search: '',
        history: {}
      },
      initialise: {
        schemas: undefined,
        userSettings: undefined,
        searchArgs: undefined
      },
      default : {
        shouldLoadWhenVisible : true,
        displayOptions : {
          displaySearchResults : true,
          exportButton : true,
          addRemoveButtons : true,
          favouritesToggle : true,
          addRemoveFavourites : true,
          excludeDiscontinued : true,
          expandDetails : true,
          expandFacets: true,
          multiSelectToggle: true,
          canSelectResults : true
        },

        customFilterHeight: '',
        enableExpandFacets: true,
        enableExpandDetails: true,
        schemaSelectorSize: 258,
        refreshRequired : false,
        isSearching: false,
        userSchemas : [/*{
          "schemaName": "GenerationByFuelTypes",
          "isSelected": true,
          "isExpanded": true
        },
        {
          "schemaName": "test-cross-search-02-x",
          "isSelected": false
        },
        {
          "schemaName": "Transmission",
          "isSelected": true
        },
        {
          "schemaName": "_legacy",
          "isSelected": false,
          "isExpanded": true
        }*/],
        criteria: {
          query: '',
          customFilter: '',
          discontinuedFilterMonths: 6,
          enableDiscontinuedFilter: false,
          enableFavouritesFilter: false,
          filtersCount: 0,
          isMultiSelect: true,
          filters: [],
          orderBy: 'name',
          orderByDirection: 'asc',
          page: 1,
          pageSize: 50,
          schemas :[/*{
            schemaName :'_legacy',
            colour :'Black',
            isSelected : true,
            isExpanded : true,
            facets : []
            },
            schemaName :'genByFuel',
              colour :'#888',
              isSelected : true,
              isExpanded : true,
              facets : []
          */],
          facets: [
            'aggregation',
            'commodity',
            'contract',
            'company',
            'currency',
            'dataType',
            'derivationType',
            'eicCode',
            'flow',
            'flowType',
            'grade',
            'granularity',
            'granularityType',
            'horizon',
            'hub',
            'instanceStyle',
            'location',
            'location2',
            'location2From',
            'location2To',
            'locationFrom',
            'locationTo',
            'materialisationStatus',
            'model',
            'owner',
            'partner',
            'periodMonth',
            'periodType',
            'periodYear',
            'plant',
            'provider',
            'reporter',
            'scenario',
            'shape',
            'source',
            'sourceTimeZoneId',
            'status',
            'style',
            'styleExtended',
            'subTechnology',
            'supplyDemand',
            'supplyDemandName',
            'supplyDemandType',
            'technology',
            'tso',
            'unit',
            'venue'
          ]
          },
        requestParams: {},
        results: {
          count: 0,
          facets: [],
          data: [],
          maxLevel: 0,
          selectedLevel: 0
        }
      }
    },
    analysis: {
      location: {
        pathname: '/analysis',
        search: '',
        history: {}
      },
      basketSettings: {
        basketView: 'classic', // request, chart, classic,
        highchartsJson : {}
      },
      ui: {
        reflowSwitch: 0,
        isTableDesignPanelVisible: true,
        isTableJsonEditorPanelVisible: false,
        isSideBarCollapsed: true,
        isHighChartSettingsPinned: false,
        isChartSettingsPinned: false,
        isSeriesSettingsPinned: false,
        isCreateReportModalVisible: false,
        isChartSeriesSettingsModalVisible: false,        
        shapesSettings: { 
          availableShapes: [/*
            {name:'test-shape',
             id: 1}
           */],
          selectedShapes: [],
          shapeName: '',
          shapeTemplate: 'hours/weekday',
          shapeTemplateMap: undefined,// {
          //   buckets: ['h1', 'h2'],
          //   groups: [{
          //       name: 'Mon',
          //       index : 0,
          //       buckets: [{ name: 'h1', isSelected: false }]
          //     }]
          // }
          shapeTemplateMapIsDirty: false,
          isLoadingShapes: false,
          isSavingShape: false,
          isDeletingShape: false,
          addShapeErrorMessage: undefined,
        },
        periods : [],
        periodsReferenceData : [],
        periodsUserSettings : [],
        periodsAbs : [],
        periodsUnsyncronised : false,
        isPeriodEditorVisible : true,
        isPeriodsAbsLoading : false,
        comparisonSettings: {
          basket : {
            comparisonMode : 'yearOnYear'
          },
          yearOnYear: {
            activeView: 'individualAbs',
            windowTitle: 'Year on year selector',
            rangeStart: moment.utc().startOf('day').add(-5, 'year').format(),
            rangeEnd: moment.utc().startOf('day').add(24, 'month').format(),
            selection: [],
            plotOrigin: {
              mode: 'abs',
              absOriginDate: moment.utc().month(0).day(0).format(),
              relOriginDate: '0Y',
            },
            view: {
              individualAbs: {
                options: {},
                showAddPreselectionErrorMessage: false,
                preselection: []
              },
              individualRel: {
                showAddPreselectionErrorMessage: false,
                relativeDate: '-1Y'
              },
              aggregationAbs: {
                operations: ['Avg'],
                options: {},
                showAddPreselectionErrorMessage: false,
                showAddRangePreselectionErrorMessage: false,
                preselection: []
              },
              aggregationRel: {
                operations: ['Avg'],
                options: { dates: [] },
                showAddPreselectionErrorMessage: false,
                relativeDate: '-1Y',
                relativeFromDate: '-2Y',
                relativeToDate: '-1Y',
              }
            }
          },
          gasYear: {
            activeView: 'individualAbs',
            windowTitle: 'GasYear selector',
            rangeStart: moment.utc().startOf('day').add(-5, 'year').format(),
            rangeEnd: moment.utc().startOf('day').add(24, 'month').format(),
            selection: [],
            plotOrigin: {
              mode: 'abs',
              absOriginDate: moment.utc().month(9).day(0).format(),
              relOriginDate: '0Y',
            },
            view: {
              individualAbs: {
                options: {},
                showAddPreselectionErrorMessage: false,
                preselection: []
              },
              individualRel: {
                showAddPreselectionErrorMessage: false,
                relativeDate: '-1Y'
              },
              aggregationAbs: {
                operations: ['Avg'],
                options: {},
                showAddPreselectionErrorMessage: false,
                showAddRangePreselectionErrorMessage: false,
                preselection: []
              },
              aggregationRel: {
                operations: ['Avg'],
                options: { dates: [] },
                showAddPreselectionErrorMessage: false,
                relativeDate: '-1Y',
                relativeFromDate: '-2Y',
                relativeToDate: '-1Y',
              }
            }
          },
          gasSeason: {
            activeView: 'individualAbs',
            windowTitle: 'GasSeason selector',
            rangeStart: moment.utc().startOf('day').add(-5, 'year').format(),
            rangeEnd: moment.utc().startOf('day').add(24, 'month').format(),
            selection: [],
            plotOrigin: {
              mode: 'abs',
              absOriginDate: moment.utc().month(3).day(0).format(),
              relOriginDate: '0Y',
            },
            view: {
              individualAbs: {
                options: {},
                showAddPreselectionErrorMessage: false,
                preselection: []
              },
              individualRel: {
                showAddPreselectionErrorMessage: false,
                relativeDate: '-1Y'
              },
              aggregationAbs: {
                operations: ['Avg'],
                options: {},
                showAddPreselectionErrorMessage: false,
                showAddRangePreselectionErrorMessage: false,
                preselection: []
              },
              aggregationRel: {
                operations: ['Avg'],
                options: { dates: [] },
                showAddPreselectionErrorMessage: false,
                relativeDate: '-1Y',
                relativeFromDate: '-2Y',
                relativeToDate: '-1Y',
              }
            }
          },
          quarter: {
            activeView: 'individualAbs',
            windowTitle: 'Quarter selector',
            rangeStart: moment.utc().startOf('day').add(-5, 'year').format(),
            rangeEnd: moment.utc().startOf('day').add(24, 'month').format(),
            selection: [],
            plotOrigin: {
              mode: 'abs',
              absOriginDate: moment.utc().month(0).day(0).format(),
              relOriginDate: '0Y',
            },
            view: {
              individualAbs: {
                options: {},
                showAddPreselectionErrorMessage: false,
                preselection: []
              },
              individualRel: {
                showAddPreselectionErrorMessage: false,
                relativeDate: '-1Y'
              },
              aggregationAbs: {
                operations: ['Avg'],
                options: {},
                showAddPreselectionErrorMessage: false,
                showAddRangePreselectionErrorMessage: false,
                preselection: []
              },
              aggregationRel: {
                operations: ['Avg'],
                options: { dates: [] },
                showAddPreselectionErrorMessage: false,
                relativeDate: '-1Y',
                relativeFromDate: '-2Y',
                relativeToDate: '-1Y',
              }
            }
          },
          monthOnMonth: {
            activeView: 'individualAbs',
            windowTitle: 'Month on month selector',
            rangeStart: moment.utc().startOf('day').add(-5, 'year').format(),
            rangeEnd: moment.utc().startOf('day').add(24, 'month').format(),
            selection: [],
            plotOrigin: {
              mode: 'abs',
              absOriginDate: moment.utc().month(0).day(0).format(),
              relOriginDate: '0M',
            },
            view: {
              individualAbs: {
                options: {},
                showAddPreselectionErrorMessage: false,
                preselection: []
              },
              individualRel: {
                relativeDate: '-1M',
                showAddPreselectionErrorMessage: false,
                preselection: []
              },
              aggregationAbs: {
                operations: ['Avg'],
                options: {},
                showAddPreselectionErrorMessage: false,
                showAddRangePreselectionErrorMessage: false,
                preselection: []
              },
              aggregationRel: {
                operations: ['Avg'],
                options: { dates: [] },
                showAddPreselectionErrorMessage: false,
                relativeDate: '-1M',
                relativeFromDate: '-2M',
                relativeToDate: '-1M',
              }
            }
          },
          dayOnDay: {
            activeView: 'individualAbs',
            windowTitle: 'Day on day selector',
            rangeStart: moment.utc().startOf('day').add(-5, 'year').format(),
            rangeEnd: moment.utc().startOf('day').add(24, 'month').format(),
            selection: [],
            plotOrigin: {
              mode: 'abs',
              absOriginDate: moment.utc().month(0).day(0).format(),
              relOriginDate: '0D',
            },
            view: {
              individualAbs: {
                options: {},
                showAddPreselectionErrorMessage: false,
                preselection: [],
                activeDate: undefined
              },
              individualRel: {
                relativeDate: '-1D',
                showAddPreselectionErrorMessage: false,
                preselection: []
              },
              aggregationAbs: {
                operations: ['Avg'],
                options: {},
                showAddPreselectionErrorMessage: false,
                showAddRangePreselectionErrorMessage: false,
                preselection: []
              },
              aggregationRel: {
                operations: ['Avg'],
                options: { dates: [] },
                showAddPreselectionErrorMessage: false,
                relativeDate: '-1D',
                relativeFromDate: '-2D',
                relativeToDate: '-1D',
              }
            }
          }
        },
        fillerValues : {
          asAtWindow : {
            isEnabled:true,
            dataRelativeToAsAt: false,
            from:{
              mode: 'abs',
              rel: '0D',
              abs: moment.utc().startOf('day').format('YYYY-MM-DD')
            },
            to: {
              mode: 'abs',
              rel: '0D',
              abs: moment.utc().startOf('day').format('YYYY-MM-DD')
            }
          },
          conversionUnit:'',
          lineWidth:'1',
          plotOrigin: {
            isEnabled:true,
            lens:'Day',
            absRel : {
              mode: 'abs',
              rel: '0D',
              abs: moment.utc().startOf('day').format('YYYY-MM-DD')
            }
          },
          scenario : {
            scenario: '',
            complexScenario: ''
          },  
          timeSeriesNameExpression :{
            isEditing: false,
            expression: ''
          },
          windowOverride :{
            mode:'None',
            lens:'Day',
            period: '',
            from:{
              mode: 'abs',
              rel: '0D',
              abs: moment.utc().startOf('day').add(-14, 'day').format('YYYY-MM-DD')
            },
            to:{
              mode: 'rel',
              rel: '0D',
              abs: moment.utc().startOf('day').format('YYYY-MM-DD')
            }
          }
        },
        timeseriesVersionSettings: {
          //tab: 'AsAt|Operation',
          //timeseries: [],
          //identityId
          //key
          //showDefault
          //operations: [
          //  {
          //    name:
          //    isSelected
          //  }
          //],
          //asAts: {
          //  isLoading: true,
          //  page: 1,
          //  pageSize: 25,
          //  data: [],
          //  selection: []
          //},
          //adHocAsAts: {
          //  editValue: '',
          //  selection: []
          //},
          // relativeAsAts: {
          //  editValue: '-1D',
          //  selection: []
          //},
          //relativeForecasts: {
          //  editValue: '-1D',
          //  selection: []
          //}
        },
        editTimeSeries: undefined,
        panelWidths: {
          sideBarPanel: {
            size: 56
          },
          mainPanel: {
          },
          contentPanel: {
          },
          seriesSettings: {
            size: 300,
            isHidden: true
          },
          reportSettings: {
            size: 400,
            isHidden: false
          },
          chartSettings: {
            size: 400,
            isHidden: true
          },
          mainContainer: {
          }
        }
      },
      composition: {
        selectedLevel: 10,
        maxLevel: 0,
        maxDepth: 100,
        data: {}
      },
      search: {
        customFilterHeight: '',
        enableExpandFacets: true,
        enableExpandDetails: true,
        selectedSearchSchema: '',
        criteria: {
          query: '',
          customFilter: '',
          discontinuedFilterMonths: 6,
          enableDiscontinuedFilter: false,
          enableFavouritesFilter: false,
          facets: [
            'aggregation',
            'commodity',
            'contract',
            'company',
            'currency',
            'dataType',
            'derivationType',
            'eicCode',
            'flow',
            'flowType',
            'grade',
            'granularity',
            'granularityType',
            'horizon',
            'hub',
            'instanceStyle',
            'location',
            'location2',
            'location2From',
            'location2To',
            'locationFrom',
            'locationTo',
            'materialisationStatus',
            'model',
            'owner',
            'partner',
            'periodMonth',
            'periodType',
            'periodYear',
            'plant',
            'provider',
            'reporter',
            'scenario',
            'shape',
            'source',
            'sourceTimeZoneId',
            'status',
            'style',
            'styleExtended',
            'subTechnology',
            'supplyDemand',
            'supplyDemandName',
            'supplyDemandType',
            'technology',
            'tso',
            'unit',
            'venue'
          ],
          facetStyle: '',
          filtersCount: 0,
          filters: [],
          orderBy: 'name',
          orderByDirection: 'asc',
          page: 0,
          pageSize: 0
        },
        results: {
          count: 0,
          facets: [],
          data: [],
          displayMap: {},
          maxLevel: 0,
          selectedLevel: 0
        },
        isSearching: false
      },
      source : {},
      table : {
        styles :{},
        rowStyles: [],
        headers :[],
        data :[],
        addressLookup :{}
      },
      chart: {
        rowData: [],
        series: [],
        timeSeries: [],
        isLoading: false,
        refreshRequired: false,
        comparisonMode: 'none',
        stackingType: '',
        xAxisKey: undefined
      },
      chartSettingsEdit: {
        settings: {},
        currentView: 'UI',
        isDirty: false
      },
      seriesTypes: [
        { value:'area', text:'Area'},
        { value:'column', text:'Column'},
        { value:'line', text:'Line'},
        { value:'pie', text:'Pie'},
        { value:'scatter', text:'Scatter'},
        { value:'spline', text:'Spline'}
      ],
      seriesDashStyles: [
          { value:'none', text:'None'},
          { value:'solid', text:'Solid'},
          { value:'shortdash', text:'ShortDash'},
          { value:'shortdot', text:'ShortDot'},
          { value:'shortdashdot', text:'ShortDashDot'},
          { value:'shortdashdotdot', text:'ShortDashDotDot'},
          { value:'dot', text:'Dot'},
          { value:'dash', text:'Dash'},
          { value:'dashdot', text:'DashDot'},
          { value:'longdash', text:'LongDash'},
          { value:'longdashdot', text:'LongDashDot'},
          { value:'longdashdotdot', text:'LongDashDotDot'},
      ],
      seriesStacking: [
          { value:'', text:'None' },
          { value:'normal', text:'Normal' },
          { value:'percent', text:'Percent' },
      ],
      shape: '',
      dynamicWorkspaceDesign: {
        showModal: false,
        isBusy: false,
        dynamicFilters : [{
          alias: '',
          key: guid(),
          selectedSchemas: [],
          selectedValue: '',
          strict: false,
          strictSelectedValue: '',
          strictValues: [],
          strictOptions: [],
          values: [],
          warnings: [],
          isMultiSelect: false,
          multiSelectValues: []
        }],
        dynamicWorkspace: {
          key: 'new',
          searchCriteria: {},
          substitutionKeyExclusionCategories: [],
          previousDynamicKeys : [],
          dynamicFilters: []
        },
        dynamicWorkspaceBaseline : {},
        previousSearchCriteria : {},
        searchResultSchemaCategories : [],
        searchResultCategories: [],
        dynamicRows: []
      },
      chartJson : undefined,
      workspace: {
        id: 0,
        name: '',
        folderPath: '',
        subFolderPath: '',
        type: 'Analysis',
        scope: 'Private',
        templateNameExpression : '',
        initialPageUrl: '',
        pageUrl: '',
        period:  'Default',
        periods: [],
        timeSeriesVersions : [
          {
            // "identityId": 154384,
            // "showDefault": true,
            // "operations": [],
            "asAts": [
              // "2021-03-16T18:19:38",
              // "2021-03-17T01:20:41",
              // "2021-03-12T03:20:13"
            ],
            "adHocAsAts": [
              // "2021-03-16T18:19:38",
              // "2021-03-17T01:20:41",
              // "2021-03-12T03:20:13"
            ],
            "relativeAsAts": [
              //
            ],
            "relativeForecasts": [
              //
            ],
            "trackRemovals": [
              //
            ]
          }
        ],

        //timeseriesNameStyle : {
        //  expression:''
        //},

        timeseries: [
          // 'timeseriesId' : {
          //   key: key,
          //   id: data.id,
          //   identityId: data.identityId || data.id,
          //   timeSeriesName: data.name, // from the API
          //   name: data.name, // used as display name
          //   nameStyle: 'default'|'custom'|'expression',
          //   customName: '',
          //   style: data.style,
          //   granularity: data.granularity,
          //   sourceTimeZoneId: data.sourceTimeZoneId,
          //   unit: data.unit,
          //   firstDataPointUtc: data.firstDataPointUtc,
          //   lastDataPointUtc: data.lastDataPointUtc,
          //   lastUpdatedUtc: data.lastUpdatedUtc,
          //   timestampUtc: data.timestampUtc,
          //   type: data.type || (defaults.chart || {}).type || 'line',
          //   dashStyle: data.dashStyle || ((defaults.plotOptions || {}).series || {}).dashStyle || 'solid',
          //   lineWidth: data.lineWidth || ((defaults.plotOptions || {}).series || {}).lineWidth || 1,
          //   yAxis: data.yAxis || 0,
          //   color: data.color || '#000000'.replace(/0/g, () => Math.floor(Math.random() * 0xf).toString(16)),
          //   isDisabled: data.isDisabled || false,
          //   infoType: data.infoType || '',
          //   infoMessage: data.infoMessage || ''
          //   asAtType:
          //   masterBuildStyle: MasterAndInstances or Instances
          //   materialisationModeDisabled
          //   variantModeDisabled
          // }
        ],
        baselineBasket: [],
        lens: 'Day',
        operation: 'WAvg',

        fromDateMode: 'abs',
        fromUtc: moment.utc().startOf('day').add(-14, 'day').format('YYYY-MM-DDTHH:mm'),
        relFrom: '-1M',

        toDateMode: 'abs',
        toUtc: moment.utc().startOf('day').add(1, 'month').format('YYYY-MM-DDTHH:mm'),
        relTo: '0D',

        asAtUtc: '',
        timeZoneId: 'UTC',
        comparisonMode: 'none',

        dynamicWorkspaceIsPinned : true,
        dynamicWorkspaceGroupingEnabled : false,
        
        dynamicWorkspaces : [
          // {
          //   key : '',
          //   searchCriteria : {
          //   },
          //   dynamicFilters:[]
          // }
        ],

        // v2
        shapesSettings: {
          selection: []
        },

        // v2
        comparisonSettings: {
          yearOnYear: { mode: undefined, windows: [] },
          gasYear: { mode: undefined, windows: [] },
          gasSeason: { mode: undefined, windows: [] },
          quarter: { mode: undefined, windows: [] },
          monthOnMonth: { mode: undefined, windows: [] },
          dayOnDay: { mode: undefined, windows: [] }
        },

        chartSettings: {},

        tableSettings: {
          headerView: '',
          fontSize: undefined,
          orderBy: '',
          orderByDirection: '',
          headerStyles: {
            cellStyles:{
              default :{
                paddingTop:'5px',
                paddingBottom:'5px'
              }
            }
          },
          dataStyles: {}
        },
        conversionUnit: '',
        conversionFactor: undefined,
        conversionUnitStrictErrors: false,

        fromAsAtDateMode: 'abs',
        relAsAtFrom: '-5D',
        asAtFromUtc: moment.utc().startOf('day').add(-14, 'day').format('YYYY-MM-DDTHH:mm'),

        toAsAtDateMode: 'abs',
        relAsAtTo: '0D',
        asAtToUtc: moment.utc().startOf('day').format('YYYY-MM-DDTHH:mm'),

        dataWindowRelativeToAsAt: false,

        isEvolution: false,
        force24HourDST: false,
        alignTimeZones: false,
        isDirty: false,
        isUnsaved: false,

        materialisationMode: 'Enabled',
        variantMode: 'Enabled',

        masterBuildStyle: 'masterAndInstances',
        seriesStyle: 'masterBuild'
      },
      workspacePath : undefined,
      overlay: {
        isVisible: false,
        panelName: ''
      },
      isLoading: true,
      isDynamicWorkspaceFilterLoading: false,
      adjustments : {
        isEditing: false,
        existingAdjustmentsMap: {},
        dirtyCellsMap: {},
        preSaveWarnings :[],
        timeSeriesMeta : {
          annotation : undefined, // overridden by user settings
          adjustmentType: 'Standard'
        },
        validation:{
          isLoading:false,
          messages:[]
        },
        editSelection: {
          editCell: {},
          cursorCell: {},
          range:{},
          startRowKey:undefined,
          startColKey:undefined,
          endRowKey:undefined,
          endColKey:undefined,
          deferredValue:undefined,
          timeSeriesMeta: [],
        }
      }
    },
    outages: {
      isInitialised: false,
      isLoading: false,
      requiresRefresh: false,
      toolbar: {
        source: '',
        biddingZone: '',
        productionType: '',
        unitEic: '',
        eventStart: '',
        eventEnd: '',
        page: 1,
        pageSize: 50,
        fotaStatus: 'Invalid',
        messageVersions: '',
        messageStatus: '',
        generationData: '',
        filters: [],
        sourceList: {
          isLoading: false,
          data: []
        },
        biddingZoneList: {
          isLoading: false,
          data: []
        },
        productionTypeList: {
          isLoading: false,
          data: []
        },
        generationDataLookupList: {
          isLoading: false,
          data: []
        }
      },
      criteria: {
        source: '',
        biddingZone: '',
        productionType: '',
        unitEic: '',
        eventStart: '',
        eventEnd: '',
        skip: 0,
        take: 25,
        fotaStatus: '',
        messageVersions: '',
        messageStatus: '',
        generationData: '',
        messageId: ''
      },
      data: [],
      resultCount: 0,
      selectedOutage: null
    },
    reports: {
      location: {
        pathname: '/reports',
        search: '',
        history: {}
      },
      lastLoadedReportPath: '',
      criteria: {
        reportPath: '',
        reportScope: '',
        reportFolderPath: '',
        reportName: '',
        lens: '',
        fromDate: '',
        toDate: '',
        timeZoneId: '',
        asAt: '',
        mode: '',
        relativeAsAt: '-1D',
        snapshotAsAt: '',
        snapshotRelativeDate: '-1Y',
        snapshotLookupDate: '',
        snapshotLookupCount: 50,
        isPivot: false,
        availableGroupings: [],
        groupings: [],
        filters: [],
        rootScenario: '',
        scenarioWidth: 15,
        shapeName: ''
      },
      settings : {
        hasAdaptiveLensDates: false,
        lenses: [],
        timeZones: [],
        reportingModes: []
      },
      adjustments:{
        dirtyCellsMap: {},
        isEditing: false,
        isAdjustmentsPanelVisible: false,
        isSaveConfirmationVisible: false,
        editSelection: {
          editCell: undefined,
          cursorCell: undefined,
          range:{},
          startColIndex:undefined,
          startRowKey:undefined,
          endColIndex:undefined,
          endRowKey:undefined,
          deferredValue:undefined,
          timeSeriesMeta: []
        },
        timeSeriesMeta : {
          annotation : undefined, // overridden by user settings
          adjustmentType: 'Standard'
        },
        validation:{
          isLoading:false,
          messages:[]
        }
      },
      results: {
        settings: {
          maxLevel: 6,
          selectedLevel: null
        },
        displayMap: {},
        annotationsDisplayMap: {},
        scenarioOverrideMap: {},
        styles: {},
        specialisedStyles: {},
        columns: [],
        flatRows: [],
        rows: [],
        headers: [],
        annotationSections: [],
        errors: {},
        warnings: {}
      },
      composition: {
        isLoading: true,
        isOverridenLoading: false,
        showOnlyScenarios: false,
        filteredScenarios: [],
        panels: {
          panelGroup1: [{ resize: 'stretch', showHandles: false }, { resize: 'dynamic', showHandles: false }],
          panelGroup2: [{ size: 1000, resize: 'stretch' }, { size: 1000, resize: 'stretch' }]
        },
        criteria: {
          reportPath: '',
          lens: 'Day',
          timeZoneId: 'Europe/GasDay'
        },
        results: {
          displayMap: {},
          granularityCount: {},
          flatRows: [],
          rows: [],
          rowsWithOverrides: [],
          scenarioOverrideMap: {},
          scenarioRows: [],
          selectedTimeSeriesId: '',
          selectedTimeSeries: {}
        },
        toolbar: {
          scenarioCsvIds: '',
          isLoading: false
        }
      },
      diagnostics: {
        elapsed: ''
      },
      snapshots: {
        isLoading: false,
        data: []
      },
      chart: {
        parentKeyPath: [],
        seriesData: {},
        isLoading: false,
        lens: '',
        selectedIds: [],
        availableScenarios: {},
        selectedScenarios: [],
        analysisLaunchKeys: []
      },
      workspace: {
        id: 0,
        name: '',
        identityId: [],
        lens: 'Day',
        operation: 'Avg',
        timeZoneId: 'Europe/GasDay'
      },
      overlay: {
        isVisible: false
      },
      isLoading: false,
      isExporting: false,
      isRefreshRequired: false
    },
    events: {
      location: {
        pathname: '/events?timeRangeFilter=1H&page=1',
        search: '',
        history: {}
      },
      search: {
        criteria: {
          query: '',
          customFilter: '',
          facets: [
            'eventName',
            'eventType',
            'originator',
            'source',
            'reason'
          ],
          filters: [],
          orderBy: 'raisedUtc',
          orderByDirection: 'desc',
          page: 0,
          pageSize: 0,
          timeRangeFilter: '24H'
        },
        results: {
          count: 0,
          facets: [],
          data: []
        },
        isSearching: false
      }
    },
    log: {
      messages: []
    },
    userSettings: {
      isLoading: true,
      settings: {
        experimental: {
          useNewAsAtQuery: false
        }
      }
    },
    timeSeries: {
      id: null,
      granularityType: null,
      features: {
        rangeBasedAdjustments: false
      }
    },
    timeSeriesDetails: {
      isEditorCollapsed: false,
      isSideBarCollapsed: true,
      selectedTimeSeriesId: 0,
      currentTimeSeriesId: 0,
      currentTimeSeriesView: '',
      timeSeriesEditor: {
        inputFocusId:'',
        viewMode: 'default', // json|default
        lookupData: {
          source: [],
          unit: [],
          categories: [],
          categoryValues: {},
          isLoading: false,
          isLoadingCategory: false
        },
        timeSeries: {
          // id
          // name
          // source
          // sourceId
          // etc.
          // derivationData{ keys:[] }
        },
        basedOnSettings: {
          fromDateMode: 'rel',
          toDateMode: 'rel',
          searchKeyText: '',
          selectedIdentity: null
        },
        derivationSettings: {
          searchKeyText: '',
          selectedKeys: [
            //{ id
            // name}
          ]
        }
      },
      annotationsEditor: {
        annotations: [],
        selectedTimeZone: '',
        timeZone: '',
        filterType: '',
        filterText: '',
        appliedFilterType: '',
        appliedFilterText: '',
        isFilterDirty: false,
        appliedOrderBy: '',
        appliedOrderByDirection: ''
      },
      composition: {},
      adjustmentsEditor: {
        dataPointsEdit: {
          dataPoints: [],
          filterFromUtc: null,
          filterToUtc: null,
          filterTimezone: '',
          dataPointsTimezone: ''
        },
        dataPointsRangeEdit: {
          fromUtc: moment.utc().startOf('day').subtract(1, 'day').toDate(),
          toUtc: moment.utc().startOf('day').add(1, 'month').toDate(),
          value: ''
        },
        dataPointsSaveModal: {
          reason: '',
          commentMarkdown: '',
          adjustmentType: 'Standard'
        },
      },
      variantsEditor: {
        variants: [],
        variantEdit: {},
        isLoading: false
      },
      materialise: {
        rangeDialogVisible: false,
        suppressArchive: true,
        fromUtc: moment.utc().startOf('year').add(-10, 'year').format('YYYY-MM-DD'),
        toUtc: moment.utc().startOf('year').add(10, 'year').format('YYYY-MM-DD')
      }
    },
    adjustmentRangeEditor: {
      isInitialised: false,
      isLoading: false,
      isSaving: false,
      isDeleting: false,
      isGenerating: false,
      isDateOnly: false,
      timeSeriesId: null,
      timeSeriesGranularityType: null,
      operations: ['Overwrite', 'Block', 'Allow', 'Add', 'Subtract', 'Multiply', 'Divide'],
      types: ['Standard', 'Temporary'],
      toolbar: {
        periodStart: null,
        periodEnd: null,
        requiresRefresh: false
      },
      selectedRangeId: '',
      firstSelectedRow: -1,
      rangeEdit: {
        periodStart: null,
        periodEnd: null,
        operation: null,
        adjustedValue: null,
        type: null,
        reason: null,
        isInclusive: false,
        isEditing: false,
        isValidForm: false,
        hasChanges: false
      },
      ranges: [
        /*
        {
          id: 0,
          periodStart: null,
          periodEnd: null,
          operation: 'Overwrite', // Overwrite
          adjustedValue: null,
          reason: null,
          type: null,
          owner: null,
          updatedUtc: null
        }
        */
      ],
      originalDataPoints: null,
      editedDataPoints: null,
      dataPoints: [
        /*
        {
          startDateUtc: null,
          endDateUtc: null, // Displayed if time series is "Encoded" granularity
          value: null,
          adjustedValue: null,
          result: null,
          operation: 'Overwrite', // Overwrite
          type: 'Standard' // Standard, Temporary
        }
        */
      ],
      comments: []
    },    
    etl: {
      initialised: null,
      isLoading: false,
      lastRefreshed: null,
      filter:{
        text: '',
        lastOriginator: ''
      },
      filteredRows: [],
      mappedRows: [],
      orderBy: 'jobName',
      orderByDirection: 'asc',
      showSuccess: false,
      showRunning: true,
      showFinishedWithWarnings: false,
      showFinishedWithErrors: true,
      showFail: true,
      showNotRun: false,
      showAll: false,
      showLastSuccessAgo: false,
      succeededCount: 0,
      runningCount: 0,
      finishedWithWarningsCount: 0,
      finishedWithErrorsCount: 0,
      failedCount: 0,
      notRunCount: 0,
      totalCount: 0
    },
    etlJob: {
      isLoading: false,
      lastRefreshed: null,
      job: {},
      lastRun: {},
      lastRunInformation: [],
      lastRunWarning: [],
      lastRunError: [],
      nextRun: {},
      lastSuccessAtUtc: null
    },
    etlJobHistory: {
      items: []
    },
    roles: {
      isLoading: true,
      isContributor: false,
      isWizard: false,
      isInlineAdjustmentsEditor: false
    },
    schemas: {
      allSchemas: [],
      allSchemaNames: [],
      allSchemaLookups: {},
      availableSchemas: [],
      identityId: 0,
      identitySchemas: [],
      isLoading: false,
      schemaInEdit: '',
      selectedSchema: '',
      copyFromIdentityId: '',
      selectedLookup: '',
      lookupValue: ''
    },
    instanceViewer: {
      instances: {
        rows: [],
        page: 1,
        pageSize: 40,
        total: null
      },
      isLoading: false,
      selectedInstanceDataPoints: [],
      instancePromoteAsAtUtc: moment.utc(),
      instancePromoteDialogVisible: false,
      selectedInstance: null,
      toolbar: {
        fromUtc: null,
        toUtc: null,
        dataPointUtc: null
      }
    },
    historyViewer: {
      isLoading: false,
      rows: [],
      isItemSelected: false,
      selectedAudit: {},
      selectedIdentityVersion: {}
    },
    lngDeals: {
      isInitialised: false,
      isLoading: false,
      country: [],
      delivery: [],
      entity: [],
      status: [],
      deals: [],
      newDeal: {
        id: 0,
        modified: true,
        tenderClosingDate: moment.utc().startOf('day').format('DD-MMM-YYYY'),
        seller: '',
        buyer: '',
        cargoes: 0,
        delivery: 'DES',
        startDate: moment.utc().startOf('day').format('DD-MMM-YYYY'),
        endDate: moment.utc().startOf('day').add(1, 'month').format('DD-MMM-YYYY'),
        countryOrigin: 'Unknown',
        countryDestination: 'Unknown',
        terminalDestination: '',
        terminalOrigin: '',
        status: 'Pending',
        comment: ''
      }
    },
    toolbox: {
      isLoading: false,
      lens: 'None',
      operation: 'WAvg',
      shapeName: '',
      fromDate: moment.utc().startOf('day').add(-14, 'day').format('YYYY-MM-DD'),
      toDate: moment.utc().startOf('day').add(14, 'day').format('YYYY-MM-DD'),
      timezone: '',
      asAtDate: null,
      take: 'First',
      dpCount: 10,
      disableMaterialisation: true,
      disableVariants: true,
      testUnsavedChanges: true,
      inputs: [],
      steps: [],
      result: null
    },
    actionsEditor : {
      isLoading : false,
      filter: '',
      selectedTabIndex : 0,
      onlyShowMyActions : true,
      collections : {
        inlineDataPointsActions: [],
        scheduledActions: [],
        triggeredActions: []  
      },
      scheduledRuns: {}
    },
    queueStatus: {
      initialised: false,
      isLoading: false,
      loaders: [],
      subscriptions: []
    }
  });
}